export const headers = [
  { label: 'Município', key: 'name' },
  { label: 'Estado', key: 'uf' },
  { label: 'Região', key: 'zone' },
  { label: 'População', key: 'population' },
  { label: 'Grupo de implementação', key: 'group' },
  { label: 'Status de implementação', key: 'status' },
  { label: 'Fase prevista', key: 'phase' },
  { label: 'Data de adesão', key: 'accessionDate' },
  { label: 'Telefone', key: 'phone' },
  { label: 'Responsável', key: 'userResponsible' },
  { label: 'Observações', key: 'observations' },
  { label: 'Contatos', key: 'people' },
  { label: 'Prospecção', key: 'prospections' },
  { label: 'Ciclos do município', key: 'cycle' },
  { label: 'Emails', key: 'emails' },
];
