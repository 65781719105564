import styled, { keyframes } from 'styled-components';

export const sweep = keyframes`
  from {
    opacity: 0; 
    transform: translateX(-10px)
  }

  to {
    opacity: 1; 
    transform: translateX(0)
  }
`;

export const Details = styled.details`
  div {
    animation: 0.5s ${sweep} ease-in;
  }
  cursor: pointer;
`;

export const Span = styled.span`
  font-size: 12px;
  line-height: 16px;

  color: ${({ theme }) => theme.colors.secondary.light};
`;

export const TextArea = styled.textarea`
  resize: none;
  outline: transparent;
  border: none;
  min-height: 100px;
  border-radius: 4px;
  padding: 10px 15px;
  color: ${({ theme }) => theme.colors.primary.main};
`;

export const Summary = styled.summary`
  width: 100%;
  background: ${({ theme }) => theme.colors.primary.regular};
  padding: 15px 41px;
  color: ${({ theme }) => theme.colors.primary.main};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  cursor: pointer;
`;

export const Info = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;
  background: ${({ theme }) => theme.colors.primary.light};
  padding: 10px 20px;
  min-height: 75px;

  p {
    text-align: left;
  }

  & > div {
    margin-bottom: 10px;
    :last-child {
      margin-bottom: 0px;
    }
  }
`;

export const FirstField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.875rem;

  & > div {
    margin-top: 0 !important;
  }
`;

export const DropDown = styled.select`
  width: 100%;
  padding: 8px 6px;
  border-radius: 4px;
  margin-top: 4px;
  outline: transparent;
  border: 1px solid ${({ theme }) => theme.colors.primary.light};
  background: ${({ theme }) => theme.colors.primary.white};
  color: ${({ theme }) => theme.colors.primary.main};
`;
