import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoPaperPlaneOutline } from 'react-icons/io5';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { selectAllCycles } from '../../redux/slices/cycleSlice';
import { transformObjectToArray } from '../../utils/formatObjectToArray';
import useForms from '../../hooks/useForms';
import { Button, PageLayout } from '../../components';
import { Column, TextArea } from './style';
import { createNews } from '../../services/news';

export function Add() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { fields, createFields, createDropdownFields, handleFieldsChange, errors } = useForms();
  const [selectedCycle, setSelectedCycle] = useState([]);
  const cycles = useSelector(selectAllCycles);

  useEffect(() => {
    transformObjectToArray(cycles).forEach(cycle => {
      setSelectedCycle(prev => [...prev, cycle.id]);
    });
  }, []);
  const handleSelectCycle = cycle => {
    setSelectedCycle([cycle]);
  };

  const fieldsObject = {
    title: {
      label: 'Título',
      value: fields.title,
      placeholder: 'Digite o título da notícia',
      messageIfHasError: 'Título do município obrigatório',
    },
    cycle: {
      label: 'Ciclo',
      options: transformObjectToArray(cycles),
      placeholder: 'Todos (padrão)',
      onSelect: handleSelectCycle,
    },
  };

  const isFormValid = () => {
    const isSomeFieldEmpty = ['title', 'message'].some(field => !fields[field]);

    return !isSomeFieldEmpty && !errors.length && selectedCycle;
  };

  function handleCreateNews() {
    dispatch(createNews({ title: fields.title, message: fields.message, cycleIds: selectedCycle }));
    history.goBack();
  }

  return (
    <PageLayout previous>
      {createFields('title', fieldsObject)}
      <br />
      <Column>
        <label>Mensagem</label>
        <TextArea
          placeholder="Lorem ipsum dolor sit amet, consectet ui i iadipiscing elit."
          name="message"
          autoCapitalize="sentences"
          value={fields.message}
          onChange={handleFieldsChange({ confirm: false })}
        />
      </Column>
      {createDropdownFields('cycle', fieldsObject)}
      <Button onClick={handleCreateNews} disabled={!isFormValid()}>
        <IoPaperPlaneOutline size={24} />
        Enviar notificação
      </Button>
    </PageLayout>
  );
}
