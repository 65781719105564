/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'; // useEffect
import {
  // useDispatch,
  useSelector,
} from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { selectUserPermission } from '../redux/slices/authSlice';
// import { verifyToken } from '../services/auth';
// import { getUserByToken } from '../services/user';

function PublicRoute(props) {
  const { component: Component, ...rest } = props;
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const userPermission = useSelector(selectUserPermission);

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   try {
  //     dispatch(getUserByToken());
  //     dispatch(verifyToken());
  //   } catch (err) {
  //     // console.log(err);
  //   }
  // }, [dispatch]);

  if (userPermission === 'admin' && isAuthenticated) return <Redirect to="/dashboard" />;

  if (isAuthenticated) return <Redirect to="/customers" />;

  return <Route {...rest} component={routeProps => <Component {...routeProps} />} />;
}

export default PublicRoute;
