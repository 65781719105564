import { createAsyncThunk } from '@reduxjs/toolkit';
import fetcher from '../fetcher';

export const createTaskLog = async () => {
  const fetch = fetcher(true);
  const {
    data: {
      data: { id },
    },
  } = await fetch.post('/taskLog', {
    taskId: 1,
    status: 1,
  });

  return id;
};

export const createContact = createAsyncThunk('create/contact', async info => {
  const fetch = fetcher(true);
  const { customerId, personId, personName, annotation } = info;
  // const taskLogId = await createTaskLog();

  const {
    data: { data },
  } = await fetch.post('/contact', {
    customerId,
    personId,
    // taskLogId,
    annotation,
    formatContact: 'formatContact',
  });

  return { ...data, person: { id: personId, name: personName } };
});

export const getCustomerContacts = createAsyncThunk('get/customerContacts', async id => {
  const fetch = fetcher(true);

  const {
    data: { data },
  } = await fetch.get(`/contact/customer/${id}`);

  return data;
});

export const getPersonContacts = async id => {
  const fetch = fetcher(true);

  const {
    data: { data },
  } = await fetch.get(`/contact/person/${id}`);

  return data;
};
