import styled, { css } from 'styled-components';

export default styled.input`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  height: 52px;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  padding: 15px 16px;
  outline: none;
  font-size: 1rem;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.colors.primary.regular};

  transition: 200ms ease-in;
  appearance: none;

  ${({ theme, error }) =>
    error &&
    css`
      border-color: ${theme.colors.alert.main} !important;
      color: ${theme.colors.alert.main};
    `}
`;
