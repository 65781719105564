import styled from 'styled-components';

export const Checkbox = styled.input.attrs(props => ({
  type: 'checkbox',
  checked: props.check,
}))`
  margin-right: 17px;
  cursor: pointer;
  position: relative;

  :before {
    content: '';
    display: block;
    position: absolute;
    width: ${({ large }) => (large ? '20px' : '16px')};
    height: ${({ large }) => (large ? '20px' : '16px')};

    border: ${({ theme, large }) => (large ? `1px solid ${theme.colors.primary.main}` : 'none')};
    border-radius: 4px;
    top: ${({ large }) => (large ? '-4px' : '-1px')};
    left: -1px;
    background-color: #e9e9e9;
  }

  :checked:before {
    content: '';
    background: ${({ theme }) => theme.colors.primary.main};
  }
`;
