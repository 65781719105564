import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  &:not(:first-of-type) {
    margin-top: ${({ noMargin }) => (noMargin ? 0 : 20)}px;
  }

  label {
    color: ${({ theme }) => theme.colors.primary.black};
    font-size: 0.875rem;
    line-height: 16px;
    margin-left: 2px;
    margin-bottom: 4px;
  }

  small {
    color: ${({ theme }) => theme.colors.alert.main};
    margin-top: 4px;
    align-self: flex-end;
  }
`;

export default Container;
