/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
export function completeYear(monthsArray, year) {
  const allMonths = [];

  // Cria um objeto para cada mês com status "other"
  for (let i = 1; i <= 12; i++) {
    allMonths.push({
      id: i,
      year,
      month: i,
      service_level: false,
      management_report: false,
      relocation: false,
      sent: false,
    });
  }

  // Substitui os meses existentes no array de entrada pelos meses reais
  for (const month of monthsArray) {
    allMonths[month.month - 1] = month;
  }

  return allMonths;
}
export function getAbbreviation(month) {
  switch (month) {
    case 1:
      return 'Jan';
    case 2:
      return 'Fev';
    case 3:
      return 'Mar';
    case 4:
      return 'Abr';
    case 5:
      return 'Mai';
    case 6:
      return 'Jun';
    case 7:
      return 'Jul';
    case 8:
      return 'Ago';
    case 9:
      return 'Set';
    case 10:
      return 'Out';
    case 11:
      return 'Nov';
    case 12:
      return 'Dez';
    default:
      return 'Jan';
    //   throw new Error('Month must be between 1 and 12');
  }
}
