import styled from 'styled-components';

export const Info = styled.div`
  background: ${({ theme }) => theme.colors.altBackground};
  padding: 15px 20px 20px;

  p {
    text-align: left;
    line-height: 18px;
    white-space: pre-line;
    word-wrap: break-word;
  }
`;

export const CopyButton = styled.button`
  margin-bottom: 15px;
  padding: 4px 12px;
  border-radius: 4px;
  border: none;
  outline: none;
  background: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.altText};
  font-size: 12px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
