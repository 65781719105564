import React from 'react';
import ReactDOM from 'react-dom';
import { Wrapper, Loader } from './style';

function Loading() {
  return ReactDOM.createPortal(
    <Wrapper>
      <Loader />
    </Wrapper>,
    document.getElementById('loader'),
  );
}

export default Loading;
