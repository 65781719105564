import React from 'react';
import formatDate from '../../utils/formatDate';
import { formatPhone } from '../../utils/formatPhone';
import BodyText from '../Texts/BodyText';
import { Item, ListItem } from './style';

function List({ group, population, date, phone, decreeDate }) {
  const dataItens = {
    group: {
      label: 'Grupo de implementação',
      value: group,
    },
    population: {
      label: 'População',
      value: population,
    },
    // stage: {
    //   label: 'Fase prevista',
    //   value: stage,
    // },
    date: {
      label: 'Data de adesão',
      value: formatDate(date, false) || '-',
    },
    phone: {
      label: 'Telefone da prefeitura',
      value: formatPhone(phone) || '-',
    },
    decreeDate: {
      label: 'Data do decreto',
      value: formatDate(decreeDate, false) || '-',
    },
  };

  function createItem(item) {
    const { label, value } = dataItens[item];

    return (
      <Item key={label}>
        <BodyText color="text">{label}</BodyText> <h5>{value}</h5>
      </Item>
    );
  }

  return (
    <ListItem>{['group', 'population', 'date', 'phone', 'decreeDate'].map(createItem)}</ListItem>
  );
}

export default List;
