import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const WrapperDefault = styled.header`
  max-height: 341px;
  background: ${({ theme }) => theme.colors.primary.main};
`;

export const HeaderLarge = styled(WrapperDefault)``;

export const HeaderSmall = styled(WrapperDefault)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px 20px;
  width: 100%;
`;

export const Controls = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  flex-direction: center;

  svg {
    cursor: pointer;
  }

  a {
    max-height: 28px;
    padding: 4px;
  }
`;

export const Logout = styled(Link)`
  background: transparent;
  display: flex;
`;
