import { useState, useEffect, useRef } from 'react';
import { MdArrowUpward, MdSearch } from 'react-icons/md';
import { ControlButtons, SortButton, Input, Search, Span } from './style';

function ListHeader({
  placeholder,
  searchTerm,
  setSearchTerm,
  orderBy,
  handleToggleOrderBy,
  isEmpty,
  htmlFor,
  forceDisableButton,
}) {
  const [firstRender, setFirstRender] = useState(true);
  const inputButton = useRef(null);

  useEffect(() => {
    if (!forceDisableButton && !firstRender) {
      inputButton.current.focus();
    }
    setFirstRender(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceDisableButton]);

  return (
    <ControlButtons>
      <Search htmlFor={htmlFor} disabled={isEmpty || forceDisableButton}>
        <Input
          ref={inputButton}
          id={htmlFor}
          type="text"
          placeholder={placeholder}
          value={searchTerm}
          onChange={setSearchTerm}
          autoComplete="off"
          disabled={isEmpty || forceDisableButton}
        />
        <MdSearch size={20} />
      </Search>
      <SortButton onClick={handleToggleOrderBy} disabled={isEmpty}>
        Ordenar
        <Span orderBy={orderBy}>
          <MdArrowUpward size={20} />
        </Span>
      </SortButton>
    </ControlButtons>
  );
}

export default ListHeader;
