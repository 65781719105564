import React, { useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import theme from '../../styles/theme';
import { Wrapper, Container, TextContainer, ControlContainer, ButtonLimiter } from './style';
import { Button } from '../index';

function Panel({ content }) {
  const [pageNumber, setPageNumber] = useState(0);

  const sumPageNumber = number => {
    const result = pageNumber + number;
    if (result < 0 || result > content.length - 1) return;

    setPageNumber(result);
  };

  return (
    <Wrapper>
      <Container>
        <TextContainer>{content && content[pageNumber]}</TextContainer>
        <ControlContainer>
          <ButtonLimiter>
            <Button onClick={() => sumPageNumber(-1)}>
              <FaAngleLeft size={20} color={theme.colors.accentRegular} />
            </Button>
          </ButtonLimiter>
          <ButtonLimiter>
            <Button onClick={() => sumPageNumber(1)}>
              <FaAngleRight size={20} color={theme.colors.accentRegular} />
            </Button>
          </ButtonLimiter>
        </ControlContainer>
      </Container>
    </Wrapper>
  );
}

export default Panel;
