import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Button = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  width: 100%;
  padding: 12px 16px;
  color: ${({ theme }) => theme.colors.altText};
  background: ${({ theme }) => theme.colors.primary.main};
  text-decoration: none;
`;
