import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from 'react-router';
import { getClients, getCustomerByUserId } from '../../services/customers';
import { selectCustomers, selectCustomerStatus } from '../../redux/slices/customerSlice';
import { selectStatus, selectUser, selectUserPermission } from '../../redux/slices/authSlice';

import {
  Loading,
  PageLayout,
  RoadMapList,
  Faq,
  CustomersList,
  OutlinedButton,
} from '../../components';
import { Section } from './style';
import { SectionTitle } from '../../components/Texts/HeadingText';
import useDoubtsAndScripts from '../../hooks/useDoubtsAndScripts';

function Customers() {
  const customers = Object.values(useSelector(selectCustomers));
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const status = useSelector(selectStatus);
  const customersStatus = useSelector(selectCustomerStatus);
  const userPermission = useSelector(selectUserPermission);
  const history = useHistory();
  const { doubts, scripts } = useDoubtsAndScripts();
  useEffect(() => {
    if (userPermission === 'admin') {
      history.push('/dashboard');
    }
  }, [userPermission, history]);
  useEffect(() => {
    try {
      if (user?.id) {
        dispatch(getClients({ ...user, reload: false }));
        dispatch(getCustomerByUserId(user?.id));
      }
    } catch (err) {
      console.log('error', err);
    }
  }, [dispatch, user, user.id]);

  function handleUpdateCustomerList() {
    dispatch(getClients({ ...user, reload: true }));
  }

  const isPageLoading = [status, customersStatus].includes('loading');
  useEffect(() => {}, [isPageLoading, status, customersStatus]);
  useEffect(() => {
    handleUpdateCustomerList();
  }, []);
  return (
    <PageLayout>
      {isPageLoading ? (
        <Loading />
      ) : (
        <>
          <Section>
            <SectionTitle>Municípios</SectionTitle>
            <CustomersList noCircle customers={customers} />
            <OutlinedButton onClick={handleUpdateCustomerList}>
              Atualizar lista de munícipios
            </OutlinedButton>
          </Section>

          {!doubts || !scripts ? (
            <Loading />
          ) : (
            <>
              <Section>
                <SectionTitle>Roteiros</SectionTitle>
                <RoadMapList scripts={scripts} />
              </Section>

              <Section>
                <SectionTitle>FAQ</SectionTitle>
                <Faq doubts={doubts} />
              </Section>
            </>
          )}
        </>
      )}
    </PageLayout>
  );
}

export default Customers;
