import fetcher from '../fetcher';

export const getAllSicgespLicenserByCustomerId = async id => {
  const fetch = fetcher(true);

  const {
    data: { data },
  } = await fetch.get(`/sicgespLicense/${id}`);

  return data;
};

export const createLicenseAndFirstRegister = async ({
  cpf,
  name,
  phone,
  email,
  password,
  url,
  customerId,
}) => {
  const fetch = fetcher(true);

  const {
    data: { data },
  } = await fetch.post(`/sicgespLicense`, { cpf, name, phone, email, password, url, customerId });

  return data;
};

export const addRegister = async ({ cpf, name, phone, email, password, licenseId }) => {
  const fetch = fetcher(true);

  const {
    data: { data },
  } = await fetch.post(`/sicgespLicense/addPassword`, {
    cpf,
    name,
    phone,
    email,
    password,
    linceseId: licenseId,
  });

  return data;
};

export const deleteLicense = async registerId => {
  const fetch = fetcher(true);

  const {
    data: { data },
  } = await fetch.delete(`/sicgespLicense/${registerId}`);

  return data;
};
export const deleteLicensePassword = async registerId => {
  const fetch = fetcher(true);

  const {
    data: { data },
  } = await fetch.delete(`/sicgespLicense/deletePassword/${registerId}`);

  return data;
};
