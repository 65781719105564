import styled from 'styled-components';

export const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 60px 0px 45px;

  p {
    text-align: left;
    margin-bottom: 4px;
  }

  button {
    align-self: center;
    margin-top: 17px;
    padding: 5px 10px;
    font-size: 0.875rem;
  }
`;

export const SectionControls = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  gap: 20px;

  a {
    width: 100%;
  }
`;
