import styled, { keyframes } from 'styled-components';

const Animation = keyframes`
  0% {
    margin-top: calc(-100% - 200px);
  }
  100% {
    margin-top: 0px;
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
`;

export const Content = styled.div`
  width: ${({ large, medium }) => (large || medium ? '500px' : '200px')};
  min-height: ${({ large }) => (large ? '300px' : '200px')};
  max-height: 400px;
  padding: 24px 12px;
  border-radius: 5px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);

  animation: 0.6s ${Animation} ease-in-out forwards;

  h1 {
    margin-bottom: 10px;
  }

  p {
    max-width: ${({ large }) => (large ? '400px' : '200px')};
    display: inline-block;
    color: ${({ theme }) => theme.colors.primary.regular};
    word-wrap: break-word;
    white-space: pre-line;
    padding: 0 20px;
    text-align: left;

    font-size: 0.875rem;
    overflow-y: ${({ large, medium }) => (large || !medium) && 'scroll'};
  }
`;
