/* eslint-disable react/jsx-props-no-spreading */
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { selectUserPermission } from '../redux/slices/authSlice';
import { verifyToken, logout } from '../services/auth';
import { getUserByToken } from '../services/user';

function AdminRoute(props) {
  const { component: Component, ...rest } = props;
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const userPermission = useSelector(selectUserPermission);
  const token = Cookies.get('token');

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    try {
      dispatch(getUserByToken());
      dispatch(verifyToken());
    } catch (err) {
      // console.log(err);
    }

    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [token, dispatch]);

  useEffect(() => {
    dispatch(verifyToken());
  }, [dispatch]);

  if (!isAuthenticated && !isFirstRender) {
    dispatch(logout());
  }

  if (!isAuthenticated || userPermission !== 'admin') return <Redirect to="/" />;

  return <Route {...rest} component={routeProps => <Component {...routeProps} />} />;
}

export default AdminRoute;
