import { createSlice } from '@reduxjs/toolkit';
import { getAllNews, getAllNewsByCycle, createNews } from '../../services/news';

const initialState = {
  news: [],
  status: null,
  error: '',
};

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {},
  extraReducers: {
    [getAllNews.pending]: state => {
      state.status = 'loading';
    },
    [getAllNews.fulfilled]: (state, action) => {
      state.news = action.payload.reverse();
      state.status = 'sucess';
    },
    [getAllNews.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [getAllNewsByCycle.pending]: state => {
      state.status = 'loading';
    },
    [getAllNewsByCycle.fulfilled]: (state, action) => {
      state.news = action.payload;
      state.status = 'sucess';
    },
    [getAllNewsByCycle.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [createNews.pending]: state => {
      state.status = 'loading';
    },
    [createNews.fulfilled]: (state, action) => {
      state.news = [action.payload, ...state.news];
    },
    [createNews.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
  },
});

export const selectAllNews = state => state.news.news;

export default newsSlice.reducer;
