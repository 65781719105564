import styled, { keyframes } from 'styled-components';

const Animation = keyframes`
  0% {
    margin-top: calc(-100% - 200px);
  }
  100% {
    margin-top: 0px;
  }
`;

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);

  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 334px;
  border-radius: 4px;
  padding: 40px 20px;
  background: ${({ theme }) => theme.colors.altText};
  animation: 0.3s ${Animation} ease-out;

  box-shadow: 8px 4px 10px rgba(0, 0, 0, 0.04);

  p {
    margin-top: 8px;
  }
`;

export const Form = styled.form`
  margin-top: 24px;
  padding: 0 16px;

  label {
    font-size: 0.75rem;
  }

  input {
    border: 1px solid ${({ theme }) => theme.colors.gray.main};
    height: 40px;

    font-size: 0.875rem;
  }
`;

export const Footer = styled.footer`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  button {
    font-size: 0.875rem;
    margin-top: 0;
    padding: 5px 10px;
    width: fit-content;

    &:first-of-type {
      padding: 3px 8px;
    }
  }
`;
