const theme = {
  fontFamily: 'Lato',
  fontFamilySecondary: 'Montserrat',
  fontDefault: 'sans-serif',
  colors: {
    white: '#FFFFFF',
    black: '#111111',
    text: '#181818',
    disabled: '#7E7E7E',
    altText: '#FFFFFF',
    altBackground: '#E1E1E1',
    // background: '#F6F5FC',
    primary: {
      main: '#078C03',
      dark: '#096032',
      regular: '#7E7E7E',
      background: '#F2F2F2',
      border: '#AAAAAA',
      light: '#E6E6E6',
      black: '#181818',
      accent: '#030A8C',
      yellow: '#F2CB05',
      white: '#FFFFFF',
      text: '#181818',
    },
    alert: {
      main: '#F24027',
    },
    secondary: {
      light: '#767676',
    },
    gray: {
      300: '#AAAAAA',
      500: '#767676',
    },
  },
};

export default theme;
