/* eslint-disable camelcase */
import { createSlice, current } from '@reduxjs/toolkit';
import { logout } from '../../services/auth';
import {
  getClientsByCycle,
  getClient,
  updateImplementationStatus,
  getAllCustomers,
  delegateOrTransferManyCustomers,
  removeManyCustomersOfAnUserResponsible,
  createCustomer,
  updateCustomerData,
  getCustomerEmails,
  updateCustomerEmail,
  getCustomerByUserId,
} from '../../services/customers';
import { createPerson } from '../../services/person';
import {
  createProspector,
  deleteProspectorById,
  editProspector,
  getAllProspectorsByCustomerId,
} from '../../services/prospection';
import { setCustomerCycle } from '../../services/cycle';
import { editStatus, getStatus, registerStatus } from '../../services/calendar';
import { completeYear } from '../../utils/calendar';

const initialState = {
  customers: {},
  responsible: {},
  status: null,
  error: '',
  createProspectorStatus: false,
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {},
  extraReducers: {
    [getCustomerEmails.pending]: state => {
      state.status = 'loading';
    },
    [getCustomerEmails.fulfilled]: (state, action) => {
      const { customerId, emails } = action.payload;

      state.customers[customerId] = {
        ...state.customers[customerId],
        emails,
      };
      state.status = 'success';
    },
    [getCustomerEmails.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [updateCustomerEmail.pending]: state => {
      state.status = 'loading';
    },
    [updateCustomerEmail.fulfilled]: state => {
      // state.customers = action.payload;
      state.status = 'success';
    },
    [updateCustomerEmail.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [getClientsByCycle.pending]: state => {
      state.status = 'loading';
    },
    [getClientsByCycle.fulfilled]: (state, action) => {
      state.customers = action.payload;
      state.status = 'success';
    },
    [getClientsByCycle.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [getClient.pending]: state => {
      state.status = 'loading';
    },
    [getClient.fulfilled]: (state, action) => {
      state.customers = { ...state.customers, ...action.payload };
      state.status = 'success';
    },
    [getClient.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [createPerson.pending]: state => {
      state.status = 'loading';
    },
    [createPerson.fulfilled]: (state, action) => {
      const { customerId, personName, personId } = action.payload[1];
      state.customers[customerId].people = [
        ...state.customers[customerId].people,
        { id: personId, name: personName },
      ];
      state.status = 'success';
    },
    [createPerson.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [updateImplementationStatus.fulfilled]: (state, action) => {
      const { id: customerId, statusImplementationId, implementationStatus } = action.payload;
      state.customers[customerId] = {
        ...state.customers[customerId],
        statusImplementationId,
        implementationStatus,
      };
      state.status = 'success';
    },
    [updateImplementationStatus.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [getAllCustomers.pending]: state => {
      state.status = 'loading';
    },
    [getAllCustomers.fulfilled]: (state, action) => {
      state.customers = { ...action.payload, ...state.customers };
      state.status = 'success';
    },
    [getAllCustomers.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [delegateOrTransferManyCustomers.pending]: state => {
      state.status = 'loading';
    },
    [delegateOrTransferManyCustomers.fulfilled]: (state, action) => {
      state.customers = {
        ...state.customers,
        ...action.payload,
      };
      state.status = 'success';
    },
    [delegateOrTransferManyCustomers.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [removeManyCustomersOfAnUserResponsible.pending]: state => {
      state.status = 'loading';
    },
    [removeManyCustomersOfAnUserResponsible.fulfilled]: (state, action) => {
      state.customers = action.payload;
      state.status = 'success';
    },
    [removeManyCustomersOfAnUserResponsible.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [logout.pending]: state => {
      state.status = 'loading';
    },
    [logout.fulfilled]: state => {
      state.customers = {};
    },
    [logout.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [createCustomer.pending]: state => {
      state.status = 'loading';
    },
    [createCustomer.fulfilled]: (state, action) => {
      state.customers = {
        ...state.customers,
        [action.payload.id]: action.payload,
      };
      state.status = 'success';
    },
    [createCustomer.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [updateCustomerData.pending]: state => {
      state.status = 'loading';
    },
    [updateCustomerData.fulfilled]: (state, action) => {
      const { id: customerId, ...body } = action.payload;
      state.customers[customerId] = {
        ...state.customers[customerId],
        ...body,
      };
      state.status = 'success';
    },
    [updateCustomerData.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },

    [createProspector.pending]: state => {
      state.status = 'loading';
    },
    [createProspector.fulfilled]: (state, action) => {
      const {
        // id,
        // name,
        // email,
        // phone,
        // whatsapp,
        customer: { id: customerId },
      } = action.payload;

      // const newProspector = {
      //   id,
      //   name,
      //   email,
      //   phone,
      //   whatsapp,
      // };

      const prospectors = current(state.customers[customerId]);
      console.log(prospectors);
      // state.customers[customerId].prospectors = [
      //   ...state.customers[customerId].prospectors,
      //   newProspector,
      // ];
      state.createProspectorStatus = {
        situation: 'success',
        message: 'Prospector criado com sucesso',
      };
    },
    [createProspector.rejected]: (state, action) => {
      state.error = action.error;
      state.createProspectorStatus = {
        situation: 'failed',
        message: 'Um erro ocorreu ao criar o prospector',
      };
      // state.createProspectorStatus = 'failed';
    },
    [getAllProspectorsByCustomerId.pending]: state => {
      state.status = 'loading';
    },
    [getAllProspectorsByCustomerId.fulfilled]: (state, action) => {
      const { customerId, prospectors } = action.payload;
      state.customers[customerId] = {
        ...state.customers[customerId],
        prospectors,
      };
      state.status = 'success';
    },
    [getAllProspectorsByCustomerId.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },

    [editProspector.pending]: state => {
      state.status = 'loading';
    },
    [editProspector.fulfilled]: (state, action) => {
      const { id, name, email, phone, whatsapp, customerId } = action.payload;

      const prospectorModified = {
        id,
        name,
        email,
        phone,
        whatsapp,
      };

      const newProspectors = state.customers[customerId].prospectors.filter(
        prospector => prospector.id !== id,
      );

      state.customers[customerId] = {
        ...state.customers[customerId],
        prospectors: [...newProspectors, prospectorModified],
      };
      state.status = 'success';
    },
    [editProspector.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },

    [deleteProspectorById.pending]: state => {
      state.status = 'loading';
    },
    [deleteProspectorById.fulfilled]: (state, action) => {
      const { customerId, prospectorId } = action.payload;

      const newProspectors = state.customers[customerId].prospectors.filter(
        prospector => prospector.id !== prospectorId,
      );

      state.customers[customerId] = {
        ...state.customers[customerId],
        prospectors: [...newProspectors],
      };
      state.status = 'success';
    },
    [deleteProspectorById.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [setCustomerCycle.pending]: state => {
      state.status = 'loading';
    },
    [setCustomerCycle.fulfilled]: (state, action) => {
      const { customerId, customerCycle } = action.payload;
      state.customers[customerId] = {
        ...state.customers[customerId],
        customerCycle: [customerCycle],
      };

      state.status = 'success';
    },
    [setCustomerCycle.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },

    [registerStatus.pending]: state => {
      state.status = 'loading';
    },
    [registerStatus.fulfilled]: (state, action) => {
      const {
        id,
        service_level,
        management_report,
        relocation,
        sent,
        year,
        month: m,
        customerId,
      } = action.payload;
      const months = state.customers[customerId]?.calendar?.[year].filter(
        month => month.month !== m,
      );

      const month = {
        id,
        service_level,
        management_report,
        relocation,
        sent,
        year,
        month: m,
      };

      let insertIndex = -1;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < months.length; i++) {
        if (months[i].month > month.month) {
          insertIndex = i;
          break;
        }
      }

      if (insertIndex === -1) {
        months.push(month);
      } else {
        months.splice(insertIndex, 0, month);
      }

      state.customers[customerId].calendar = {
        ...state.customers[customerId].calendar,
        [year]: [...months],
      };
      state.status = 'success';
    },
    [registerStatus.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },

    [editStatus.pending]: state => {
      state.status = 'loading';
    },
    [editStatus.fulfilled]: (state, action) => {
      const {
        id,
        service_level,
        management_report,
        relocation,
        sent,
        year,
        month: m,
        customerId,
      } = action.payload;
      const months = state.customers[customerId]?.calendar?.[year]?.filter(
        month => month.month !== m,
      );

      const month = { id, service_level, management_report, relocation, sent, year, month: m };

      let insertIndex = -1;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < months.length; i++) {
        if (months[i].month > month.month) {
          insertIndex = i;
          break;
        }
      }

      if (insertIndex === -1) {
        months.push(month);
      } else {
        months.splice(insertIndex, 0, month);
      }

      state.customers[customerId].calendar = {
        ...state.customers[customerId].calendar,
        [year]: [...months],
      };
      state.status = 'success';
    },
    [editStatus.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },

    [getStatus.pending]: state => {
      state.status = 'loading';
    },
    [getStatus.fulfilled]: (state, action) => {
      const { data, customerId } = action.payload;

      let months;
      const dataFormated = {};
      if (!data) {
        return;
      }
      const years = [];

      if (Object?.keys(data)?.length === 0) {
        const months20 = completeYear([], 2020);
        const months21 = completeYear([], 2021);
        const months22 = completeYear([], 2022);
        const months23 = completeYear([], 2023);
        dataFormated['2020'] = months20;
        dataFormated['2021'] = months21;
        dataFormated['2022'] = months22;
        dataFormated['2023'] = months23;
      } else {
        Object.entries(data).map(([year, month]) => {
          years.push(year);
          months = completeYear(month, year);
          dataFormated[year] = months;
          return null;
        });

        [1, 2, 3].forEach(i => {
          // eslint-disable-next-line no-prototype-builtins
          if (data.hasOwnProperty(2023 - i)) return;
          months = completeYear([], 2023 - i);
          dataFormated[2023 - i] = months;
        });
      }

      state.customers[customerId].calendar = dataFormated;
      state.status = 'success';
    },
    [getStatus.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [getCustomerByUserId.pending]: state => {
      state.status = 'loading';
    },
    [getCustomerByUserId.fulfilled]: (state, action) => {
      state.responsible = action.payload;
      state.status = 'success';
    },
    [getCustomerByUserId.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
  },
});
export const selectStatus = state => state.customer.createProspectorStatus;
export const selectCustomers = state => state.customer.customers;
export const selectCustomersTeste = state =>
  Object.values(state.customer?.customers)[0]?.user?.customers;
export const selectCustomer = (state, id) => state.customer.customers[id];
export const selectCustomerTeste = (state, id) =>
  Object.values(state.customer?.customers)[0]?.user?.customers.filter(c => c.id === id);
export const selectCustomerStatus = state => state.customer.status;
export const selectCustomerEmails = (state, id) => state?.customer.customers[id]?.emails;
export const selectProspectorsByCustomer = (state, id) =>
  state?.customer?.customers[id]?.prospectors;
export const selectProspectorById = (state, id) =>
  state?.customer.customers[id].prospectors.filter(prospector => prospector.id === id);
export const selectCreateStatus = state => state.customer.customers;
export const selectAllCustomerWithoutCycleAndUserResponsible = state =>
  state.customer.customers.filter(
    customer => !customer.customerCycle.length && !customer?.userResponsibleId,
  );

export const selectCalendar = (state, id) => state?.customer?.customers[id]?.calendar;
// export const selectUserCustomers = (state, userId) => state.customer.customers.filter(c => c.userResponsible === userId);
export default customerSlice.reducer;
