import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import useForms from '../../hooks/useForms';
import { formatCpf } from '../../utils/formatCpf';
import { formatPhone } from '../../utils/formatPhone';
import { FirstField, Info } from './style';
import EditIcon from '../../assets/imgs/edit-primary.svg';

import { Details, OutlinedButton } from '..';
import { Image } from '../../pages/ContactInformation/style';
import { deletePerson } from '../../services/person';
import { selectPeople } from '../../redux/slices/peopleSlice';
import { getClient } from '../../services/customers';

function BodyDetails({ data, customerId }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const { fields, createFields, handleAllFieldsChange } = useForms();
  const people = useSelector(selectPeople);
  useEffect(() => {
    if (data) {
      handleAllFieldsChange(data);
      setDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const fieldsObject = {
    whatsapp: {
      label: 'Whatsapp',
      value: formatPhone(fields.whatsapp),
      maxLength: 15,
      disabled,
    },
    phone: {
      label: 'Telefone',
      value: formatPhone(fields.phone),
      maxLength: 15,
      disabled,
    },
    email: {
      label: 'Email',
      value: fields.email,
      type: 'email',
      disabled,
    },
    role: {
      label: 'Cargo',
      value: fields.role,
      disabled,
    },
    cpf: {
      label: 'CPF',
      value: formatCpf(fields.cpf),
      maxLength: 14,
      disabled,
    },
  };

  useEffect(() => {
    dispatch(getClient(customerId));
  }, [people]);

  const handleDelete = id => {
    dispatch(deletePerson(id));
  };

  return (
    <Details summary={fields.name}>
      <Info>
        <FirstField>
          {['whatsapp', 'phone'].map(field => createFields(field, fieldsObject))}
        </FirstField>

        {['email', 'role', 'cpf'].map(field => createFields(field, fieldsObject))}

        <br />
        <OutlinedButton
          onClick={() =>
            history.push(`/contact/add-representative/${data.id}`, { ...data, customerId })
          }
        >
          <Image alt="Ícone de edição" src={EditIcon} />
          Editar representante
        </OutlinedButton>
        <br />
        <OutlinedButton alert type="button" onClick={() => handleDelete(data.id)}>
          Deletar representante
        </OutlinedButton>
      </Info>
    </Details>
  );
}

export default BodyDetails;
