import { createAsyncThunk } from '@reduxjs/toolkit';
import fetcher from '../fetcher';

export const updateProfile = createAsyncThunk('update/user', async ({ name, email, password }) => {
  const fetch = fetcher(true);

  const {
    data: { data },
  } = await fetch.patch('/auth/update', { name, email, password });

  return data;
});

export const updatePassword = createAsyncThunk(
  'updatePassword/user',
  async ({ password, newPassword }) => {
    const fetch = fetcher(true);

    const {
      data: { data },
    } = await fetch.patch('/auth/update/password', { password, newPassword });

    return data;
  },
);

export const getCollaborators = createAsyncThunk('getCollaborators/user', async () => {
  const fetch = fetcher(true);

  const {
    data: { data },
  } = await fetch.get('/user/collaborator');
  return data;
});

export const getUserByToken = createAsyncThunk('get-by-token/user', async () => {
  const fetch = fetcher(true);

  const {
    data: { data: user },
  } = await fetch.get('/user/by-token');

  return user;
});
