import { useRef } from 'react';

export default function useDebounce(fn, delay, setLoading) {
  const timeoutRef = useRef(null);

  function debouncedFunction(...props) {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setLoading(true);
      fn(...props);
    }, delay);
  }

  return debouncedFunction;
}
