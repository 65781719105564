import { createSlice } from '@reduxjs/toolkit';
import { login, logout, register, verifyToken } from '../../services/auth';
import { getUserByToken, updatePassword, updateProfile } from '../../services/user';

const initialState = {
  isAuthenticated: false,
  status: null,
  user: {
    id: '',
    email: '',
    name: '',
    teams: [],
    permission: '',
  },
  error: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setStatus: (state, action) => ({
      ...state,
      status: action.payload,
    }),
  },
  extraReducers: {
    [login.pending]: state => {
      state.status = 'loading';
    },
    [login.fulfilled]: (state, action) => {
      state.user = { ...state.user, ...action.payload };
      state.status = 'sucess';
      state.isAuthenticated = true;
    },
    [login.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
      state.isAuthenticated = false;
    },
    [getUserByToken.pending]: state => {
      state.status = 'loading';
    },
    [getUserByToken.fulfilled]: (state, action) => {
      state.user = { ...state.user, ...action.payload };
      state.status = 'sucess';
      state.isAuthenticated = true;
    },
    [getUserByToken.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
      state.isAuthenticated = false;
    },
    [register.pending]: state => {
      state.status = 'loading';
    },
    [register.fulfilled]: state => {
      state.status = 'sucess';
    },
    [register.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [verifyToken.pending]: state => {
      state.status = 'loading';
    },
    [verifyToken.fulfilled]: (state, action) => {
      state.status = 'sucess';
      state.isAuthenticated = action.payload;
    },
    [verifyToken.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'authentication failed';
      state.isAuthenticated = false;
    },
    [updateProfile.pending]: state => {
      state.status = 'loading';
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.status = 'sucess';
      state.user = { ...state.user, ...action.payload };
      state.isAuthenticated = true;
    },
    [updateProfile.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [updatePassword.pending]: state => {
      state.status = 'loading';
    },
    [updatePassword.fulfilled]: (state, action) => {
      state.status = 'sucess';
      state.user = { ...state.user, ...action.payload };
      state.isAuthenticated = true;
    },
    [updatePassword.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [logout.pending]: state => {
      state.status = 'loading';
    },
    [logout.fulfilled]: state => {
      state.user = initialState.user;
      state.isAuthenticated = false;
    },
  },
});

export const { authenticate, deny, setStatus } = authSlice.actions;
export const selectStatus = state => state.auth.status;
export const selectUser = state => state.auth.user;
export const selectUserPermission = state => state.auth.user.permission;
export const selectError = state => state.auth.error;
export default authSlice.reducer;
