import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

export const List = styled.ul`
  width: 100%;
  margin-bottom: ${({ addButton }) => !addButton && '40px'};
  max-height: ${({ large }) => (large ? '500px' : '300px')};
  overflow: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }

  p {
    margin: 16px 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primary.main};
    border-radius: 20px;
    border: 1px solid none;
  }
`;

export const Item = styled.li`
  list-style-type: none;
  background-color: ${({ theme }) => theme.colors.altText};
  font-size: 1rem;
  border-bottom: 2px solid ${({ theme }) => theme.colors.altBackground};
`;

export const Navigation = styled(Link)`
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 16px;
  min-width: 100%;
  text-decoration: none;
`;

const isRotating = keyframes`
  100% {
    transform: rotate(1turn);
  }
`;

export const LoaderContainer = styled.div`
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loader = styled.div`
  width: 50px;
  height: 50px;
  border: 6px solid #e5e5e5;
  border-radius: 50%;
  border-top-color: ${({ theme }) => `${theme.colors.primary.main}`};
  animation: ${isRotating} ease 1s infinite;
`;

export const ExtraInformation = styled.div`
  background-color: rgb(205 203 203);
  color: white;
  font-size: 12px;
  padding: 4px;
  border-radius: 4px;
`;
