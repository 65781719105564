import ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Overlay, Container, Footer, Form } from './styles';
import { selectError, selectStatus } from '../../redux/slices/authSlice';
import { BodyText, Button, OutlinedButton } from '..';
import useForms from '../../hooks/useForms';

function ConfirmPasswordModal({ isOpen, onClose, modalHandler }) {
  const status = useSelector(selectStatus);
  const errorCode = useSelector(selectError);
  const [firstRender, setFirstRender] = useState(true);
  const { fields, createFields, errors, setError } = useForms();
  const { currentPassword, password, confirmPassword } = fields;

  useEffect(() => {
    if (status === 'failed' && !firstRender) {
      const message = errorCode.message.endsWith('409')
        ? 'E-mail já está em uso!'
        : 'Senha invalida!';
      setError({ field: 'currentPassword', message });
    }

    if (status === 'sucess' && !firstRender) {
      onClose();
    }

    if (firstRender) {
      setFirstRender(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const fieldsObject = {
    currentPassword: {
      label: 'Digite sua senha atual',
      value: currentPassword,
      placeholder: 'Senha atual',
      type: 'password',
      autoComplete: 'on',
    },
    password: {
      label: 'Digite sua nova senha',
      value: password,
      placeholder: 'Nova senha',
      type: 'password',
      confirm: true,
      autoComplete: 'on',
    },
    confirmPassword: {
      label: 'Confirme sua nova senha',
      value: confirmPassword,
      placeholder: 'Nova senha',
      type: 'password',
      autoComplete: 'on',
    },
  };

  const isFormValid =
    !errors.length && modalHandler?.changePassword
      ? confirmPassword && password && currentPassword
      : currentPassword;

  async function handleConfirm() {
    await modalHandler?.action(currentPassword, password);
  }

  function handleCancel() {
    onClose();
  }

  return (
    isOpen &&
    ReactDOM.createPortal(
      <Overlay>
        <Container>
          <BodyText color="black">
            {modalHandler?.changePassword
              ? 'Alterar senha'
              : 'Tem certeza que deseja alterar seus dados?'}
          </BodyText>
          <Form onSubmit={event => event.preventDefault()}>
            {createFields('currentPassword', fieldsObject)}
            {modalHandler?.changePassword &&
              ['password', 'confirmPassword'].map(field => createFields(field, fieldsObject))}
          </Form>

          <Footer>
            <OutlinedButton onClick={handleCancel}>Cancelar</OutlinedButton>

            <Button type="submit" onClick={handleConfirm} disabled={!isFormValid}>
              {modalHandler?.label}
            </Button>
          </Footer>
        </Container>
      </Overlay>,
      document.getElementById('modal-root'),
    )
  );
}

export default ConfirmPasswordModal;
