import ReactDOM from 'react-dom';

import { Overlay, Container, Footer } from './styles';

import { Button, Loading } from '../../../../components';
import useForms from '../../../../hooks/useForms';

function DropdownModal({
  isOpen,
  modalHandler,
  onClose,
  label,
  placeholder,
  options = [],
  selectName,
  setSelected,
  loading,
  generateCsvButton = null,
}) {
  const { createDropdownFields } = useForms();

  function handleConfirm() {
    modalHandler.action();
    onClose();
  }

  const fieldsObject = {
    cycles: {
      label: label || 'Ciclo',
      placeholder: placeholder || 'Selecione um Ciclo',
      options,
      onSelect: setSelected,
      selectName,
    },
  };

  function renderConfirmButton() {
    if (!generateCsvButton) {
      return (
        <Button onClick={handleConfirm} type="button">
          {modalHandler.label}
        </Button>
      );
    }

    return generateCsvButton();
  }

  return (
    isOpen &&
    ReactDOM.createPortal(
      <Overlay>
        <Container>
          {createDropdownFields('cycles', fieldsObject)}
          {loading && <Loading />}
          <Footer>
            {modalHandler?.label ? (
              <>
                <Button onClick={onClose} danger>
                  Cancelar
                </Button>

                {renderConfirmButton()}
              </>
            ) : (
              <Button onClick={onClose}>Confirmar</Button>
            )}
          </Footer>
        </Container>
      </Overlay>,
      document.getElementById('modal-root'),
    )
  );
}

export default DropdownModal;
