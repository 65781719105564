import { createSlice, current } from '@reduxjs/toolkit';
import { logout } from '../../services/auth';
import { createContact, getCustomerContacts } from '../../services/contact';

const initialState = {
  contacts: [],
  status: null,
  error: '',
};

export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {},
  extraReducers: {
    [createContact.pending]: state => {
      state.status = 'loading';
    },
    [createContact.fulfilled]: (state, action) => {
      const contacts = current(state.contacts);
      const newContacts = contacts.map(item => ({ ...item }));
      newContacts.push(action.payload);
      state.contacts = newContacts;
      state.status = 'sucess';
    },
    [createContact.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [getCustomerContacts.pending]: state => {
      state.status = 'loading';
    },
    [getCustomerContacts.fulfilled]: (state, action) => {
      state.contacts = action.payload;
      state.status = 'sucess';
    },
    [getCustomerContacts.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [logout.pending]: state => {
      state.status = 'loading';
    },
    [logout.fulfilled]: state => {
      state.contacts = [];
    },
  },
});

export default contactSlice.reducer;
