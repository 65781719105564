import styled, { css, keyframes } from 'styled-components';

export const Title = styled.div`
  max-width: 334px;
  text-align: center;
`;

export const SubTitle = styled.div`
  max-width: 275px;
  text-align: center;

  b {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const Section = styled.section`
  max-width: 334px;
  width: 100%;

  ${({ editable }) =>
    editable &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;

      ul {
        width: 100%;
      }

      button {
        padding: 5px 10px;
        margin-top: 20px;
      }
    `}
`;

export const SectionTitle = styled.h2`
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.primary.main};
  margin: 40px 0px 20px;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
`;

export const sweep = keyframes`
  from {
    opacity: 0; 
    transform: translateY(-10px)
  }

  to {
    opacity: 1; 
    transform: translateY(0)
  }
`;

export const Image = styled.img``;
