import React from 'react';
import { Line, Progress } from './style';

function ProgressBar({ step = 0 }) {
  return (
    <Line>
      <Progress step={step * 20} />
    </Line>
  );
}

export default ProgressBar;
