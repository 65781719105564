import React, { useMemo, useState } from 'react';
import { AiOutlineSend } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { PageLayout, Button, PeopleList } from '../../../components';
import { selectCollaboratorByCycle } from '../../../redux/slices/collaboratorSlice';
import { delegateOrTransferManyCustomers } from '../../../services/customers';
import { SecondaryButton, ButtonContainer, Title } from './style';

function ChoosePerson({ location }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = location?.state?.userId;
  const customers = location?.state?.customers;
  const userCycle = location?.state?.userCycle;
  // com o location do react-router podemos pegar os customers selecionados na page anterior.
  // const people = useSelector(selectCollaborators);
  const people = useSelector(state => selectCollaboratorByCycle(state, userCycle));
  const peopleFilted = Object.values(people).filter(person => person?.id !== Number(userId));

  const [itemSelected, setItemSelected] = useState(peopleFilted);

  if (!userId || !customers) history.goBack();

  const selectedPerson = useMemo(() => {
    const personFiltered = itemSelected.find(person => person.selected);
    return personFiltered;
  }, [itemSelected]);

  const PeopleListTest = useMemo(
    () => <PeopleList setItemSelected={setItemSelected} people={itemSelected} select />,
    [itemSelected, setItemSelected],
  );

  return (
    <PageLayout previous={`/dashboard/person/${userId}`}>
      <Title>Corpo tático</Title>
      {PeopleListTest}
      {/* {!PeopleListTest.length && <BodyText>Não há pessoas disponíveis para atribuir</BodyText>} */}
      <ButtonContainer>
        <Button
          onClick={async () => {
            try {
              dispatch(
                delegateOrTransferManyCustomers({
                  customerIds: customers,
                  userId: selectedPerson.id,
                }),
              );
              history.push('/dashboard');
            } catch (err) {
              // console.log(err);
            }
          }}
        >
          <AiOutlineSend size={20} />
          Atribuir
        </Button>
        <SecondaryButton to={`/dashboard/person/${userId}`}>Cancelar</SecondaryButton>
      </ButtonContainer>
    </PageLayout>
  );
}

export default ChoosePerson;
