import { createAsyncThunk } from '@reduxjs/toolkit';
import fetcher from '../fetcher';

export const createPerson = createAsyncThunk('create/person', async data => {
  const fetch = fetcher(true);

  const secondReturn = {
    customerId: Number(data.customerId),
  };

  const {
    data: { data: person },
  } = await fetch.post('/person', data);

  return [
    { [person.id]: person },
    { ...secondReturn, personName: person.name, personId: person.id },
  ];
});

export const getAllFieldsOfPerson = async () => {
  const fetch = fetcher(true);

  const { data } = await fetch.get(`/person/fields`);

  return data;
};

export const getPerson = createAsyncThunk('get/person', async id => {
  const fetch = fetcher(true);

  const { data } = await fetch.get(`/person/${id}`);

  const person = data.data;

  const formattedPerson = {
    [person.id]: person,
  };

  return formattedPerson;
});

export const editPerson = createAsyncThunk('edit/person', async data => {
  const fetch = fetcher(true);
  const {
    data: { data: person },
  } = fetch.put(`/person/${data.id}`, data);

  return { id: { ...person } };
});

export const deletePerson = createAsyncThunk('delete/person', async (id, { getState }) => {
  const fetch = fetcher(true);
  const state = getState();
  const people = state?.people.people;
  await fetch.delete(`/person/${id}`);
  const newPeople = Object.fromEntries(
    Object.entries(people).filter(([chave]) => Number(chave) !== Number(id)),
  );

  return newPeople;
});
