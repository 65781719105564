import React, { useEffect, useMemo } from 'react'; // useMemo
import // Link,
// useHistory,
'react-router-dom';
// import { useParams } from 'react-router';
// import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import {
  SectionTitle,
  Title,
  // SectionControls,
  TitleSecondary,
} from './style';
import {
  BodyText,
  CustomersList,
  HeadingText,
  // HeadingText,
  PageLayout,
  PeopleList,
  // CustomersList,
} from '../../../components';
import { getAllCustomerByCycleId, getAllUsersByCycleId } from '../../../services/cycle';
import {
  selectCustomersByCycleId,
  // selectUsersByCycleId
} from '../../../redux/slices/cycleSlice';
import { selectCollaboratorByCycle } from '../../../redux/slices/collaboratorSlice';
// import { actions } from './mock';
// import { selectCustomers } from '../../../redux/slices/customerSlice';

function Cycle() {
  // { location }
  // const history = useHistory();
  const { id } = useParams();
  const { state: cycle } = useLocation();
  const customers = useSelector(state => selectCustomersByCycleId(state, id));
  const users = useSelector(state => selectCollaboratorByCycle(state, id));
  const memoizedCustomers = useMemo(() => customers || [], [customers]);
  const memoizedUsers = useMemo(() => users || [], [users]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCustomerByCycleId(id));
    dispatch(getAllUsersByCycleId(id));
  }, [dispatch, id]);
  // const name = location?.state?.name;
  // const customersFilted = useMemo(
  //   () => Object.values(customers).filter(customer => customer?.userResponsible?.id === Number(id)),
  //   [customers, id],
  // );
  // if (!name) history.goBack();

  return (
    <PageLayout previous="/dashboard">
      <Title>
        <HeadingText color="text">{cycle.name}</HeadingText>
      </Title>

      {/* <Subtitle>
        <BodyText color="text">
          Equipe: <strong>Érida/Lucas</strong>
        </BodyText>
        <BodyText color="text">
          Status: <strong>Online</strong>
        </BodyText>
      </Subtitle> */}

      {/* <Section>
        <OutlinedButton disabled>Alterar equipe</OutlinedButton>
      </Section> */}

      <TitleSecondary>Municípios</TitleSecondary>

      {memoizedCustomers?.length ? (
        <CustomersList
          noCircle
          customers={memoizedCustomers}
          isCustomersListEmptySentence="O ciclo não possui municípios cadastrados."
        />
      ) : (
        <BodyText>O ciclo não possui municípios cadastrados.</BodyText>
      )}

      <>
        <SectionTitle>Integrantes do Ciclo</SectionTitle>
        <PeopleList people={memoizedUsers} isPeopleListEmptySentence={!memoizedUsers.length} />
      </>

      {/* <SectionControls>
        {actions.map(action => (
          <Link
            key={action.route}
            to={{ pathname: action.route, state: { userId: id, customers: customersFilted } }}
          >
            <OutlinedButton alert={action.alert}>{action.label}</OutlinedButton>
          </Link>
        ))}
      </SectionControls> */}
    </PageLayout>
  );
}

export default Cycle;
