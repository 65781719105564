import { useState } from 'react';
import FormGroup from '../components/FormGroup';
import { Dropdown, Input } from '../components';
import { useValidateForms } from '.';

export default function useForms(props) {
  const userName = props?.userName;
  const userEmail = props?.userEmail;

  const [fields, setFields] = useState({
    name: userName || '',
    email: userEmail || '',
    message: '',
    whatsapp: '',
    phone: '',
    role: '',
    cpf: '',
    password: '',
    confirmPassword: '',
    currentPassword: '',
    date: '',
    observation: '',
    title: '',
    text: '',
    team: '',
    population: '',
    stage: '',
    day: '',
    month: '',
    year: '',
    cycle: '',
    cycles: '',
    decreeDay: '',
    decreeMonth: '',
    decreeYear: '',
  });

  const { errors, getErrorMessageByFieldName, setError, removeError, validateFields } =
    useValidateForms({ fields });

  function handleFieldsChange({ confirm, messageIfHasError, validateField }) {
    return event => {
      const { name, value } = event.target;
      setFields(prevState => ({
        ...prevState,
        [name]: value,
      }));

      if (validateField) {
        validateFields({ confirm, name, event, messageIfHasError });
      }
    };
  }

  function handleAllFieldsChange(data) {
    setFields(prevState => ({ ...prevState, ...data }));
  }

  function createFields(field, fieldsObject, noMargin = false) {
    const {
      label,
      value,
      type,
      maxLength,
      placeholder,
      disabled,
      autoComplete,
      confirm,
      validateField = true,
      messageIfHasError = 'Campo obrigatório',
    } = fieldsObject[field];

    const getError = validateField ? getErrorMessageByFieldName(field) : '';

    return (
      <FormGroup key={label || placeholder} error={getError} noMargin={noMargin}>
        {label && <label>{label}</label>}
        <Input
          error={getError}
          name={field}
          type={type || 'text'}
          value={disabled ? value || '-' : value}
          onChange={handleFieldsChange({ confirm, messageIfHasError, validateField } || false)}
          maxLength={maxLength}
          disabled={disabled}
          placeholder={placeholder}
          autoComplete={autoComplete}
        />
      </FormGroup>
    );
  }

  function createDropdownFields(field, fieldsObject) {
    const {
      label = '',
      options,
      onSelect,
      placeholder,
      selectName,
      hasEdge,
      small,
      initialValue,
    } = fieldsObject[field];

    return (
      <FormGroup key={label || placeholder}>
        {label && <label>{label}</label>}
        <Dropdown
          field={field}
          label={placeholder}
          options={options}
          onSelect={onSelect}
          selectName={selectName}
          hasEdge={hasEdge}
          small={small}
          initialValue={initialValue}
        />
      </FormGroup>
    );
  }

  function clearAllFields() {
    const clearedFields = Object.keys(fields).reduce(
      (accumulator, field) => ({ ...accumulator, [field]: '' }),
      { ...fields },
    );
    setFields(clearedFields);
  }

  return {
    fields,
    handleFieldsChange,
    handleAllFieldsChange,
    clearAllFields,
    createFields,
    createDropdownFields,
    errors,
    setError,
    removeError,
  };
}
