import { createAsyncThunk } from '@reduxjs/toolkit';
import fetcher from '../fetcher';

// data => name, email, phone, whatsapp, customerId
export const createProspector = createAsyncThunk('createProspectus/prospection', async data => {
  const fetch = fetcher(true);
  const {
    data: { data: contact },
  } = await fetch.post('/prospection/', data);

  return contact;
});

// data => id, name, email, phone, whatsapp, customerId
export const editProspector = createAsyncThunk('editProspectus/prospection', async data => {
  const fetch = fetcher(true);
  const {
    data: { data: prospector },
  } = await fetch.put(`/prospection/${data.id}`, data);

  return { prospector, customerId: data?.customerId };
});

export const getAllProspectorsByCustomerId = createAsyncThunk(
  'getAllProspectusByCustomerId/prospection',
  async customerId => {
    const fetch = fetcher(true);
    const {
      data: { data: prospectors },
    } = await fetch.get(`/prospection/customer/${customerId}`);
    return { customerId, prospectors };
  },
);

export const getProspectorsById = createAsyncThunk('getProspectusById/prospection', async id => {
  const fetch = fetcher(true);
  const {
    data: { data: contact },
  } = await fetch.get(`/prospection/${id}`);

  return contact;
});

export const deleteProspectorById = createAsyncThunk(
  'deleteProspectusById/prospection',
  async ({ customerId, prospectorId }) => {
    const fetch = fetcher(true);
    await fetch.delete(`/prospection/${prospectorId}`);
    return { customerId, prospectorId };
  },
);
