import React from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { Button } from './style';

function AddButton({ children, to, state = null }) {
  return (
    <Button to={{ pathname: to, state }}>
      {children}
      <AiOutlinePlus size={24} />
    </Button>
  );
}

export default AddButton;
