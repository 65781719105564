import { createAsyncThunk } from '@reduxjs/toolkit';
import fetcher from '../fetcher';

export const getAllNews = createAsyncThunk('getAll/news', async () => {
  const fetch = fetcher(true);
  const {
    data: { data: News },
  } = await fetch.get('/news');
  return News;
});

export const getAllNewsByCycle = createAsyncThunk('getByCycle/news', async () => {
  const fetch = fetcher(true);
  const {
    data: { data: News },
  } = await fetch.get(`/news/cycle`);
  return News;
});

export const createNews = createAsyncThunk('create/news', async data => {
  const fetch = fetcher(true);
  const { title, message, cycleIds } = data;
  const {
    data: { data: news },
  } = await fetch.post('/news', {
    title,
    message,
    cycleIds,
  });

  return news;
});
