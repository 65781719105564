import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { AddButton, Faq, Loading, PageLayout, RoadMapList } from '../../../components';
import { Section } from './style';
import { SectionTitle } from '../../../components/Texts/HeadingText';
// import useDoubtsAndScripts from '../../../hooks/useDoubtsAndScripts';
import { getAllDoubtsOfFAQ } from '../../../services/faq';
import { getAllRoadMap } from '../../../services/roadmap';

function Help({ location }) {
  // const { doubts, scripts } = useDoubtsAndScripts();
  const path = location?.pathname;
  const [doubts, setDoubts] = useState(null);
  const [scripts, setScripts] = useState(null);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      setDoubts(await getAllDoubtsOfFAQ());
      setScripts(await getAllRoadMap());
    })();
  }, [history]);

  return (
    <PageLayout previous="/customers">
      {!doubts || !scripts ? (
        <Loading />
      ) : (
        <>
          <Section>
            <SectionTitle>Roteiros</SectionTitle>
            <RoadMapList scripts={scripts} type="roadmap" location={path} />
            <AddButton to="/dashboard/add-roadmap">Adicionar Roteiro</AddButton>
          </Section>

          <Section>
            <SectionTitle>FAQ</SectionTitle>
            <Faq doubts={doubts} type="doubts" location={path} />
            <AddButton to="/dashboard/add-faq">Adicionar Dúvida</AddButton>
          </Section>
        </>
      )}
    </PageLayout>
  );
}

export default Help;
