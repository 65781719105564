import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0 10px;
`;

export const FormContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
