import React from 'react';

import { Details, DetailsTextInfo, ListHeader } from '..';
import useFilters from '../../hooks/useFilters';

function RoadMap({ scripts = [], type = '', location = '' }) {
  const { searchTerm, handleSearchTermChange, filteredData, orderBy, handleToggleOrderBy } =
    useFilters({ data: scripts, filterProperty: 'name' });
  return (
    <>
      <ListHeader
        placeholder="Buscar roteiro"
        searchTerm={searchTerm}
        setSearchTerm={handleSearchTermChange}
        orderBy={orderBy}
        handleToggleOrderBy={handleToggleOrderBy}
        isEmpty={!scripts.length}
        htmlFor="roadmap"
      />
      <ul>
        {filteredData?.map(roadMap => (
          <li key={roadMap.id}>
            <Details
              key={roadMap.id}
              summary={roadMap.name}
              identifier={roadMap.id}
              roadMap={roadMap.descriptions}
              type={type}
              location={location}
            >
              {roadMap.descriptions.map(text => (
                <>
                  <DetailsTextInfo key={text.id}>{text.description}</DetailsTextInfo>
                </>
              ))}
            </Details>
          </li>
        ))}
      </ul>
    </>
  );
}

export default RoadMap;
