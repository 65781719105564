import { Switch } from 'react-router-dom';
import { PrivateRoute, PublicRoute } from './routes/index';

import {
  AddCustomer,
  AddDoubt,
  AddProspectus,
  AddRepresentative,
  AddRoadMap,
  AllCustomers,
  ChooseCustomers,
  ChoosePerson,
  ClientsOfUser,
  ContactInformation,
  Customer,
  Customers,
  Cycle,
  EditProspectus,
  EditRepresentative,
  Help,
  Home,
  Notifications,
  Profile,
  ReportGeneration,
  SignIn,
  SignUp,
} from './pages';
import AddEmail from './pages/AddEmail';
import { Add } from './pages/Notifications/Add';
import AdminRoute from './routes/AdminRoute';

import AddSicgespLicense from './pages/AddSicgespLicense';

import SicgespLicense from './pages/SicgespLicense';
import AddCycle from './pages/dashboard/AddCycle';

function Routes() {
  return (
    <Switch>
      <PublicRoute component={SignIn} path="/" exact />
      <PublicRoute component={SignUp} path="/register/:token" />

      <PrivateRoute component={Customers} path="/customers" exact />
      <PrivateRoute component={Customer} path="/customer/:id" />
      <PrivateRoute component={ContactInformation} path="/contact/:id" exact />
      <PrivateRoute component={AddRepresentative} path="/contact/:id/add-representative" />
      <PrivateRoute component={EditRepresentative} path="/contact/add-representative/:id" />
      <PrivateRoute component={AddEmail} path="/contact/:id/add-email" />
      <PrivateRoute component={Notifications} path="/notifications" />
      <PrivateRoute component={Profile} path="/profile" />
      <PrivateRoute component={AddProspectus} path="/contact/:id/add-prospectus" />
      <PrivateRoute component={EditProspectus} path="/contact/edit-prospectus/:id" />
      <PrivateRoute component={SicgespLicense} path="/licenses/:customerId" />
      <PrivateRoute component={AddSicgespLicense} exact path="/add-license/:customerId" />

      <AdminRoute component={Home} path="/dashboard" exact />
      <AdminRoute component={Help} path="/dashboard/help" />
      <AdminRoute component={AddRoadMap} path="/dashboard/add-roadmap" />
      <AdminRoute component={AddDoubt} path="/dashboard/add-faq" />
      <AdminRoute component={ClientsOfUser} path="/dashboard/person/:id" />
      <AdminRoute component={ChooseCustomers} path="/dashboard/choose/customers/:action" exact />
      <AdminRoute component={ChoosePerson} path="/dashboard/choose/person" exact />
      <AdminRoute component={AllCustomers} path="/dashboard/customers" />
      <AdminRoute component={AddCustomer} path="/dashboard/add-customer" />
      <AdminRoute component={AddCycle} path="/dashboard/add-cycle" />
      <AdminRoute component={ReportGeneration} path="/dashboard/report" />
      <AdminRoute component={Cycle} path="/dashboard/cycle/:id" />
      <AdminRoute component={Add} path="/add-news" />
    </Switch>
  );
}

export default Routes;
