import fetcher from '../fetcher';

export const getImplementationStatuses = async () => {
  const fetch = fetcher(true);

  const {
    data: { data },
  } = await fetch.get(`/status`);

  return data;
};
