import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import useForms from '../../hooks/useForms';
import { formatPhone } from '../../utils/formatPhone';
import { Br, FirstField, Info } from './style';
import EditIcon from '../../assets/imgs/edit-primary.svg';
import { Details, OutlinedButton } from '..';
import { Image } from '../../pages/ContactInformation/style';
import { deleteProspectorById } from '../../services/prospection';

function BodyDetails({ data, customerId }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const { fields, createFields, handleAllFieldsChange } = useForms();

  useEffect(() => {
    if (data) {
      handleAllFieldsChange(data);
      setDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const fieldsObject = {
    whatsapp: {
      label: 'Whatsapp',
      value: formatPhone(fields.whatsapp),
      // value: formatPhone(fields.whatsapp),
      maxLength: 15,
      disabled,
    },
    phone: {
      label: 'Telefone',
      value: formatPhone(fields.phone),
      maxLength: 15,
      disabled,
    },
    email: {
      label: 'Email',
      value: fields.email,
      type: 'email',
      disabled,
    },
  };

  const handleDeleteProspector = (cId, pId) => {
    dispatch(deleteProspectorById({ customerId: cId, prospectorId: pId }));
  };

  return (
    <Details summary={fields.name ? fields.name : fields?.email}>
      <Info>
        <FirstField>{['email'].map(field => createFields(field, fieldsObject))}</FirstField>
        <div
          className="field-no-margin"
          style={{
            display: 'flex',
            gap: 12,
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {['whatsapp'].map(field => createFields(field, fieldsObject))}
          {['phone'].map(field => createFields(field, fieldsObject, true))}
        </div>
        <br />
        <OutlinedButton
          onClick={() => history.push(`/contact/edit-prospectus/${data.id}`, { data, customerId })}
        >
          <Image alt="Ícone de edição" src={EditIcon} />
          Editar prospecto
        </OutlinedButton>
        <Br />
        <OutlinedButton
          alert
          type="button"
          onClick={() => handleDeleteProspector(customerId, data.id)}
        >
          Deletar prospecto
        </OutlinedButton>
      </Info>
    </Details>
  );
}

export default BodyDetails;
