import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';
import useForms from '../../hooks/useForms';

import { Wrapper, RegisterLink, Content, Controls } from './style';
import Button from '../Button';
import HeadingText from '../Texts/HeadingText';
import { register } from '../../services/auth';
import { Header, Toast } from '..';

function RegisterForm() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { token } = useParams();

  const {
    fields: { name, email, password, confirmPassword, cycle },
    createFields,
    errors,
  } = useForms();

  const isFormValid = () => name && email && password && confirmPassword && !errors.length;

  function toastNotify(type) {
    if (type === 'error') {
      toast.error('Erro ao criar conta', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } else if (type === 'warning') {
      toast.warning('Preencha todos os campos!', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } else {
      toast.success('Conta criada com sucesso!', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }

  const handleSignUp = async () => {
    try {
      const res = await dispatch(register({ name, email, password, token, cycle }));
      if (res.payload.status === 201) {
        toastNotify('success');
        history.push('/', { status: 201, message: 'account created.' });
      } else if (res.payload.status === 400) {
        toastNotify('warning');
      } else if (res.payload.status === 406 || res.status === 500 || res.status === 404) {
        toastNotify('error');
      }
    } catch (err) {
      history.push('/');
      // eslint-disable-next-line no-console
      // console.log(err);
    }
  };

  const fieldsObject = {
    name: {
      label: 'Nome Completo',
      type: 'text',
      placeholder: 'Digite seu nome',
      value: name,
    },
    email: {
      label: 'Digite seu e-mail',
      type: 'email',
      placeholder: 'Digite seu e-mail',
      value: email,
    },
    password: {
      label: 'Senha',
      placeholder: 'Defina sua senha',
      value: password,
      type: 'password',
      autoComplete: 'on',
      confirm: true,
    },
    confirmPassword: {
      label: 'Confirme sua senha',
      placeholder: 'Confirme sua senha',
      value: confirmPassword,
      type: 'password',
      autoComplete: 'on',
    },
    // cycle: {
    //   label: 'Ciclo da equipe',
    //   placeholder: 'Digite o código do seu ciclo',
    //   value: cycle,
    //   type: 'cycle',
    // },
  };

  return (
    <Wrapper>
      {/* Same as */}
      <Toast />
      <Header type="large" />
      <Content>
        <HeadingText>Cadastre-se</HeadingText>
        {['name', 'email', 'password', 'confirmPassword'].map(field =>
          createFields(field, fieldsObject),
        )}
        <Controls>
          <Button disabled={!isFormValid()} onClick={handleSignUp} type="submit">
            Cadastrar
          </Button>
          <RegisterLink to="/">Já possui cadastro? Fazer login</RegisterLink>
        </Controls>
      </Content>
    </Wrapper>
  );
}

export default RegisterForm;
