/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';
import fetcher from '../fetcher';

export const registerStatus = createAsyncThunk('calendar/create', async body => {
  const fetch = fetcher(true);
  const {
    data: {
      data: { id, service_level, management_report, relocation, sent, year, month },
    },
  } = await fetch.post('/calendar', {
    ...body,
  });

  return {
    id,
    service_level,
    management_report,
    relocation,
    sent,
    year,
    month,
    customerId: body?.customerId,
  };
});

export const getStatus = createAsyncThunk('calendar/get', async id => {
  const fetch = fetcher(true);

  try {
    const { data: res } = await fetch.get(`/calendar/customer/${id}`);

    return { data: res?.data, customerId: id };
  } catch (err) {
    return { error: err?.response?.data };
  }
});

export const editStatus = createAsyncThunk('calendar/put', async data => {
  const fetch = fetcher(true);
  const {
    data: {
      data: { id, service_level, management_report, relocation, sent, year, month },
    },
  } = await fetch.put(`/calendar/${data?.id}`, {
    ...data,
  });

  return {
    id,
    service_level,
    management_report,
    relocation,
    sent,
    year,
    month,
    customerId: data?.customerId,
  };
});
