import styled, { keyframes } from 'styled-components';

export const sweep = keyframes`
  from {
    opacity: 0; 
    transform: translateX(-10px)
  }

  to {
    opacity: 1; 
    transform: translateX(0)
  }
`;

export const TextArea = styled.textarea`
  resize: none;
  outline: transparent;
  border: none;
  min-height: 100px;
  border-radius: 4px;
  padding: 10px 15px;
  color: ${({ theme }) => theme.colors.text};
`;

export const Info = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;
  background: ${({ theme }) => theme.colors.primary.light};
  padding: 10px 20px;
  min-height: 75px;

  p {
    text-align: left;
  }

  & > div {
    margin-bottom: 10px;
    :last-child {
      margin-bottom: 0px;
    }
  }
`;

export const FirstField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.875rem;

  & > div {
    margin-top: 0 !important;
  }
`;

export const ObservationsList = styled.div`
  max-height: 265px;
  overflow-y: auto;
`;

export const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-top: 10px;
    padding: 8px 24px;
    font-weight: 500;
  }
`;
