/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState, useCallback } from 'react';
import replaceSpecialChars from '../utils/replaceSpecialChars';
import useDebounce from './useDebounce';

export default function useFilters({ data, filterProperty }) {
  const [orderedData, setOrderedData] = useState(Object.values(data));
  const [orderBy, setOrderBy] = useState('asc');
  const [isLoading, setIsLoading] = useState(false);
  const [forceDisableButton, setForceDisableButton] = useState(false);

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedChange = useDebounce(setDebouncedSearchTerm, 500, setForceDisableButton);

  const handleOrderedData = useCallback(newData => {
    setOrderedData(newData);
  }, []);

  const filteredData = useMemo(() => {
    const formattedSearchTerm = replaceSpecialChars(searchTerm).toLowerCase();
    const filtered = orderedData.filter(itemList =>
      replaceSpecialChars(itemList[filterProperty])?.toLowerCase()?.includes(formattedSearchTerm),
    );
    setIsLoading(false);
    setForceDisableButton(false);
    return filtered;
  }, [debouncedSearchTerm, data, orderedData, orderBy]);

  const handleToggleOrderBy = () => {
    setOrderBy(prevState => (prevState === 'asc' ? 'desc' : 'asc'));
    if (orderBy === 'asc') {
      setOrderedData(
        Object.values(data)?.sort((firstItem, secondItem) =>
          firstItem[filterProperty] >= secondItem[filterProperty] ? -1 : 1,
        ),
      );
    } else {
      setOrderedData(
        Object.values(data)?.sort((firstItem, secondItem) =>
          firstItem[filterProperty] >= secondItem[filterProperty] ? 1 : -1,
        ),
      );
    }
  };

  const handleSearchTermChange = useCallback(event => {
    setIsLoading(true);
    setSearchTerm(event.target.value);
    debouncedChange(event.target.value);
  }, []);

  return {
    searchTerm,
    isLoading,
    forceDisableButton,
    filteredData,
    orderBy,
    handleSearchTermChange,
    handleToggleOrderBy,
    handleOrderedData,
  };
}
