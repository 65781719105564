import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Title = styled.div`
  max-width: 334px;
  text-align: center;

  h1 {
    margin-bottom: 0;
  }
`;

export const Subtitle = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 20px;

    &:nth-of-type(2) {
      margin-bottom: 20px;
    }
  }

  strong {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const ControlButtonsArea = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  button {
    padding: 5px 12px !important;
  }
`;

export const Section = styled.section`
  width: 334px;
`;

export const SectionTitle = styled.h2`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.primary.text};
  margin: 40px 0px 20px;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
`;

export const Button = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  background: ${({ theme }) => theme.colors.primary.main};
  padding: 12px 16px;
  border: none;
  border-radius: 4px;
  outline: none;
  font-size: 16px;
  gap: 8px;
  text-decoration: none;
  transition: background 200ms ease-in;
  margin-top: 40px;
  & + & {
    margin-left: 10px;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.primary.dark};
  }
`;

export const HeaderList = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CopyButton = styled.button`
  color: ${({ theme }) => theme.colors.altText};
  background: ${({ theme, disabled }) =>
    disabled ? theme.colors.disabled : theme.colors.primary.main} !important;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 4px;
  align-self: flex-end;
  font-size: 0.75rem;
  text-transform: uppercase;
  background: transparent;
  outline: none;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  ${({ disabled }) =>
    disabled &&
    css`  
    span svg {
      transform: none};
    }
  `}
`;

export const ListMail = styled.ul`
  width: 100%;
  margin-top: 0.625rem;
  max-height: ${({ type }) => (type === 'large' ? 'fit-content' : '200px')};
  overflow: auto;
  li {
    flex: 1;
  }
  ::-webkit-scrollbar {
    width: 0px;
  }
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  background-color: ${({ theme, type }) =>
    type === 'title' ? theme.colors.white : theme.colors.altBackground};
  font-size: 1rem;
  padding: 0.625rem;
  border-bottom: 2px solid ${({ theme }) => theme.colors.altBackground};
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 400;
  div {
    gap: 10px;
    div {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }
  }
  color: ${({ theme, type }) =>
    type === 'title' ? theme.colors.black : theme.colors.primary.regular};
`;

export const ButtonListAll = styled.button`
  width: 100%;
  padding: 0.312rem 0.625rem;
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary.main};
  cursor: pointer;
`;

export const ButtonsHeader = styled.div`
  display: flex;
`;

export const CycleSpan = styled.div`
  padding: 6px;
  color: #fff;
  background-color: ${({ theme }) => theme.colors.primary.main};
  border-radius: 6px;
  font-weight: 500;
`;
