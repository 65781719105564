import React from 'react';
import { NotificationBody, NotificationTitle } from './style';

export function NotificationContent({ title, text }) {
  return (
    <>
      <NotificationTitle>{title}</NotificationTitle>
      <NotificationBody>{text}</NotificationBody>
    </>
  );
}
