import React from 'react';
import { Item as Container, ItemEnd, ItemStart, MiddleItem, Square } from './style';

export const Item = ({ title, type }) => (
  <Container>
    <Square type={type}>
      <ItemStart type={type} />
      <MiddleItem type={type} />
      <ItemEnd type={type} />
    </Square>
    <p>{title}</p>
  </Container>
);
