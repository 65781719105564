import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { SectionTitle } from '../../../components/Texts/HeadingText';

export const List = styled.ul`
  width: 100%;
  max-height: 314px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0px;
  }

  border-bottom: ${({ theme }) => `16px solid ${theme.colors.primary.main}`};

  p {
    margin-top: 24px;
  }
`;

export const Item = styled.li`
  list-style-type: none;
  background-color: ${({ theme }) => theme.colors.altText};
  font-size: 1rem;
  border-bottom: 2px solid ${({ theme }) => theme.colors.altBackground};
`;

export const Navigation = styled(Link)`
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 16px;
  min-width: 100%;
  text-decoration: none;
`;

export const ContactButton = styled.div`
  width: 100%;
  height: 30px;
  background-color: purple;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const TextArea = styled.textarea`
  padding: 10px;
`;

export const Title = styled.div`
  max-width: 334px;
  text-align: center;

  h1 {
    margin-bottom: 20px;
  }
`;

export const Subtitle = styled.div`
  display: flex;
  max-width: 300px;
  width: 100%;
  justify-content: space-evenly;
  margin-bottom: 25px;

  strong {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 200px;
`;

export const SectionControls = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: -15px;
  gap: 15px;

  a {
    width: 100%;
  }
`;

export const TitleSecondary = styled(SectionTitle)`
  margin-top: 45px;
`;

export const Span = styled.span`
  position: relative;
  padding: 2px 4px;
  background-color: ${({ theme }) => theme.colors.primary.main};
  border-radius: 4px;
  color: #fff;
  font-weight: bold;

  + span {
    margin-left: 4px;
  }
`;
