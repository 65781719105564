import styled from 'styled-components';
import { BodyText } from '..';

export const Card = styled.div`
  background: ${({ theme }) => theme.colors.altText};
  padding: 10px 20px;
  border-radius: 8px;
  width: 100%;

  &:not(:first-of-type) {
    margin-top: 10px;
  }

  p {
    text-align: left;
    font-size: 0.75rem;
  }
`;

export const NotificationTitle = styled(BodyText)`
  font-weight: 600;
`;

export const NotificationBody = styled(BodyText)`
  color: ${({ theme }) => theme.colors.disabled};
`;
