import { createSlice, current } from '@reduxjs/toolkit';
import { logout } from '../../services/auth';
import { getCollaborators } from '../../services/user';
import { setRemoveUserCycle, setUserCycle } from '../../services/cycle';

const initialState = {
  collaborators: [],
  status: null,
  error: '',
};

export const collaboratorSlice = createSlice({
  name: 'collaborator',
  initialState,
  reducers: {},
  extraReducers: {
    [getCollaborators.pending]: state => {
      state.status = 'loading';
    },
    [getCollaborators.fulfilled]: (state, action) => {
      state.collaborators = action.payload;
      state.status = 'sucess';
    },
    [getCollaborators.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [logout.pending]: state => {
      state.status = 'loading';
    },
    [logout.fulfilled]: state => {
      state.collaborators = [];
    },
    [setUserCycle.pending]: state => {
      state.status = 'loading';
    },
    [setUserCycle.fulfilled]: (state, action) => {
      const collaborator = action.payload;
      const collaborators = state.collaborators.map(collab => {
        if (collab.id === collaborator.id) {
          return {
            ...collab,
            userCycle: [...collab?.userCycle, { ...collaborator?.userCycle }],
          };
        }

        return collab;
      });
      state.collaborators = collaborators;
    },
    [setUserCycle.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [setRemoveUserCycle.pending]: state => {
      state.status = 'loading';
    },
    [setRemoveUserCycle.fulfilled]: (state, action) => {
      const collaborator = action.payload;
      const collaborators = current(state).collaborators.map(collab => {
        if (Number(collab.id) === Number(collaborator.userId)) {
          const newCycles = collab?.userCycle.filter(
            cycle => Number(cycle.id) !== Number(collaborator.userCycle),
          );
          return {
            ...collab,
            userCycle: [...newCycles],
          };
        }

        return collab;
      });
      state.collaborators = collaborators;
    },
    [setRemoveUserCycle.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
  },
});

export const selectCollaborators = state => state.collaborator.collaborators;
export const selectCollaboratorStatus = state => state.collaborator.status;
export const selectCollaborator = (state, id) =>
  state.collaborator.collaborators.filter(collaborator => collaborator.id === Number(id))[0];

export const selectCollaboratorByCycle = (state, cycleId) =>
  state.collaborator.collaborators.filter(collaborator =>
    collaborator.userCycle.some(userCycle => Number(userCycle.cycle.id) === Number(cycleId)),
  );
export default collaboratorSlice.reducer;
