import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;

  h2 {
    margin-top: 0;
    margin-bottom: 30px;
  }
`;

export const ButtonsArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: fit-content;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 16px;
  }
`;

export const CancelButton = styled.button`
  color: ${({ theme }) => theme.colors.primary.main};
  border: none;
  background: transparent;
  margin-top: 10px;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.dark};
  }
`;

export const SectionField = styled.div`
  margin-top: 20px;
`;

export const Label = styled.label`
  display: flex;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.text};
  margin: 0 0 4px 2px;
`;
