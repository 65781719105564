import styled, { keyframes } from 'styled-components';

export const sweep = keyframes`
  from {
    opacity: 0; 
    transform: translateX(-10px)
  }

  to {
    opacity: 1; 
    transform: translateX(0)
  }
`;

export const Wrapper = styled.div`
  div {
    input {
      width: 100%;
      font-size: 0.75rem;
      height: fit-content;
      padding: 8px 6px;
      border-radius: 4px;
      margin-top: 4px;
      outline: transparent;
      background: white;
    }
  }
`;

export const ContentView = styled.div`
  width: ${({ location }) => location === '/dashboard/help' && '50px'};
  display: flex;
  justify-content: space-between;
`;

export const IconBtn = styled.button`
  border: none;
  margin-left: 8px;
  background-color: #fff;
  cursor: pointer;
`;

export const Summary = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 16px;
  box-shadow: inset 0 -2px 0 ${({ theme }) => theme.colors.altBackground};
  color: ${({ theme, green }) => (green ? theme.colors.altText : theme.colors.text)};
  background: ${({ theme, green }) => (green ? theme.colors.primary.main : theme.colors.altText)};
  transition: 200ms ease-in;

  cursor: pointer;

  svg {
    path:last-child {
      display: block;
      width: 12px !important;
    }
  }
`;

export const Arrow = styled.div`
  height: 20px;
  width: 20px;
  overflow: hidden;
  transform: rotateX(${({ isOpen }) => (isOpen ? '-180deg' : '0')})
    rotateY(${({ isOpen }) => (isOpen ? '-180deg' : '0')});
  transition: transform 0.4s;

  > svg {
    margin: auto;
    right: 5px;
    bottom: 5px;
    position: relative;
  }
`;

export const Content = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};

  div {
    animation: 0.7s ${sweep} ease-in-out;
  }
`;
