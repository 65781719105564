import styled from 'styled-components';

export const Section = styled.section`
  max-width: 334px;
  width: 100%;

  & > ul {
    max-height: 336px;

    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    scrollbar-width: none;
  }
`;
