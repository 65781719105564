import styled from 'styled-components';

export const DetailsUrlInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  border-radius: 4px;
  padding: 10px;
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 15%;
  }
`;
export const Url = styled.div``;
export const SearchUser = styled.div`
  display: flex;
  background-color: white;
  width: 100%;
  justify-content: space-between;
  border-radius: 4px;

  padding: 10px;
`;
export const InputSearch = styled.input`
  border: none;
  ::placeholder {
    color: #078c03;
  }
  :focus {
    outline: none;
  }
`;
