import React from 'react';
import ReactDOM from 'react-dom';
import { BodyText, HeadingText } from '..';
import { Content, Wrapper } from './style';

function Modal({ visible, title, subtitle, children, large, medium }) {
  return visible
    ? ReactDOM.createPortal(
        <Wrapper>
          <Content large={large} medium={medium}>
            <HeadingText>{title}</HeadingText>
            <BodyText>{subtitle}</BodyText>
            {children}
          </Content>
        </Wrapper>,
        document.getElementById('modal-root'),
      )
    : null;
}

export default Modal;
