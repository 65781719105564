export function transformObjectToArray(obj) {
  const result = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      result.push(obj[key]);
    }
  }

  return result;
}
