import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { IoPaperPlaneOutline } from 'react-icons/io5';

import { useDispatch, useSelector } from 'react-redux';
import { Button, PageLayout } from '../../components';
import { SectionTitle } from '../../components/Texts/HeadingText';
import useForms from '../../hooks/useForms';
import { Form, ButtonsArea, CancelButton, SectionField, Label } from './style';

import { selectCustomer, selectCustomerEmails } from '../../redux/slices/customerSlice';
import { DateInputs } from '../dashboard/AddCustomer/styles';
import { updateCustomerEmail } from '../../services/customers';

function AddEmail() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { fields, createFields, createDropdownFields, errors } = useForms();

  const customerExists = useSelector(state => selectCustomer(state, id));
  const customerEmails = useSelector(state => selectCustomerEmails(state, id));

  const [selectedRole, setSelectedRole] = useState(null);

  const roles = [
    { id: 1, label: 'Executivo', value: 'Executivo', selectName: 'Executivo' },
    { id: 2, label: 'Legislativo', value: 'Legislativo', selectName: 'Legislativo' },
  ];

  if (!customerExists) history.goBack();

  const fieldsObject = {
    email: {
      label: 'E-mail do representante',
      value: fields.email,
      type: 'email',
      placeholder: 'E-mail',
    },
    name: {
      label: 'Nome do usuário que coletou',
      value: fields.name,
      placeholder: 'Nome',
      messageIfHasError: 'Nome do usuário obrigatório',
    },
    role: {
      label: 'Cargo',
      options: roles,
      placeholder: 'Selecione uma fase',
      onSelect: setSelectedRole,
      selectName: 'label',
      hasEdge: true,
      small: true,
      initialValue: roles.find(role => role.id === selectedRole)?.value,
    },
    day: {
      value: fields.day,
      placeholder: 'Dia',
      maxLength: 2,
    },
    month: {
      value: fields.month,
      placeholder: 'Mês',
      maxLength: 2,
    },
    year: {
      value: fields.year,
      placeholder: 'Ano',
      maxLength: 4,
    },
  };

  function handleBack() {
    history.goBack();
  }

  async function handleAddEmail() {
    try {
      const onlyEmails = [];
      if (customerEmails) {
        customerEmails.forEach(email => {
          if (selectedRole === 1 && email?.category === 'executive') {
            onlyEmails.push(email?.email);
          }
          if (selectedRole === 2 && email?.category === 'legislative') {
            onlyEmails.push(email?.email);
          }
        });
      }
      dispatch(
        updateCustomerEmail({
          id,
          emails: [...onlyEmails, fields.email],
          category: selectedRole === 1 ? 'executive' : 'legislative',
          userCollect: fields.name,
          dateUserCollect: `${fields.day}/${fields.month}/${fields.year}`,
        }),
      );
      handleBack();
    } catch (err) {
      // eslint-disable-next-line no-console
      // console.log(err);
    }
  }

  const isFormValid = () => fields.name && fields.email && !errors.length && !!selectedRole;

  return (
    <PageLayout previous={`/contact/${id}`}>
      <Form>
        <SectionTitle>Adicionar representante</SectionTitle>
        {['email'].map(field => createFields(field, fieldsObject))}

        {createDropdownFields('role', fieldsObject)}

        {createFields('name', fieldsObject)}

        <SectionField>
          <Label>Data da coleta</Label>
          <DateInputs>
            {['day', 'month', 'year'].map(field => createFields(field, fieldsObject))}
          </DateInputs>
        </SectionField>

        <ButtonsArea>
          <Button onClick={handleAddEmail} disabled={!isFormValid()}>
            <IoPaperPlaneOutline size={24} />
            Adicionar email
          </Button>
          <CancelButton type="button" onClick={handleBack}>
            Cancelar
          </CancelButton>
        </ButtonsArea>
      </Form>
    </PageLayout>
  );
}

export default AddEmail;
