import fetcher from '../fetcher';

export const getExpectedPhases = async () => {
  const fetch = fetcher(true);

  const {
    data: { data },
  } = await fetch.get(`/expectedPhase`);

  return data;
};
