import ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Overlay, Container, Footer, Form, AccessionDate, DateInputs, Label } from './styles';
import { BodyText, Button, Loading, OutlinedButton } from '..';
import useForms from '../../hooks/useForms';
import { getExpectedPhases } from '../../services/expectedPhase';
import { getImplementationGroups } from '../../services/implementationGroup';
import { formatPhone } from '../../utils/formatPhone';
import formatDate from '../../utils/formatDate';
import { updateCustomerData } from '../../services/customers';
import { formatInputDate } from '../../utils/formatInputDate';
import { formatPhoneToNumber } from '../../utils/formatPhoneToNumber';

function CustomerDataModal({ isModalOpen, setModalClose, customerData }) {
  const dispatch = useDispatch();

  const [implementationGroups, setImplementationGroups] = useState({});
  const [expectedPhases, setExpectedPhases] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [selectedImplementationGroup, setSelectedImplementationGroup] = useState(null);
  const [selectedExpectedPhase, setSelectedExpectedPhases] = useState(null);

  const { fields, createDropdownFields, createFields, errors, handleAllFieldsChange } = useForms();

  useEffect(() => {
    const {
      accessionDate,
      population,
      customerPhone,
      implementationGroup,
      expectedPhase,
      decreeDate,
    } = customerData;
    let formattedDate = {};
    let formattedDecreeDate = {};
    if (accessionDate) {
      const [day, month, year] = formatDate(accessionDate, false).split('/');
      formattedDate = { day, month, year };
    }
    if (decreeDate) {
      const [day, month, year] = formatDate(decreeDate, false).split('/');
      formattedDecreeDate = { day, month, year };
    }

    handleAllFieldsChange({
      ...formattedDate,
      decreeDay: formattedDecreeDate.day,
      decreeMonth: formattedDecreeDate.month,
      decreeYear: formattedDecreeDate.year,
      population,
      phone: customerPhone,
    });
    setSelectedImplementationGroup(implementationGroup?.id);
    setSelectedExpectedPhases(expectedPhase?.id);

    (async () => {
      const groups = await getImplementationGroups();
      setImplementationGroups(groups);

      const phases = await getExpectedPhases();
      setExpectedPhases(phases);
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fieldsObject = {
    implementationGroup: {
      label: 'Grupo de implementação',
      options: Object.values(implementationGroups),
      placeholder: 'Selecione uma população',
      onSelect: setSelectedImplementationGroup,
      selectName: 'populationGroup',
      hasEdge: true,
      small: true,
      initialValue: Object.values(implementationGroups).find(
        group => group?.id === selectedImplementationGroup,
      )?.populationGroup,
    },
    population: {
      label: 'Número de habitantes',
      value: fields.population,
      placeholder: 'População',
    },
    expectedPhase: {
      label: 'Fase prevista',
      options: Object.values(expectedPhases),
      placeholder: 'Selecione uma fase',
      onSelect: setSelectedExpectedPhases,
      selectName: 'phase',
      hasEdge: true,
      small: true,
      initialValue: Object.values(expectedPhases).find(phase => phase?.id === selectedExpectedPhase)
        ?.phase,
    },
    day: {
      value: fields.day,
      placeholder: 'Dia',
      maxLength: 2,
    },
    month: {
      value: fields.month,
      placeholder: 'Mês',
      maxLength: 2,
    },
    year: {
      value: fields.year,
      placeholder: 'Ano',
      maxLength: 4,
    },
    phone: {
      label: 'Telefone da Prefeitura',
      value: formatPhone(fields.phone),
      placeholder: '(XX) YYYY-YYYY',
      maxLength: 15,
      validateField: false,
    },
    decreeDay: {
      value: fields.decreeDay,
      placeholder: 'Dia',
      maxLength: 2,
      validateField: false,
    },
    decreeMonth: {
      value: fields.decreeMonth,
      placeholder: 'Mês',
      maxLength: 2,
      validateField: false,
    },
    decreeYear: {
      value: fields.decreeYear,
      placeholder: 'Ano',
      maxLength: 4,
      validateField: false,
    },
  };

  const isFormValid = !errors.length && fields.population;

  // eslint-disable-next-line no-empty-function
  async function handleConfirm() {
    const { id: customerId } = customerData;
    const { population, phone } = fields;

    dispatch(
      updateCustomerData({
        customerId,
        implementationGroupId: selectedImplementationGroup,
        population,
        expectedPhaseId: selectedExpectedPhase,
        accessionDate: formatInputDate({ day: fields.day, month: fields.month, year: fields.year }),
        customerPhone: formatPhoneToNumber(phone),
        decreeDate: formatInputDate({
          day: fields.decreeDay,
          month: fields.decreeMonth,
          year: fields.decreeYear,
        }),
      }),
    );
    setModalClose();
  }

  function handleCancel() {
    setModalClose();
  }

  return (
    isModalOpen &&
    ReactDOM.createPortal(
      <Overlay>
        <Container>
          <BodyText color="black" />
          <Form>
            {isLoading && <Loading />}
            <>
              {createDropdownFields('implementationGroup', fieldsObject)}
              {createFields('population', fieldsObject)}
              {/* {createDropdownFields('expectedPhase', fieldsObject)} */}

              <AccessionDate>
                <Label>Data de adesão</Label>
                <DateInputs>
                  {['day', 'month', 'year'].map(field => createFields(field, fieldsObject))}
                </DateInputs>
              </AccessionDate>

              {createFields('phone', fieldsObject)}

              <AccessionDate>
                <Label>Data do decreto</Label>
                <DateInputs>
                  {['decreeDay', 'decreeMonth', 'decreeYear'].map(field =>
                    createFields(field, fieldsObject),
                  )}
                </DateInputs>
              </AccessionDate>
            </>

            <Footer>
              <OutlinedButton onClick={handleCancel}>Cancelar</OutlinedButton>
              <Button type="button" onClick={handleConfirm} disabled={!isFormValid}>
                Editar
              </Button>
            </Footer>
          </Form>
        </Container>
      </Overlay>,
      document.getElementById('modal-root'),
    )
  );
}

export default CustomerDataModal;
