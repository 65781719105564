import { useState } from 'react';

export default function useModal() {
  const [fields, setFields] = useState({
    visible: false,
    title: '',
    subtitle: '',
  });

  function handleModalInformation(data) {
    setFields({
      ...fields,
      ...data,
    });
  }

  function changeVisibility() {
    setFields({
      ...fields,
      visible: !fields.visible,
    });
  }

  function clearAllFields() {
    const clearedFields = Object.keys(fields).reduce(
      (accumulator, field) => ({ ...accumulator, [field]: '' }),
      { ...fields },
    );
    setFields(clearedFields);
  }

  return {
    fields,
    handleModalInformation,
    clearAllFields,
    changeVisibility,
  };
}
