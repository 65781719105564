import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;

  h2 {
    margin: 0 0 30px;
  }

  input {
    background: transparent;
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.colors.text};
  }
`;

export const ControlButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const ButtonArea = styled.div`
  button {
    display: block;
  }
`;
