import ReactDOM from 'react-dom';

import { Overlay, Container, Footer } from './styles';

import { BodyText, Button, OutlinedButton } from '..';

function ConfirmDeleteEmailModal({ isOpen, onClose, email, setEmail, onConfirmed }) {
  function handleConfirm() {
    onConfirmed();
    onClose();
  }

  return (
    isOpen &&
    ReactDOM.createPortal(
      <Overlay>
        <Container>
          <BodyText color="text">
            Deseja remover esse email <strong>{email.email}</strong> da lista do município?
          </BodyText>

          <Footer>
            <OutlinedButton
              onClick={() => {
                onClose();
                setEmail({});
              }}
            >
              {' '}
              Cancelar
            </OutlinedButton>

            <Button onClick={handleConfirm} type="button" danger>
              Confirmar
            </Button>
          </Footer>
        </Container>
      </Overlay>,
      document.getElementById('modal-root'),
    )
  );
}

export default ConfirmDeleteEmailModal;
