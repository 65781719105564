import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { LoginForm, Toast } from '../../components';
import { Wrapper, FormContainer } from './style';

function SignIn() {
  const history = useHistory();
  const location = useLocation();
  const { state } = location;

  window.addEventListener('beforeunload', event => {
    event.preventDefault();
    history.replace('/destination', null);
  });

  useEffect(() => {
    if (state && state.status === 201) {
      toast.success('Conta criada com sucesso!', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      location.state = null;
    }
  }, []);

  return (
    <Wrapper>
      <Toast />

      <FormContainer>
        <LoginForm />
      </FormContainer>
    </Wrapper>
  );
}

export default SignIn;
