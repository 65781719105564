import React, { useEffect, useState, useCallback } from 'react';
import useForms from '../../hooks/useForms';
import BodyText from '../Texts/BodyText';
import { Details, Summary, Info, Span, TextArea } from './style';
import { Dropdown } from '..';

function ObservationDetails({ data }) {
  const [personSelected, setPersonSelected] = useState('');
  const { fields, handleAllFieldsChange, handleFieldsChange } = useForms();

  const onSelect = useCallback(target => setPersonSelected(target), []);
  useEffect(() => {
    if (data) {
      handleAllFieldsChange(data);
    }
  }, [data, handleAllFieldsChange]);

  return (
    <Details>
      <Summary>{data ? fields.observation : 'Adicionar observação'}</Summary>
      <Info>
        {data ? (
          <>
            <Span>{fields.date}</Span>
            <BodyText>{fields.observation}</BodyText>
          </>
        ) : (
          <>
            <Dropdown
              onSelect={onSelect}
              options={['testando', 'testando2', 'testando3']}
              label="Selecione um representante"
              value={personSelected}
            />
            <TextArea
              placeholder="Lorem ipsum dolor sit amet, consectet ui i iadipiscing elit."
              name="observation"
              autoCapitalize="sentences"
              value={fields.observation}
              onChange={handleFieldsChange}
            />
          </>
        )}
      </Info>
    </Details>
  );
}

export default ObservationDetails;
