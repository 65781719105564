import React from 'react';
import { useHistory } from 'react-router';
import { BackButton } from '..';
import { Header } from './style';

function HeaderNavigation({ previous = null, action }) {
  const history = useHistory();

  function handleClick() {
    history.goBack();
  }

  function handleNews() {
    history.push('/add-news');
  }

  return (
    <Header>
      {previous && <BackButton onClick={handleClick} />}
      {action && <BackButton onClick={handleNews} icon={false} label="Criar notificação" iconEnd />}
    </Header>
  );
}

export default HeaderNavigation;
