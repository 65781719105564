import React from 'react';

import { Details, DetailsTextInfo, ListHeader } from '..';
import useFilters from '../../hooks/useFilters';

function Faq({ doubts = [], type = '', location = '' }) {
  const { searchTerm, handleSearchTermChange, filteredData, orderBy, handleToggleOrderBy } =
    useFilters({ data: doubts, filterProperty: 'question' });

  return (
    <>
      <ListHeader
        placeholder="Buscar dúvida"
        searchTerm={searchTerm}
        setSearchTerm={handleSearchTermChange}
        orderBy={orderBy}
        handleToggleOrderBy={handleToggleOrderBy}
        isEmpty={!doubts.length}
        htmlFor="faq"
      />
      <ul>
        {filteredData?.map(doubt => (
          <li key={doubt.id}>
            <Details
              key={doubt.id}
              summary={doubt.question}
              identifier={doubt.id}
              type={type}
              location={location}
            >
              <DetailsTextInfo>{doubt.answer}</DetailsTextInfo>
            </Details>
          </li>
        ))}
      </ul>
    </>
  );
}

export default Faq;
