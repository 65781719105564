import styled from 'styled-components';

export const Wrapper = styled.button`
  border-radius: 4px;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.colors.primary.main};
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background: ${({ theme }) => theme.colors.primary.main};
  transition: background 0.2s ease-in;
  gap: 4px;
  cursor: pointer;
  p {
    margin-left: 8px;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.primary.dark};
  }
`;
