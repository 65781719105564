export const actions = [
  {
    route: '/dashboard/choose/customers/delegate',
    label: 'Delegar municípios',
  },
  {
    route: '/dashboard/choose/customers/transfer',
    label: 'Transferir municípios',
  },
  {
    route: '/dashboard/choose/customers/remove',
    label: 'Retirar municípios',
    alert: 'true',
  },
];
