import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { DropdownModal } from '..';
import { updateImplementationStatus } from '../../services/customers';
import { getImplementationStatuses } from '../../services/implementationStatus';

function ImplementationStatusModal({ isModalOpen, setModalClose }) {
  const [selected, setSelected] = useState(null);
  const [implementationStatuses, setImplementationStatuses] = useState([]);

  const dispatch = useDispatch();

  const { id: customerId } = useParams();

  function handleUpdateImplementationStatus() {
    if (selected) {
      dispatch(updateImplementationStatus({ customerId, statusId: selected }));
    }
  }

  const modalHandler = {
    label: 'Editar',
    action: handleUpdateImplementationStatus,
  };

  useEffect(() => {
    (async () => {
      const statuses = await getImplementationStatuses();
      setImplementationStatuses(statuses);
    })();
  }, [isModalOpen]);

  return (
    <>
      {isModalOpen && (
        <DropdownModal
          isOpen={isModalOpen}
          onClose={() => setModalClose(false)}
          modalHandler={modalHandler}
          label="Status de implementação"
          placeholder="Selecione um status"
          options={implementationStatuses}
          selectName="status"
          setSelected={setSelected}
        />
      )}
    </>
  );
}

export default ImplementationStatusModal;
