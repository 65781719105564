import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { BiSearch, BiSolidUser } from 'react-icons/bi';
import { BsFillPersonBadgeFill } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';

import { FaLock, FaPhone, FaTrash } from 'react-icons/fa';
import { IoMdCopy } from 'react-icons/io';

import { useSelector } from 'react-redux';
import { AddButton, HeadingText, PageLayout } from '../../components';
import { SectionTitle } from '../../components/Texts/HeadingText';

import { ButtonListAll, Item, ListMail } from '../Customer/style';
import { DetailsUrlInfo, InputSearch, SearchUser, Url } from './styles';

import ConfirmDeleteLicenseModal from '../../components/ConfirmDeleteLicenseModal ';
import ConfirmDeleteLicensePasswordModal from '../../components/ConfirmDeleteLicensePasswordModal';
import { selectUserPermission } from '../../redux/slices/authSlice';
import {
  deleteLicense,
  deleteLicensePassword,
  getAllSicgespLicenserByCustomerId,
} from '../../services/sicgespLicense';

function SicgespLicense() {
  const [typeHeight, setTypeHeight] = useState(null);
  const [licenses, setLicenses] = useState({});
  const { customerId } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [modalDeletePasswordOpen, setModalDeletePasswordOpen] = useState(false);
  const [modalDeleteLicenseOpen, setModalDeleteLicenseOpen] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState({});
  const userPermission = useSelector(selectUserPermission);

  const filteredUsers = licenses?.passwordSicgespLicenses?.filter(({ cpf }) =>
    cpf?.toLowerCase()?.includes(searchTerm),
  );
  async function handleLoadLicenses() {
    const data = await getAllSicgespLicenserByCustomerId(customerId);
    setLicenses(data[0]);
  }
  function copyTextToClipboard() {
    navigator.clipboard.writeText(licenses?.url);
  }
  function handleChangeSearchTerm(event) {
    setSearchTerm(event.target.value);
  }
  function handleCloseModal() {
    setModalDeletePasswordOpen(false);
    setModalDeleteLicenseOpen(false);
  }
  async function handleDeleteLicensePassword() {
    await deleteLicensePassword(selectedLicense.id);
    setSelectedLicense({});
  }
  async function handleDeleteLicense() {
    await deleteLicense(licenses.id);
    handleLoadLicenses();
  }
  useEffect(() => {
    handleLoadLicenses();
  }, [customerId, selectedLicense]);

  function toggleHeight() {
    setTypeHeight(prev => (prev === 'large' ? null : 'large'));
  }
  return (
    <>
      <ConfirmDeleteLicenseModal
        isOpen={modalDeleteLicenseOpen}
        onClose={handleCloseModal}
        onConfirmed={handleDeleteLicense}
        url={licenses?.url}
      />
      <ConfirmDeleteLicensePasswordModal
        isOpen={modalDeletePasswordOpen}
        onClose={handleCloseModal}
        license={selectedLicense}
        setLicense={setSelectedLicense}
        onConfirmed={handleDeleteLicensePassword}
      />
      <PageLayout previous="/customers">
        <HeadingText color="black">LICENÇA</HeadingText>
        {licenses?.url && (
          <DetailsUrlInfo>
            <Url>{licenses?.url}</Url>
            {userPermission === 'admin' ? (
              <div>
                <IoMdCopy
                  size={18}
                  color="#078C03"
                  onClick={copyTextToClipboard}
                  cursor="pointer"
                />
                <FaTrash
                  color="#F24027"
                  onClick={() => {
                    setModalDeleteLicenseOpen(true);
                  }}
                  cursor="pointer"
                  size={14}
                />
              </div>
            ) : (
              <IoMdCopy size={18} color="#078C03" onClick={copyTextToClipboard} cursor="pointer" />
            )}
          </DetailsUrlInfo>
        )}
        <SectionTitle color="black">CADASTROS</SectionTitle>
        <SearchUser>
          <InputSearch
            type="text"
            placeholder="BUSCAR USUÁRIO"
            value={searchTerm}
            onChange={handleChangeSearchTerm}
          />
          <BiSearch size={24} color="#078C03" />
        </SearchUser>
        <ListMail type={typeHeight}>
          <Item type="title">
            <p>usuários</p>
            {userPermission === 'admin' && <p>excluir</p>}
          </Item>
          {!!licenses &&
            filteredUsers?.map(license => (
              <Item key={license?.id}>
                <div>
                  <div>
                    <BiSolidUser size={16} />
                    <p>{license?.cpf}</p>
                  </div>
                  <div>
                    <FaLock size={16} />
                    <p>{license.password}</p>
                  </div>
                  <div>
                    <BsFillPersonBadgeFill size={16} />
                    <p>{license.name}</p>
                  </div>
                  <div>
                    <FaPhone size={16} rotate={50} />
                    <p>{license.phone}</p>
                  </div>
                  <div>
                    <MdEmail size={16} />
                    <p>{license.email}</p>
                  </div>
                </div>
                {userPermission === 'admin' && (
                  <FaTrash
                    color="#F24027"
                    onClick={() => {
                      setModalDeletePasswordOpen(true);
                      setSelectedLicense(license);
                    }}
                    cursor="pointer"
                  />
                )}
              </Item>
            ))}
        </ListMail>
        <ButtonListAll onClick={toggleHeight}>
          {typeHeight === 'large' ? 'Ver menos' : 'Ver lista completa'}
        </ButtonListAll>
        <AddButton
          to={`/add-license/${customerId}`}
          state={{ type: !licenses?.id ? `create` : `add`, licenseId: licenses?.id }}
        >
          Adicionar cadastro
        </AddButton>
      </PageLayout>
    </>
  );
}
export default SicgespLicense;
