/* eslint-disable no-use-before-define */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { DropdownModal, OutlinedButton, PageLayout } from '../../../components';
import { SectionTitle } from '../../../components/Texts/HeadingText';
import { selectCollaborators } from '../../../redux/slices/collaboratorSlice';
import { getImplementationStatuses } from '../../../services/implementationStatus';
import { ButtonsArea } from './styles';
import {
  getReportAllCustomers,
  getReportByCycle,
  getReportByStatus,
  getReportByUser,
} from '../../../services/customers';
import { headers } from './config';
import { selectAllCycles } from '../../../redux/slices/cycleSlice';
import { transformObjectToArray } from '../../../utils/formatObjectToArray';
import formatDate from '../../../utils/formatDate';
// import { getObservations } from '../../../services/contact';

export default function ReportGeneration() {
  const [selected, setSelected] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [reportType, setReportType] = useState('');
  const [dataToDownload, setDataToDownload] = useState([]);
  const collaborators = useSelector(selectCollaborators);
  const cycles = useSelector(selectAllCycles);

  function formatObservation(object) {
    return object.map(item => {
      let text = '';
      item.observations.forEach(obs => {
        text += `${obs.name} - ${obs.annotation}\n`;
      });

      return { ...item, observations: text };
    });
  }
  function formatPeople(object) {
    return object.map(item => {
      let text = '';
      item.people.forEach(name => {
        text += `- ${name}\n`;
      });

      return { ...item, people: text };
    });
  }

  function formartProspections(object) {
    return object.map(item => {
      let text = '';
      item.prospections.forEach(prospector => {
        text += `Nome: ${prospector.name}\n
        Whatsapp: ${prospector.whatsapp}\n
        Telefone: ${prospector.phone}\n
        Email: ${prospector.email}\n
        \n------------------------\n\n`;
      });

      return { ...item, prospections: text };
    });
  }
  function formartCycle(object) {
    return object.map(item => {
      let text = '';
      item.cycle.forEach(cycle => {
        text += `${cycle.cycle.name}\n`;
      });

      return { ...item, cycle: text };
    });
  }
  function formartEmails(object) {
    return object.map(item => {
      let text = '';
      item.emails.forEach(email => {
        text += `${email}\n`;
      });

      return { ...item, emails: text };
    });
  }
  function formartAccessionDate(object) {
    return object.map(item => ({ ...item, accessionDate: formatDate(item.accessionDate) }));
  }

  const getDataToDownload = useCallback(async () => {
    let customers;

    if (selected || reportType === 'all') {
      setLoading(true);
      if (reportType === 'byUser') {
        customers = await getReportByUser(selected);
      }
      if (reportType === 'byStatus') {
        customers = await getReportByStatus(selected);
      }
      if (reportType === 'byCycle') {
        customers = await getReportByCycle(selected);
      } else {
        customers = await getReportAllCustomers();
      }
      setLoading(false);
      setButtonDisabled(false);
      customers = formatObservation(customers);
      customers = formatPeople(customers);
      customers = formartProspections(customers);
      customers = formartCycle(customers);
      customers = formartEmails(customers);
      customers = formartAccessionDate(customers);
      console.log(JSON.stringify(customers, null, 2));
      setDataToDownload(customers);
    }
  }, [reportType, selected]);

  useEffect(() => {
    handleGetObservationsOfTheAllCustomer();
  }, [getDataToDownload]);

  async function handleGetStatuses() {
    const statuses = await getImplementationStatuses();
    setData(statuses);
  }
  async function handleGetCycles() {
    setData(transformObjectToArray(cycles));
  }
  async function handleGetObservationsOfTheAllCustomer() {
    await getDataToDownload();
  }

  async function handleGenerateReportByStatus() {
    setSelected(null);
    await handleGetStatuses();
    setIsModalOpen(true);
    setReportType('byStatus');
  }
  async function handleGenerateReportByCycle() {
    setSelected(null);
    await handleGetCycles();
    setIsModalOpen(true);
    setReportType('byCycle');
  }
  async function handleGenerateReportAllCustomers() {
    setSelected(null);
    setData([]);
    setIsModalOpen(true);
    setReportType('all');
  }
  const modalHandler = {
    label: 'Gerar relatório',
  };

  function handleGetImplementationUser() {
    setSelected(null);
    setData(collaborators);
    setIsModalOpen(true);
    setReportType('byUser');
  }

  function renderDropdownModal() {
    const props = {
      label: '',
      placeholder: '',
      selectName: '',
    };
    if (reportType === 'byUser') {
      props.label = 'Usuário';
      props.placeholder = 'Selecione o usuário';
      props.selectName = 'name';
    }
    if (reportType === 'byStatus') {
      props.label = 'Status';
      props.placeholder = 'Selecione o status';
      props.selectName = 'status';
    }
    if (reportType === 'byCycle') {
      props.label = 'Ciclos';
      props.placeholder = 'Selecione o ciclo';
      props.selectName = 'name';
    }
    if (reportType === 'all') {
      props.label = 'Todos';
      props.placeholder = 'Gere o relatório';
    }
    const renderGenerateCsvButton = () => (
      <>
        {!buttonDisabled && (
          <CSVLink
            data={dataToDownload}
            filename={`Relatório-Municípios-${reportType}`}
            headers={headers}
            separator=";"
          >
            Gerar relatório
          </CSVLink>
        )}
      </>
    );

    return (
      <DropdownModal
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        isOpen={isModalOpen}
        modalHandler={modalHandler}
        onClose={() => setIsModalOpen(false)}
        options={data}
        loading={loading}
        setSelected={setSelected}
        generateCsvButton={renderGenerateCsvButton}
        buttonDisabled={buttonDisabled}
      />
    );
  }

  return (
    <PageLayout previous="/dashboard">
      <SectionTitle>Relatórios</SectionTitle>

      <ButtonsArea>
        <OutlinedButton onClick={handleGetImplementationUser}>
          Municípios por usuário
        </OutlinedButton>
        <OutlinedButton onClick={handleGenerateReportByStatus}>
          Municípios por status de implementação
        </OutlinedButton>
        <OutlinedButton onClick={handleGenerateReportByCycle}>Municípios por Ciclo</OutlinedButton>
        <OutlinedButton onClick={handleGenerateReportAllCustomers}>
          Todos os Municípios
        </OutlinedButton>
      </ButtonsArea>

      {renderDropdownModal()}
    </PageLayout>
  );
}
