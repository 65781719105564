import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { AiOutlineSend } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import useFilters from '../../../hooks/useFilters';

import { ListHeader, BodyText, PageLayout, Button, Loading, CheckBox } from '../../../components';
import {
  List,
  Item,
  CustomerName,
  Section,
  SecondaryButton,
  ButtonContainer,
  Title,
} from './style';

import { selectCustomers } from '../../../redux/slices/customerSlice';
import {
  delegateOrTransferManyCustomers,
  removeManyCustomersOfAnUserResponsible,
} from '../../../services/customers';
import { Loader, LoaderContainer } from '../../../components/CustomersList/style';

function ChooseCustomers({ location }) {
  const history = useHistory();
  const { action } = useParams();
  const userId = location?.state?.userId;
  const customers = location?.state?.customers;
  const userCycle = location?.state?.userCycle;
  const allCustomers = useSelector(selectCustomers);

  const dispatch = useDispatch();
  const selectedCustomers = useRef({});

  const [loading, setLoading] = useState(true);

  if (!userId || !customers) history.goBack();
  const {
    searchTerm,
    handleSearchTermChange,
    filteredData,
    orderBy,
    handleToggleOrderBy,
    handleOrderedData,
    isLoading,
    forceDisableButton,
  } = useFilters({
    data: customers,
    filterProperty: 'name',
  });

  const handleDelegateOrTransfer = async () => {
    try {
      dispatch(
        delegateOrTransferManyCustomers({
          customerIds: Object.values(selectedCustomers.current),
          userId,
        }),
      );
      history.push(`/dashboard`);
    } catch (err) {
      // console.log(err);
    }
  };

  const handleDelete = async () => {
    try {
      dispatch(
        removeManyCustomersOfAnUserResponsible({
          userId,
          customerIds: Object.values(selectedCustomers.current),
        }),
      );
      history.push(`/dashboard`);
    } catch (err) {
      // console.log(err);
    }
  };

  function handleTransfer() {
    history.push({
      pathname: '/dashboard/choose/person',
      state: {
        action,
        customers: Object.values(selectedCustomers.current),
        userId,
        userCycle,
      },
    });
  }

  const actions = {
    delegate: {
      label: 'Delegar Municípios',
      action: handleDelegateOrTransfer,
      isCustomersListEmpty: 'Nenhum município desse ciclo encontra-se sem responsável.',
    },
    transfer: {
      label: 'Transferir Municípios',
      action: handleTransfer,
      isCustomersListEmpty: `Nenhum município esta sob a responsabilidade desse usuário.`,
    },
    remove: {
      label: 'Retirar Municípios',
      action: handleDelete,
      isCustomersListEmpty: 'Nenhum município esta sob a responsabilidade desse usuário.',
    },
  };

  useEffect(() => {
    if (action === 'delegate') {
      const customersFilted = Object.values(allCustomers).filter(
        customer =>
          !customer?.userResponsible &&
          !!customer?.customerCycle?.length &&
          customer?.customerCycle[0]?.cycle?.id === Number(userCycle),
      );
      handleOrderedData(customersFilted);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleCheckBoxFather() {
    const checkboxFather = document.querySelector('input[type="checkbox"]');
    const size = Object.values(selectedCustomers.current).length;

    if (checkboxFather) {
      checkboxFather.checked = size === Object.values(filteredData).length && size;
    }
  }

  function handleAllCheckbox(e) {
    const { checked } = e.target;

    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach(checkBox => {
      const { value } = checkBox;
      checkBox.checked = checked;
      if (checked && value)
        selectedCustomers.current = { ...selectedCustomers.current, [value]: Number(value) };
      else if (!checked) delete selectedCustomers.current[value];
    });
  }

  function handleUnit(event) {
    const { value, checked } = event.target;

    // eslint-disable-next-line no-unused-expressions
    checked
      ? (selectedCustomers.current = {
          ...selectedCustomers.current,
          [Number(value)]: Number(value),
        })
      : delete selectedCustomers.current[value];

    handleCheckBoxFather();
  }

  useEffect(() => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');

    checkboxes.forEach(checkbox => {
      const { value } = checkbox;
      if (selectedCustomers.current[value]) {
        checkbox.checked = true;
      }
    });

    handleCheckBoxFather();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData]);

  return (
    <PageLayout previous={`/dashboard/person/${userId}`}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Title>Municípios</Title>
          <Section>
            <CheckBox large id="checkbox-father" handleToggleCheckBox={handleAllCheckbox} />
            <ListHeader
              placeholder="Buscar"
              searchTerm={searchTerm}
              setSearchTerm={handleSearchTermChange}
              orderBy={orderBy}
              handleToggleOrderBy={handleToggleOrderBy}
              // isEmpty={!Object.values(customers).length}
              forceDisableButton={forceDisableButton}
            />
          </Section>
          {isLoading ? (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          ) : (
            <List empty={!Object.values(filteredData).length}>
              {Object.values(filteredData).length ? (
                <>
                  {Object.values(filteredData)?.map(prefecture => (
                    <Item key={prefecture.id}>
                      <CheckBox value={prefecture.id} handleToggleCheckBox={handleUnit} />
                      <CustomerName>{prefecture.name}</CustomerName>
                    </Item>
                  ))}
                </>
              ) : (
                <BodyText>{actions[action].isCustomersListEmpty}</BodyText>
              )}
            </List>
          )}

          <ButtonContainer>
            <Button onClick={actions[action].action}>
              <AiOutlineSend size={20} />
              {actions[action].label}
            </Button>
            <SecondaryButton to={`/dashboard/person/${userId}`}>Cancelar</SecondaryButton>
          </ButtonContainer>
        </>
      )}
    </PageLayout>
  );
}

export default ChooseCustomers;
