import styled, { keyframes } from 'styled-components';

const Animation = keyframes`
  0% {
    margin-top: calc(-100% - 200px);
  }
  100% {
    margin-top: 0px;
  }
`;

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);

  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 334px;
  border-radius: 4px;
  padding: 40px 20px;
  animation: 0.3s ${Animation} ease-out;

  label {
    color: ${({ theme }) => theme.colors.altText};
  }
`;

export const Footer = styled.footer`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  button,
  a {
    font-size: 0.875rem;
    margin-top: 0;
    padding: 5px 10px;
    width: fit-content;
  }

  a {
    text-decoration: none;
    color: white;
    background-color: ${({ theme }) => theme.colors.primary.main};
    border-radius: 4px;
    transition: 0.2s ease-in;

    :hover {
      filter: brightness(0.8);
    }
  }
`;
