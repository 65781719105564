import React from 'react';
import RegisterForm from '../../components/RegisterForm';
import { Wrapper, FormContainer } from './style';

function SignUp() {
  return (
    <Wrapper>
      <FormContainer>
        <RegisterForm />
      </FormContainer>
    </Wrapper>
  );
}

export default SignUp;
