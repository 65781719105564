import React from 'react';
import { FinalItem, FirstItem, GridItem, SecondItem, Section } from './style';
import { getAbbreviation } from '../../utils/calendar';

export const Month = ({ item, index, handleClick, name = null }) => (
  <GridItem key={item?.month} onClick={() => handleClick(index)} sent={item?.sent}>
    <p style={{ marginLeft: -4 }}>{name ?? getAbbreviation(item.month)}</p>
    <Section type={item?.status}>
      <FirstItem managementReport={item?.management_report || item?.sent} />
      <SecondItem relocation={item?.relocation || item?.sent} />
      <FinalItem serviceLevel={item?.service_level || item?.sent} />
    </Section>
  </GridItem>
);
