import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;
  background: white;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.border};
  padding: 10px 20px;
  min-height: 50px;

  p {
    display: inline-block;
    text-align: left;
    font-size: 0.875rem;
    word-wrap: break-word;
    white-space: pre-line;
    max-height: 70px;
    overflow: hidden;
  }
`;

export const Content = styled.div`
  display: flex;
  gap: 4px;
`;

export const Span = styled.span`
  font-size: 12px;
  line-height: 16px;

  color: ${({ theme }) => theme.colors.secondary.light};
`;

export const ViewMore = styled.button`
  background: none;
  margin-top: -18px;
  border: none;
  color: ${({ theme }) => theme.colors.primary.main};
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  align-self: flex-end;
`;
