import styled, { css } from 'styled-components';

const buttonColorVariants = {
  primary: css`
    border: 2px solid ${({ theme }) => theme.colors.primary.main};
    color: ${({ theme }) => theme.colors.primary.main};
  `,
  alert: css`
    border: 2px solid ${({ theme }) => theme.colors.alert.main};
    color: ${({ theme }) => theme.colors.alert.main};
  `,
  disabled: css`
    border: 2px solid ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.colors.disabled};
  `,
};

export default styled.button`
  width: 100%;
  padding: 5px 0;
  border-radius: 4px;
  background-color: transparent;
  font-size: 0.875rem;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: center;

  ${({ alert }) => (alert ? buttonColorVariants.alert : buttonColorVariants.primary)}

  &[disabled] {
    cursor: default;
    ${buttonColorVariants.disabled}
  }
`;
