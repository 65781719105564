import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { IoPaperPlaneOutline, IoTrashOutline } from 'react-icons/io5';

// import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
// import { useDispatch } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Button, PageLayout } from '../../components';
import { SectionTitle } from '../../components/Texts/HeadingText';
import useForms from '../../hooks/useForms';
import { Form, ButtonsArea, CancelButton } from './style';

import { formatPhone } from '../../utils/formatPhone';
import { formatCpf } from '../../utils/formatCpf';
import { editPerson } from '../../services/person';
// import { createPerson } from '../../services/person';
// import { formatPhoneToNumber } from '../../utils/formatPhoneToNumber';
// import { formatCpfToNumber } from '../../utils/formatCpfToNumber';

// import { selectCustomer } from '../../redux/slices/customerSlice';

function EditRepresentative() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { fields, createFields, errors, handleAllFieldsChange } = useForms();
  const location = useLocation();
  const person = location.state;
  useEffect(() => {
    handleAllFieldsChange(person);
  }, []);
  // const customerExists = useSelector(state => selectCustomer(state, id));

  // if (!customerExists) history.goBack();

  const fieldsObject = {
    name: {
      label: 'Nome do representante',
      value: fields.name,
      placeholder: 'Nome',
    },
    whatsapp: {
      label: 'Whatsapp',
      value: formatPhone(fields.whatsapp),
      maxLength: 15,
      placeholder: '(XX) YYYYY-YYYY',
    },
    phone: {
      label: 'Telefone',
      value: formatPhone(fields.phone),
      maxLength: 15,
      placeholder: '(XX) YYYY-YYYY',
    },
    email: {
      label: 'E-mail do representante',
      value: fields.email,
      type: 'email',
      placeholder: 'E-mail',
    },
    role: {
      label: 'Cargo do representante',
      value: fields.role,
      placeholder: 'Cargo',
    },
    cpf: {
      label: 'CPF do representante',
      value: formatCpf(fields.cpf),
      maxLength: 14,
      placeholder: 'CPF',
    },
  };

  function handleBack() {
    history.push(`/contact/${id}`);
  }

  async function handleCreatePerson() {
    // name, email, phone, whatsapp, customerId
    try {
      console.log(fields);
      dispatch(editPerson(fields));
      handleBack();
    } catch (err) {
      // eslint-disable-next-line no-console
      // console.log(err);
    }
  }

  const handleDeleteRepresentative = async representativeId => {
    try {
      console.log(representativeId);
      // const newRepresentatives = representatives.filter(prop => prop.id !== representativeId);
      // setRepresentatives(newRepresentatives);
      history.push('/customers');
    } catch (err) {
      console.log(err);
    }
  };

  const isFormValid = () => fields.name && fields.email && !errors.length;

  return (
    <PageLayout previous={`/contact/${id}`}>
      <Form>
        <SectionTitle>Adicionar representante</SectionTitle>
        {['name', 'whatsapp', 'phone', 'email', 'role', 'cpf'].map(field =>
          createFields(field, fieldsObject),
        )}

        <ButtonsArea>
          <Button onClick={handleCreatePerson} disabled={!isFormValid()}>
            <IoPaperPlaneOutline size={24} />
            Salvar Alterações
          </Button>
          <Button onClick={handleDeleteRepresentative} danger>
            <IoTrashOutline size={24} />
            Excluir Contato
          </Button>
          <CancelButton type="button" onClick={handleBack}>
            Descartar mudanças
          </CancelButton>
        </ButtonsArea>
      </Form>
    </PageLayout>
  );
}

export default EditRepresentative;
