import styled from 'styled-components';

export const Info = styled.form`
  width: 100%;
  background: ${({ theme }) => theme.colors.primary.light};
  padding: 20px;
  min-height: 206px;

  label {
    font-size: 0.75rem;
  }

  & > div {
    margin-top: 0 !important;
    margin-bottom: 10px;
    :last-child {
      margin-bottom: 0px;
    }
  }
`;

export const FirstField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.875rem;

  div {
    input[name='whatsapp'] {
      color: white;
      background: ${({ theme }) => theme.colors.primary.main};
    }
  }
  & > div {
    margin-top: 0 !important;
  }
`;

export const Br = styled.div`
  width: 100%:
  height: 2px;
`;

// export const deleteBtn = styled.button`
//   width: 100%;
//   padding: 5px 0;
//   border-radius: 4px;
//   background-color: transparent;
//   font-size: 0.875rem;
//   text-align: center;
//   text-decoration: none;
//   display: flex;
//   align-items: center;
//   gap: 6px;
//   justify-content: center;

//   ${({ alert }) => (alert ? buttonColorVariants.alert : buttonColorVariants.primary)}

//   &[disabled] {
//     cursor: default;
//     ${buttonColorVariants.disabled}
//   }
// `;
