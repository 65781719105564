import fetcher from '../fetcher';

export const getImplementationGroups = async () => {
  const fetch = fetcher(true);

  const {
    data: { data },
  } = await fetch.get(`/implementationGroup`);

  return data;
};
