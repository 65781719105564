import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 10px;
`;

export const Container = styled.div`
  width: 100%;
  min-width: 370px;
  max-width: 414px;
  min-height: calc(100vh - 80px);
`;

export const Content = styled.div`
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 40px 40px;
  background: ${({ theme }) => theme.colors.primary.background};
  border-radius: 20px 20px 0 0;
  margin-top: -20px;
`;
