import React from 'react';
import { FiClipboard } from 'react-icons/fi';

import { BodyText } from '..';
import { CopyButton, Info } from './style';

function DetailsTextInfo({ children }) {
  const copyToClipboard = () => {
    const textarea = document.createElement('textarea');
    textarea.value = children;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };
  return (
    <Info>
      <CopyButton onClick={copyToClipboard}>
        <FiClipboard size={14} />
        Copiar texto
      </CopyButton>
      <BodyText color="text">{children}</BodyText>
    </Info>
  );
}

export default DetailsTextInfo;
