import styled from 'styled-components';

const Text = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily};
`;

const HeadingText = styled(Text)`
  font-size: 2rem;
  font-weight: bold;
  color: ${({ theme, color }) => theme.colors.primary[color] || theme.colors.primary.main};
  text-transform: uppercase;
  margin-bottom: 40px;
`;

export const SectionTitle = styled.h2`
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.text};
  margin: 40px 0px 20px;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
`;

export default HeadingText;
