import { useEffect, useState } from 'react';
import { getAllDoubtsOfFAQ } from '../services/faq';
import { getAllRoadMap } from '../services/roadmap';

export default function useDoubtsAndScripts() {
  const [doubts, setDoubts] = useState(null);
  const [scripts, setScripts] = useState(null);

  useEffect(() => {
    (async () => {
      setDoubts(await getAllDoubtsOfFAQ());
      setScripts(await getAllRoadMap());
    })();
  }, []);

  return { doubts, scripts };
}
