import React from 'react';
import { Header, HeaderNavigation } from '..';

import { Wrapper, Container, Content } from './style';

function PageLayout({ headerType, previous, children, action = false }) {
  return (
    <Wrapper>
      <Container>
        <Header type={headerType || 'default'} />
        <Content>
          <HeaderNavigation previous={previous} action={action} />
          {children}
        </Content>
      </Container>
    </Wrapper>
  );
}

export default PageLayout;
