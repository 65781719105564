import fetcher from '../fetcher';

export const getAllRoadMap = async () => {
  const fetch = fetcher(true);

  const {
    data: { data },
  } = await fetch.get(`/roadmap`);

  return data;
};

export const createRoadmap = async ({ name, description, hidden = false, endDate }) => {
  const fetch = fetcher(true);

  const tomorrow = new Date(Date.now());
  tomorrow.setHours(tomorrow.getHours() + 24);

  const {
    data: { data },
  } = await fetch.post('/roadmap', { name, description, hidden, endDate: endDate || tomorrow });

  return data;
};

export const deleteRoadMap = async id => {
  const fetch = fetcher(true);

  const {
    data: { data },
  } = await fetch.delete(`/roadmap/${id}`);

  return data;
};
