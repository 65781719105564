import React, { useState, useRef, useEffect } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import {
  Container,
  LabelContent,
  Relative,
  OptionsContainer,
  Icon,
  Text,
  Arrow,
  Option,
} from './style';

function Dropdown({
  label,
  icon,
  options = [],
  onSelect,
  selectName = 'name',
  hasEdge,
  small,
  field,
  initialValue,
}) {
  const [selected, setSelected] = useState(initialValue);
  const [isOpen, setIsOpen] = useState(false);

  const cont = useRef(null);

  useEffect(() => {
    cont.current.focus();
  }, []);

  return (
    <Container
      hasEdge={hasEdge}
      tabIndex="0"
      ref={cont}
      onBlur={() => setIsOpen(false)}
      onClick={() => setIsOpen(p => !p)}
      size="sm"
      small={small}
    >
      <LabelContent>
        <Icon>{icon}</Icon>
        <Text>{selected || label}</Text>
        <Arrow open={isOpen}>
          <RiArrowDropDownLine size="30" />
        </Arrow>
      </LabelContent>
      <Relative>
        <OptionsContainer open={isOpen}>
          <Option
            small={small}
            onClick={() => {
              setSelected(label);
              onSelect(null);
            }}
          >
            {label}
          </Option>
          {options?.map(option => (
            <Option
              small={small}
              key={option.id}
              onClick={() => {
                setSelected(option[selectName]);
                if (field === 'representative') {
                  onSelect(option);
                } else {
                  onSelect(option.id);
                }
              }}
            >
              {option[selectName]}
            </Option>
          ))}
        </OptionsContainer>
      </Relative>
    </Container>
  );
}

export default Dropdown;
