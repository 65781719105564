/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Slider from 'react-slick';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Check } from '@phosphor-icons/react';
import { GridContainer, Label, Caption, Container, ButtonContainer, Checkbox } from './style';
import { Item } from './Item';
import { Loading, Modal } from '..';
import { Month } from './Month';
import { registerStatus } from '../../services/calendar';
import { selectCalendar } from '../../redux/slices/customerSlice';
import { updateCustomerData } from '../../services/customers';
import { getAbbreviation } from '../../utils/calendar';

export const Calendar = ({ customerId, customer = {} }) => {
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [body, setBody] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentMonth, setCurrentMonth] = useState('');
  const handleClick = obj => {
    setCurrentMonth(getAbbreviation(obj.month));
    setModalVisible(true);
    setBody(obj);
  };

  const register = type => {
    const newBody = {
      ...body,
      ...type,
    };

    setCurrentMonth(getAbbreviation(newBody?.month));
    dispatch(registerStatus({ ...newBody }));
    setModalVisible(false);
  };

  let calendar = [];
  calendar = useSelector(state => selectCalendar(state, customerId));

  useEffect(() => {
    if (calendar !== 'undefined') {
      setLoading(false);
    }
  }, [calendar]);

  function handleChangeStatus(status) {
    if (customer?.status === 'accession' || customer?.status === 'decree') {
      dispatch(updateCustomerData({ customerId: customer?.id, ...customer, status: 'both' }));
    } else {
      dispatch(updateCustomerData({ customerId: customer?.id, ...customer, status }));
    }
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const calendarComp = () => (
    <>
      <ButtonContainer>
        <Checkbox
          onClick={() => handleChangeStatus('accession')}
          sent={
            (!!customer?.status && customer?.status === 'both') || customer?.status === 'accession'
          }
        >
          {!!customer?.status &&
            (customer?.status === 'both' || customer?.status === 'accession') && (
              <Check size={20} weight="bold" />
            )}
          Adesão
        </Checkbox>
        <Checkbox
          onClick={() => handleChangeStatus('decree')}
          sent={customer?.status === 'both' || customer?.status === 'decree'}
        >
          {!!customer?.status && (customer?.status === 'both' || customer?.status === 'decree') && (
            <Check size={20} weight="bold" />
          )}
          Decreto
        </Checkbox>
      </ButtonContainer>
      <Slider {...settings}>
        {Object?.entries(calendar || [])?.map(([year, month]) => (
          <Container>
            <Label>{year}</Label>
            <GridContainer>
              {month?.map((item, index) => (
                <Month
                  item={item}
                  handleClick={() => handleClick({ customerId, ...item })}
                  index={index}
                  type="management_report"
                />
              ))}
            </GridContainer>
          </Container>
        ))}
      </Slider>
      <br />
      <Caption>
        <Item title="Relatório gerencial" type="management_report" />
        <Item title="Realoção" type="realocation" />
        <Item title="Nível de serviço" type="service_level" />
        <Item title="Completo" type="sent" />
      </Caption>
    </>
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <>
      {loading ? <Loading /> : calendarComp()}

      <Modal visible={modalVisible} medium>
        <p>Selecione um status:</p>
        <br />
        <div
          style={{
            display: 'flex',
            gap: 10,
            width: 300,
            height: 40,
            margin: '0 auto',
            justifyContent: 'center',
          }}
        >
          <Month
            item={{ status: 'other' }}
            handleClick={() =>
              register({
                management_report: false,
                relocation: false,
                service_level: false,
                sent: false,
              })
            }
            index={1}
            name={currentMonth}
          />
          <br />
          <Month
            item={{ management_report: true }}
            handleClick={() =>
              register({
                management_report: true,
                sent: false,
                relocation: false,
                service_level: false,
              })
            }
            index={2}
            type="management_report"
            name={currentMonth}
          />
          <br />
          <Month
            item={{ relocation: true }}
            handleClick={() =>
              register({
                management_report: true,
                relocation: true,
                sent: false,
                service_level: false,
              })
            }
            index={2}
            type="realocation"
            name={currentMonth}
          />
          <br />
          <Month
            item={{ service_level: true }}
            handleClick={() =>
              register({
                management_report: false,
                relocation: false,
                service_level: true,
                sent: false,
              })
            }
            index={3}
            type="service_level"
            name={currentMonth}
          />
          <br />
          <Month
            item={{ sent: true }}
            handleClick={() => register({ sent: true })}
            index={4}
            name={currentMonth}
          />
        </div>
      </Modal>
    </>
  );
};
