import React from 'react';
import { MdArrowBack } from 'react-icons/md';
import { AiOutlinePlus } from 'react-icons/ai';

import { Wrapper } from './style';
import { BodyText } from '..';

function BackButton({ icon = true, label = 'Voltar', onClick, iconEnd = false }) {
  return (
    <Wrapper onClick={onClick}>
      {icon && <MdArrowBack size={15} color="#fff" />}
      <BodyText color="white">{label}</BodyText>
      {iconEnd && <AiOutlinePlus size={15} color="#fff" />}
    </Wrapper>
  );
}

export default BackButton;
