import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 10px 0px 24px 0px;
  border-radius: 10px;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 10px;
  width: 270px; /* Ajuste o tamanho conforme necessário */
  margin: 0 auto;
  position: relative;
`;

export const Label = styled.p`
  font-family: ${({ theme }) => theme.fontFamilySecondary};
  font-size: 18px;
  color: #000;
  text-align: center;
  padding: 10px 0px 12px 0px;
`;

export const GridItem = styled.div`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme, sent }) =>
    sent ? theme.colors.primary.main : theme.colors.primary.background};
  border: none;
  border-radius: 8px;
  position: relative;
  text-align: center;
  overflow: hidden;
  p {
    padding: 9px 10px;
    font-size: 16px;
    color: ${({ sent }) => (sent ? 'white' : '#000')};
    font-family: ${({ theme }) => theme.fontFamilySecondary};
  }
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  cursor: pointer;
  transition: 0.5s;

  :hover {
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  }
`;

export const Popup = styled.div`
  position: absolute;
  left: ${({ left }) => `${left}px`};
  top: ${({ top }) => `${top}px`};
  width: 100px; /* Ajuste a largura conforme necessário */
  height: 100%;
  background-color: blue;
  display: ${({ clicked }) => (clicked ? 'block' : 'none')};
  z-index: 999;
`;

export const SectionStart = styled.div`
  width: 8px;
  height: 100%;
  background-color: gray;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: ${({ theme, type }) =>
    // eslint-disable-next-line no-nested-ternary
    type === 'sent' || type === 'management_report'
      ? theme.colors.primary.main
      : type === 'service_level'
      ? '#D9D9D9'
      : theme.colors.primary.background};
`;

export const SectionEnd = styled.div`
  width: 8px;
  height: 100%;
  background-color: gray;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${({ theme, type }) =>
    // eslint-disable-next-line no-nested-ternary
    type === 'sent' || type === 'service_level'
      ? theme.colors.primary.main
      : type === 'management_report'
      ? '#D9D9D9'
      : theme.colors.primary.background};
`;

export const Section = styled.div`
  width: 100%;
  height: 4px;
  display: flex;
  align-self: flex-end;
  background: #d9d9d9;
  border-radius: 8px;
`;

export const FirstItem = styled.div`
  width: 33.3%;
  height: 100%;
  background-color: ${({ theme, managementReport }) =>
    managementReport ? theme.colors.primary.main : '#d9d9d9'};
`;
export const SecondItem = styled.div`
  width: 33.3%;
  height: 100%;
  background-color: ${({ theme, relocation }) =>
    relocation ? theme.colors.primary.main : '#d9d9d9'};
`;
export const FinalItem = styled.div`
  width: 33.3%;
  height: 100%;
  background-color: ${({ theme, serviceLevel }) =>
    serviceLevel ? theme.colors.primary.main : '#d9d9d9'};
`;

export const Caption = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  width: 100%; /* Ajuste o tamanho conforme necessário */
  margin: 0 auto;
  padding: 8px;
  background-color: white;
  border-radius: 4px;
`;

export const Square = styled.div`
  width: 24px;
  height: 24px;
  background-color: #000;
  border-radius: 4px;
  overflow: hidden;
  background-color: ${({ theme, type }) =>
    type === 'sent' ? theme.colors.primary.main : theme.colors.primary.background};

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  p {
    width: 140px;
    font-size: 14px;
  }
`;

export const ItemStart = styled.div`
  width: 100%;
  height: 4px;
  align-self: flex-end;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: ${({ theme, type }) =>
    type === 'sent' || type === 'management_report' || type === 'realocation'
      ? theme.colors.primary.main
      : '#d9d9d9'};
`;

export const MiddleItem = styled.div`
  width: 100%;
  height: 4px;
  align-self: flex-end;
  background-color: ${({ theme, type }) =>
    type === 'sent' || type === 'realocation' ? theme.colors.primary.main : '#d9d9d9'};
`;

export const ItemEnd = styled.div`
  width: 100%;
  height: 4px;
  align-self: flex-end;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${({ theme, type }) =>
    type === 'sent' || type === 'service_level' ? theme.colors.primary.main : '#d9d9d9'};
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 16px;
`;

export const Checkbox = styled.button`
  width: 100%;
  padding: 11px 20px;
  border: ${({ theme, sent }) => (sent ? `2px solid ${theme.colors.primary.main}` : 'none')};
  border-radius: 8px;
  color: ${({ theme, sent }) => (sent ? theme.colors.primary.main : '#000')};
  font-size: 16px;
  font-weight: ${({ sent }) => (sent ? 600 : 400)};
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: ${({ sent }) => !sent && '#E1E1E1'};

  &:hover {
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  }
`;
