import styled, { keyframes } from 'styled-components';

const isRotating = keyframes`
  100% {
    transform: rotate(1turn);
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
`;

export const Loader = styled.div`
  position: fixed;
  top: 380px;
  left: calc(50% - 25px);
  width: 50px;
  height: 50px;
  border: 6px solid #e5e5e5;
  border-radius: 50%;
  border-top-color: ${({ theme }) => `${theme.colors.primary.main}`};
  animation: ${isRotating} ease 1s infinite;
  margin: 0 auto;
`;
