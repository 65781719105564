import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { SectionTitle } from '../../../components/Texts/HeadingText';

export const List = styled.ul`
  width: 100%;
  max-height: 314px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0px;
  }

  border-bottom: ${({ theme, empty }) => !empty && `16px solid ${theme.colors.primary.main}`};

  p {
    margin-top: 24px;
  }
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  list-style-type: none;
  background-color: ${({ theme }) => theme.colors.altText};
  font-size: 1rem;
  padding: 16px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.altBackground};
`;

export const CustomerName = styled.div`
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* min-width: 100%; */
  text-decoration: none;
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  header {
    margin-bottom: 0;
  }
`;

export const SecondaryButton = styled(Link)`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary.main};
  margin-top: 25px;
  text-decoration: none;
  font-weight: 500;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  svg {
    transform: rotate(-45deg);
    margin-right: 5px;
  }
`;

export const Title = styled(SectionTitle)`
  margin-top: 0px;
`;
