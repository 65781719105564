import fetcher from '../fetcher';

export const getAllDoubtsOfFAQ = async () => {
  const fetch = fetcher(true);

  const {
    data: { data },
  } = await fetch.get(`/faq`);

  return data;
};

export const createDoubt = async ({ question, answer }) => {
  const fetch = fetcher(true);

  const {
    data: { data },
  } = await fetch.post('/faq', { question, answer });

  return data;
};

export const deleteFaq = async id => {
  const fetch = fetcher(true);

  const {
    data: { data },
  } = await fetch.delete(`/faq/${id}`);

  return data;
};
