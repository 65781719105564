import React from 'react';
import { IoPaperPlaneOutline } from 'react-icons/io5';
import { useHistory } from 'react-router';

import { toast } from 'react-toastify';
import useForms from '../../../hooks/useForms';

import { Button, PageLayout } from '../../../components';
import { SectionTitle } from '../../../components/Texts/HeadingText';

import { createCycle } from '../../../services/cycle';
import { ButtonsArea, CancelButton, Form } from './styles';

function AddCycle() {
  const history = useHistory();
  const { fields, createFields, errors } = useForms();

  const isFormValid = () => {
    const isSomeFieldEmpty = ['name'].some(field => !fields[field]);

    return !isSomeFieldEmpty && !errors.length;
  };

  const fieldsObject = {
    name: {
      label: 'Nome do ciclo',
      value: fields.name,
      placeholder: 'Ciclo',
      messageIfHasError: 'Nome do ciclo obrigatório',
    },
  };

  function handleBack() {
    history.goBack();
  }

  async function handleCreateCycle() {
    const { name } = fields;
    try {
      await createCycle(name);

      toast.success('Ciclo criado!', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });

      handleBack();
    } catch (err) {
      if (err.response.status === 409) {
        toast.error('Ciclo já existe!', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    }
  }

  return (
    <PageLayout previous>
      <Form>
        <SectionTitle>Adicionar Ciclo</SectionTitle>
        {createFields('name', fieldsObject)}

        <ButtonsArea>
          <Button onClick={handleCreateCycle} disabled={!isFormValid()}>
            <IoPaperPlaneOutline size={24} />
            Adicionar Ciclo
          </Button>
          <CancelButton type="button" onClick={handleBack}>
            Cancelar
          </CancelButton>
        </ButtonsArea>
      </Form>
    </PageLayout>
  );
}

export default AddCycle;
