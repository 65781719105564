import React, { useState } from 'react';
import useForms from '../../hooks/useForms';
import { Info, TextArea, ButtonArea, ObservationsList } from './style';
import { Button, Details } from '..';
import Observation from '../Observation';

function Observations({ people, observations = [], onSubmit }) {
  const {
    fields: { observation },
    handleFieldsChange,
    clearAllFields,
    createDropdownFields,
  } = useForms();
  const [selectedPerson, setSelectedPerson] = useState(null);

  // const filteredObservations = useMemo(
  //   () =>
  //     selectedPerson === '99'
  //       ? observations
  //       : observations?.filter(obs => obs.person === selectedPerson),
  //   [observations, selectedPerson],
  // );

  const fieldsObject = {
    representative: {
      placeholder: 'Selecione um representante',
      options: [...people],
      onSelect: setSelectedPerson,
    },
  };

  const isFormValid = () => observation && selectedPerson;

  return (
    <>
      <Details summary="Adicionar observação" green isHeader>
        <Info>
          {createDropdownFields('representative', fieldsObject)}
          <TextArea
            placeholder="Lorem ipsum dolor sit amet, consectet ui i iadipiscing elit."
            name="observation"
            autoCapitalize="sentences"
            value={observation}
            onChange={handleFieldsChange({ confirm: false })}
            maxLength={3700}
          />

          <ButtonArea>
            <Button
              onClick={() => {
                onSubmit({ selectedPerson, observation });
                clearAllFields();
              }}
              disabled={!isFormValid()}
            >
              Adicionar
            </Button>
          </ButtonArea>
        </Info>
      </Details>
      <ObservationsList>
        {observations?.map((obs, index) => (
          <Observation key={obs.id} index={index} obs={obs} />
        ))}
      </ObservationsList>
    </>
  );
}

export default Observations;
