import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  BodyText,
  HeadingText,
  BodyDetails,
  // ProgressBar,
  List,
  Observations,
  AddButton,
  PageLayout,
  RoadMapList,
  Faq,
  Button,
  Loading,
  CustomerDataModal,
  BodyDetailsSmall,
} from '../../components';
import { Title, SubTitle, Section, Image } from './style';
import { SectionTitle } from '../../components/Texts/HeadingText';
import { selectCustomer, selectProspectorsByCustomer } from '../../redux/slices/customerSlice';
import { selectPeople } from '../../redux/slices/peopleSlice';
import { createContact, getCustomerContacts } from '../../services/contact';
import EditIcon from '../../assets/imgs/edit.svg';
import ImplementationStatusModal from '../../components/ImplementationStatusModal';
import { formatPhone } from '../../utils/formatPhone';
import useDoubtsAndScripts from '../../hooks/useDoubtsAndScripts';
import { getAllProspectorsByCustomerId } from '../../services/prospection';

function ContactInformation() {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const customer = useSelector(state => selectCustomer(state, id));
  const people = useSelector(state => selectPeople(state));
  const observations = useSelector(state => state.contact);
  const prospectors = useSelector(state => selectProspectorsByCustomer(state, id));
  const { doubts, scripts } = useDoubtsAndScripts();

  const [isImplementationStatusModalOpen, setIsImplementationStatusModalOpen] = useState(false);
  const [isCustomerDataModalOpen, setIsCustomerDataModalOpen] = useState(false);

  if (!customer || !customer?.people) history.goBack();

  useEffect(() => {
    dispatch(getCustomerContacts(id));
    // eslint-disable-next-line no-unused-expressions
    id && dispatch(getAllProspectorsByCustomerId(id));
  }, []);

  const getPeopleId = useCallback(
    () => Object.values(customer?.people).map(p => p?.id),
    [customer?.people],
  );

  const submitObservation = ({ selectedPerson, observation }) => {
    if (observation !== '') {
      dispatch(
        createContact({
          customerId: id,
          personName: selectedPerson.name,
          personId: selectedPerson.id,
          annotation: observation?.trim(),
        }),
      );
    }
  };

  return (
    <PageLayout previous={`/customer/${id}`}>
      <Title>
        <HeadingText color="text">Informações de Contato</HeadingText>
      </Title>

      <SubTitle>
        <BodyText color="text">
          Você está em contato com o município <b>{customer?.name}</b>
        </BodyText>
      </SubTitle>

      {/* <Section editable>
        <SectionTitle>Status de Implementação</SectionTitle>
        <ProgressBar step={customer?.implementationStatus?.step} />
        <BodyText color="text">{customer?.implementationStatus?.status}</BodyText>
        <Button onClick={() => setIsImplementationStatusModalOpen(true)}>
          <Image alt="Ícone de edição" src={EditIcon} />
          Editar status
        </Button>
      </Section> */}

      {isImplementationStatusModalOpen && (
        <ImplementationStatusModal
          isModalOpen={isImplementationStatusModalOpen}
          setModalClose={setIsImplementationStatusModalOpen}
        />
      )}

      {isCustomerDataModalOpen && (
        <CustomerDataModal
          isModalOpen={isCustomerDataModalOpen}
          setModalClose={setIsCustomerDataModalOpen}
          customerData={customer}
        />
      )}

      <Section>
        <SectionTitle>Representantes</SectionTitle>
        {customer?.people &&
          getPeopleId()?.map(pid => <BodyDetails data={people[pid]} key={pid} customerId={id} />)}
        <AddButton to={`/contact/${id}/add-representative`}>Adicionar Representante</AddButton>
      </Section>

      <Section>
        <SectionTitle>Prospectos</SectionTitle>
        {prospectors?.map(prospector => (
          <BodyDetailsSmall data={prospector} key={prospector?.id} customerId={id} />
        ))}
        <AddButton to={`/contact/${id}/add-prospectus`} state={[]}>
          Adicionar Prospecto
        </AddButton>
      </Section>

      <Section>
        <SectionTitle>Observações</SectionTitle>
        {customer?.people && (
          <Observations
            observations={observations?.contacts}
            people={customer?.people}
            onSubmit={submitObservation}
          />
        )}
      </Section>

      <Section editable>
        <SectionTitle>Dados do Município</SectionTitle>
        <List
          group={customer?.implementationGroup?.populationGroup}
          population={customer?.population}
          stage={customer?.expectedPhase?.phase}
          date={customer?.accessionDate}
          decreeDate={customer?.decreeDate}
          phone={formatPhone(customer?.customerPhone)}
        />
        <Button onClick={() => setIsCustomerDataModalOpen(true)}>
          <Image alt="Ícone de edição" src={EditIcon} />
          Editar dados
        </Button>
      </Section>

      {!doubts || !scripts ? (
        <Loading />
      ) : (
        <>
          <Section>
            <SectionTitle>Roteiros</SectionTitle>
            <RoadMapList scripts={scripts} />
          </Section>

          <Section>
            <SectionTitle>FAQ</SectionTitle>
            <Faq doubts={doubts} />
          </Section>
        </>
      )}
    </PageLayout>
  );
}

export default ContactInformation;
