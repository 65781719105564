import { createAsyncThunk } from '@reduxjs/toolkit';
import fetcher from '../fetcher';

export const getCustomerByUserId = createAsyncThunk(
  'get/responsible-customers',
  async (id, { getState }) => {
    const fetch = fetcher(true);
    const state = getState();
    const customerState = state?.customer;

    let formattedCustomers = customerState.customers;
    if (!formattedCustomers || !Object.keys(formattedCustomers).length) {
      const res = await fetch.get(`/customer/userResponsible/${id}`);
      if (res.status !== 200) throw Error('Was not possible to get customers!');

      const customers = res?.data?.data;
      formattedCustomers = customers.reduce((p, c) => ({ ...p, [c.id]: c }), {});
    }

    return formattedCustomers;
  },
);

export const getClientsByCycle = createAsyncThunk('get/customers', async (info, { getState }) => {
  const { id } = info;

  const fetch = fetcher(true);
  const state = getState();
  const customerState = state?.customer;

  let formattedCustomers = customerState.customers;
  // if (!formattedCustomers || !Object.keys(formattedCustomers).length || info.reload) {
  const res = await fetch.get(`/associationUserCycle/user/${id}`);
  if (res.status !== 200) throw Error('Was not possible to get customers!');
  const customers = res?.data?.data;
  formattedCustomers = customers.reduce((p, c) => ({ ...p, [c.id]: c }), {});

  return formattedCustomers;
});

export const getClients = createAsyncThunk('get/customers', async (info, { getState }) => {
  // const { id } = info.id;
  const fetch = fetcher(true);
  const state = getState();
  const customerState = state?.customer;

  let formattedCustomers = customerState.customers;
  if (!formattedCustomers || !Object.keys(formattedCustomers).length || info.reload) {
    const res = await fetch.get(`/user/customer/cycle`);
    if (res.status !== 200) throw Error('Was not possible to get customers!');
    const customers = res?.data?.data;
    formattedCustomers = customers.reduce(
      (p, c) => ({
        ...p,
        [c?.customerId]: { ...c, id: c?.customerId, userResponsibleId: c?.userResposibleId },
      }),
      {},
    );
  }

  return formattedCustomers;
});

export const getClient = createAsyncThunk('get/customer', async (id, { getState }) => {
  const fetch = fetcher(true);
  const state = getState();
  const customerState = state?.customer;

  let formattedCustomer = customerState?.customers[id];

  // if (!formattedCustomer || !formattedCustomer.people || !formattedCustomer.userResponsible) {
  const res = await fetch.get(`/customer/${id}`);

  const {
    data: { data: prospectors },
  } = await fetch.get(`/prospection/customer/${id}`);

  if (res.status !== 200) throw Error('Was not possible to get this customer!');

  formattedCustomer = res?.data?.data;
  // }

  formattedCustomer = {
    [formattedCustomer.id]: { ...formattedCustomer, prospectors },
  };

  return formattedCustomer;
});

export const updateImplementationStatus = createAsyncThunk(
  'update/implementationStatus',
  async ({ customerId, statusId }) => {
    const fetch = fetcher(true);

    const {
      data: { data },
    } = await fetch.patch(`/customer/${customerId}/implementationStatus/${statusId}`);

    return data;
  },
);

export const updateCustomerData = createAsyncThunk(
  'update/customerData',
  async ({ customerId, ...body }) => {
    const fetch = fetcher(true);

    const {
      data: { data },
    } = await fetch.put(`/customer/${customerId}`, { ...body });

    return data;
  },
);

export const createCustomer = createAsyncThunk('create/customer', async data => {
  const fetch = fetcher(true);

  const {
    data: { data: customer },
  } = await fetch.post('/customer', data);

  return customer;
});

export const getAllCustomers = createAsyncThunk('get/allCustomers', async (info, { getState }) => {
  const fetch = fetcher(true);
  const state = getState();
  const customerState = state?.customer;

  let formattedCustomers = customerState.customers;
  if (!formattedCustomers || !Object.keys(formattedCustomers).length || info.reload) {
    const res = await fetch.get('/customer');
    if (res.status !== 200) throw Error('Was not possible to get customers!');
    const customers = res?.data?.data;
    formattedCustomers = customers.reduce((p, c) => ({ ...p, [c.id]: c }), {});
  }

  return formattedCustomers;
});

export const getAllCustomersWithoutResponsible = async () => {
  const fetch = fetcher(true);

  const {
    data: { data },
  } = await fetch.get('/customer/without/userResponsible');

  return data;
};

export const delegateOrTransferManyCustomers = createAsyncThunk(
  'setManyCustomersResponsible',
  async ({ customerIds, userId }) => {
    const fetch = fetcher(true);
    const {
      data: {
        data: { customers },
      },
    } = await fetch.post(`/customer/userResponsible/${userId}`, { customerIds });

    const newCustomers = customers.reduce(
      (acc, customer) => ({
        ...acc,
        [customer?.id]: {
          ...customer,
        },
      }),
      {},
    );

    return newCustomers;
  },
);

export const removeManyCustomersOfAnUserResponsible = createAsyncThunk(
  'removeCustomersOfAnUserResponsible',
  async ({ userId, customerIds }, { getState }) => {
    const fetch = fetcher(true);
    const state = getState();
    const customerState = state?.customer;

    const response = await fetch.delete(`/customer/userResponsible/${userId}`, {
      data: {
        customerIds,
      },
      Headers: {
        'Content-Type': 'application/json',
      },
    });

    const { customers } = customerState;
    const newCustomersValue = JSON.parse(JSON.stringify(customers));

    if (response.status === 200) {
      customerIds.forEach(customerId => {
        newCustomersValue[customerId].userResponsible = null;
      });
    }

    return newCustomersValue;
  },
);

export const getReportByUser = async userId => {
  const fetch = fetcher(true);

  const {
    data: { data },
  } = await fetch.get(`/customer/users/${userId}`);

  return data;
};

export const getReportByStatus = async statusId => {
  const fetch = fetcher(true);

  const {
    data: { data },
  } = await fetch.get(`/customer/status/${statusId}`);

  return data;
};
export const getReportByCycle = async cycleId => {
  const fetch = fetcher(true);

  const {
    data: { data },
  } = await fetch.get(`/customer/cycle/${cycleId}`);

  return data;
};
export const getReportAllCustomers = async () => {
  const fetch = fetcher(true);

  const {
    data: { data },
  } = await fetch.get(`/customer/reportAll`);

  return data;
};

export const getCustomerEmails = createAsyncThunk('get/customerEmail', async id => {
  const fetch = fetcher(true);

  const {
    data: { data: customerEmail },
  } = await fetch.get(`/customerEmails/customer/${id}`);

  if (!customerEmail) return { customerId: id, emails: [] };

  const emails = customerEmail.map(email => ({
    id: email?.id,
    email: email?.email,
    userCollect: email?.userCollect,
    dateUserCollect: email?.dateUserCollect,
    category: email.category,
  }));

  let customerId = 0;

  if (customerEmail.length) {
    customerId = customerEmail[0]?.customer?.id;
  }

  return { customerId, emails };
});

export const getAllCustomerEmail = createAsyncThunk('get/customersEmail', async () => {
  const fetch = fetcher(true);

  const {
    data: { data: customerEmail },
  } = await fetch.get(`/customerEmails`);

  return customerEmail;
});

export const updateCustomerEmail = createAsyncThunk('update/customerEmail', async data => {
  const fetch = fetcher(true);

  const {
    data: { data: customerEmail },
  } = await fetch.put(`/customerEmails/customer/${data?.id}`, data);

  return customerEmail;
});

export const deleteCustomerEmail = async id => {
  const fetch = fetcher(true);

  const { data } = await fetch.delete(`/customerEmails/customer/${id}`);

  return data;
};
