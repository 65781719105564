import React, { useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Title, Subtitle, Section, SectionControls, TitleSecondary, Span } from './style';
import {
  BodyText,
  HeadingText,
  OutlinedButton,
  PageLayout,
  CustomersList,
} from '../../../components';
import { actions } from './mock';
import { selectCustomers } from '../../../redux/slices/customerSlice';
import { selectCollaborator } from '../../../redux/slices/collaboratorSlice';
import DropdownModal from './DropdownModal';
import { selectAllCycles } from '../../../redux/slices/cycleSlice';
import { transformObjectToArray } from '../../../utils/formatObjectToArray';
import { setRemoveUserCycle, setUserCycle } from '../../../services/cycle';

function ClientsOfUser({ location }) {
  const history = useHistory();
  const customers = useSelector(selectCustomers);
  const { id } = useParams();
  const name = location?.state?.name;
  const user = useSelector(state => selectCollaborator(state, id));
  const customersFilted = useMemo(
    () => Object.values(customers).filter(customer => customer?.userResponsible?.id === Number(id)),
    [customers, id],
  );
  const [, setIsConfirmModalOpen] = useState(false);
  const [isDropdownModalOpen, setIsDropdownModalOpen] = useState(false);
  const [isDropdownModalSecondaryOpen, setIsDropdownModalSecondaryOpen] = useState(false);
  const [modalHandlerType, setModalHandlerType] = useState(null);
  const [cycleSelected, setCycleSelected] = useState(null);
  const cycles = useSelector(selectAllCycles);
  const dispatch = useDispatch();

  if (!name) history.goBack();

  function setCycle() {
    dispatch(setUserCycle({ cycleId: cycleSelected, userId: id }));
  }
  function removeCycle() {
    user?.userCycle.forEach(cycle => {
      if (Number(cycle?.id) === Number(cycleSelected)) {
        dispatch(setRemoveUserCycle({ cycleId: cycleSelected, userId: id }));
      }
    });
  }

  const modalHandlers = [
    {
      label: 'Adicionar Ciclo',
      // disabled: !!customer.userResponsible,
      action: setCycle,
    },
  ];
  const modalSecondaryHandlers = [
    {
      label: 'Remover Ciclo',
      // disabled: !!customer.userResponsible,
      action: removeCycle,
    },
  ];
  function handleOpenModal({ modalType }) {
    return () => {
      if (['supervise', 'remove'].includes(modalType)) {
        setIsConfirmModalOpen(true);
      } else {
        setIsDropdownModalOpen(true);
      }

      setModalHandlerType(modalType);
    };
  }

  function handleCloseModal() {
    setIsConfirmModalOpen(false);
    setIsDropdownModalOpen(false);
  }

  function handleOpenSecondaryModal() {
    setIsDropdownModalSecondaryOpen(true);
  }

  function handleCloseModalSecondary() {
    setIsDropdownModalSecondaryOpen(false);
  }

  return (
    <PageLayout previous="/customers">
      <Title>
        <HeadingText color="text">{name}</HeadingText>
      </Title>

      <Subtitle>
        {/* <BodyText color="text">
          Equipe: <strong>Érida/Lucas</strong>
        </BodyText> */}
        <BodyText color="text">
          Ciclos: <strong>{!user?.userCycle.length && 'Sem ciclo'}</strong>
          {user?.userCycle.map((cycle, index) => (
            <Span>
              {cycle?.cycle?.name}
              {index !== user?.userCycle.length - 1 && ', '}
            </Span>
          ))}
        </BodyText>
      </Subtitle>

      <Section>
        {Object.keys(modalHandlers).map(modalHandler => (
          <OutlinedButton onClick={handleOpenModal({ modalType: modalHandler })}>
            {modalHandlers[modalHandler].label}
          </OutlinedButton>
        ))}
        <OutlinedButton alert onClick={handleOpenSecondaryModal} disabled={!user?.userCycle.length}>
          Remover ciclo
        </OutlinedButton>
      </Section>

      <TitleSecondary>Municípios</TitleSecondary>

      <CustomersList
        noCircle
        customers={customersFilted}
        isCustomersListEmptySentence="Usuário não é responsável por nenhum município"
      />

      <SectionControls>
        {actions.map(action => (
          <Link
            key={action.route}
            to={{
              pathname: action.route,
              state: {
                userId: id,
                customers: customersFilted,
                userCycle: user?.userCycle[0]?.cycle?.id,
              },
            }}
          >
            <OutlinedButton alert={action.alert}>{action.label}</OutlinedButton>
          </Link>
        ))}
      </SectionControls>

      <DropdownModal
        isOpen={isDropdownModalOpen}
        onClose={handleCloseModal}
        modalHandler={modalHandlers[modalHandlerType]}
        options={transformObjectToArray(cycles)}
        label="Ciclo"
        setSelected={setCycleSelected}
      />
      <DropdownModal
        isOpen={isDropdownModalSecondaryOpen}
        onClose={handleCloseModalSecondary}
        modalHandler={modalSecondaryHandlers[0]}
        options={user?.userCycle?.map(cycle => ({
          ...cycle.cycle,
          idFake: cycle.cycle.id,
          id: cycle.id,
        }))}
        label="Ciclo"
        setSelected={setCycleSelected}
      />
    </PageLayout>
  );
}

export default ClientsOfUser;
