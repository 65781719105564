import React from 'react';

import { AddHelpForm, PageLayout } from '../../../components';
import { createRoadmap } from '../../../services/roadmap';

function AddRoadmap() {
  function handleCreateRoadmap(title, text) {
    createRoadmap({
      name: title,
      description: text,
    });
  }

  return (
    <PageLayout previous="help">
      <AddHelpForm
        formTitle="Adicionar Roteiro"
        titleLabel="Título"
        textLabel="Texto"
        onClick={handleCreateRoadmap}
      />
    </PageLayout>
  );
}

export default AddRoadmap;
