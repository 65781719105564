import React, { useState, memo } from 'react';
import { FaUserAlt, FaBell } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HeaderLarge, HeaderSmall, Controls, Logout } from './style';
import Logo from '../../assets/imgs/logo.svg';
import { selectUser } from '../../redux/slices/authSlice';

function Header({ type }) {
  const user = useSelector(selectUser);
  const [visible, setVisible] = useState(false);

  const changeVisible = () => setVisible(!visible);

  return (
    <>
      {type === 'large' ? (
        <HeaderLarge>
          <img src={Logo} alt="Nuniware" />
        </HeaderLarge>
      ) : (
        <HeaderSmall>
          <Link to={user.permission === 'admin' ? '/dashboard' : '/customers'}>
            <img width="161px" src={Logo} alt="Nuniware" />
          </Link>
          <Controls>
            <Link to="/notifications">
              <FaBell size={20} color="#fff" />
            </Link>
            <Logout to="/profile">
              <FaUserAlt onClick={changeVisible} size={20} color="#fff" />
            </Logout>
          </Controls>
        </HeaderSmall>
      )}
    </>
  );
}

export default memo(Header);
