import { createAsyncThunk } from '@reduxjs/toolkit';
import fetcher from '../fetcher';

function transformArrayToObject(array) {
  const result = {};

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < array.length; i++) {
    const cycle = array[i];
    result[cycle.id] = { id: cycle.id, name: cycle.name };
  }

  return result;
}

export const getAllCycles = createAsyncThunk('getAllCycles/cycle', async () => {
  const fetch = fetcher(true);
  const {
    data: { cycles },
  } = await fetch.get('/cycle/');

  return transformArrayToObject(cycles);
});

export const getAllCustomerByCycleId = createAsyncThunk(
  'getAllCustomerByCycleId/cycle',
  async id => {
    const fetch = fetcher(true);

    const {
      data: { data: customers },
    } = await fetch.get(`/associationCycleCustomer/cycle/${id}`);

    const onlyCustomers = customers.map(customer => ({
      ...customer.customer,
      cycle: customer.cycle.id,
    }));

    return { id, customers: onlyCustomers };
  },
);

export const getAllUsersByCycleId = createAsyncThunk('getAllUsersByCycleId/cycle', async id => {
  const fetch = fetcher(true);

  const {
    data: { data: users },
  } = await fetch.get(`/associationUserCycle/cycle/${id}`);
  const onlyUsers = users.map(user => ({ ...user.user, cycle: user.cycle.id }));

  return { id, users: onlyUsers };
});

export const setUserCycle = createAsyncThunk('setUserCycle/cycle', async data => {
  const fetch = fetcher(true);
  const { cycleId, userId } = data;

  const {
    data: { data: res },
  } = await fetch.post('/associationUserCycle/', {
    cycleId,
    userId,
  });

  const collaborator = {
    ...res?.user,
    userCycle: { id: res.id, cycle: res?.cycle },
  };
  return collaborator;
});

export const setRemoveUserCycle = createAsyncThunk('setRemoveUserCycle/cycle', async data => {
  const fetch = fetcher(true);
  const { cycleId, userId } = data;

  const {
    data: { data: res },
  } = await fetch.delete(`/associationUserCycle/${cycleId}`);
  const collaborator = {
    ...res?.user,
    userId,
    userCycle: cycleId,
  };
  return collaborator;
});

export const setCustomerCycle = createAsyncThunk('setCustomerCycle/cycle', async data => {
  const fetch = fetcher(true);
  const { cycleId, customerId } = data;
  const {
    data: { data: res },
  } = await fetch.post('/associationCycleCustomer/', {
    cycleId,
    customerId,
  });

  return { customerId: res?.customer?.id, customerCycle: res?.cycle };
});
export const createCycle = async name => {
  const fetch = fetcher(true);

  const data = await fetch.post('/cycle/', {
    name,
  });

  return data;
};
