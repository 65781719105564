import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const List = styled.ul`
  width: 100%;
  max-height: 314px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0px;
  }

  border-bottom: ${({ theme, addButton }) =>
    !addButton && `16px solid ${theme.colors.primary.main}`};

  p {
    margin-top: 24px;
  }
`;

export const Item = styled.li`
  display: flex;
  padding: ${({ isSelectionList }) => (isSelectionList ? '15px 16px' : 0)};
  align-items: center;
  list-style-type: none;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.altBackground : theme.colors.altText};
  font-size: 1rem;
  border-bottom: 2px solid ${({ theme }) => theme.colors.altBackground};
  cursor: pointer;
`;

export const Circle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ theme, haveCycle }) =>
    haveCycle ? theme.colors.primary.main : theme.colors.alert.main};
  margin-right: 8px;
`;

export const Navigation = styled(Link)`
  padding: 15px 16px;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  text-decoration: none;
`;

export const InfoName = styled.div`
  display: flex;
  align-items: center;

  color: ${({ theme, myTeam }) => !myTeam && theme.colors.disabled};
`;
