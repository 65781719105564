import React from 'react';

import { AddHelpForm, PageLayout } from '../../../components';
import { createDoubt } from '../../../services/faq';

function AddDoubt() {
  function handleCreateDoubt(title, text) {
    createDoubt({
      question: title,
      answer: text,
    });
  }

  return (
    <PageLayout previous="/dashboard/help">
      <AddHelpForm
        formTitle="Adicionar Dúvida"
        titleLabel="Dúvida"
        textLabel="Resposta"
        onClick={handleCreateDoubt}
      />
    </PageLayout>
  );
}

export default AddDoubt;
