import styled, { css } from 'styled-components';

export const Wrapper = styled.button`
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme, danger }) =>
    danger ? theme.colors.alert.main : theme.colors.primary.main};
  padding: 15px 16px;
  border: none;
  outline: none;
  border-radius: 4px;
  font-size: 1rem;
  margin-top: 20px;
  gap: 12px;
  transition: 0.2s ease-in;
  display: flex;
  align-items: center;

  &:not(:disabled):hover {
    filter: brightness(0.8);
  }

  &[disabled] {
    cursor: default;
    background: ${({ theme }) => theme.colors.disabled};
  }

  ${({ white, theme }) =>
    white &&
    css`
      color: ${theme.colors.primary.main};
      background: ${theme.colors.altText} !important;
    `}
`;
