import React, { useEffect, useState } from 'react';
import { IoPaperPlaneOutline } from 'react-icons/io5';
import { useHistory } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import useForms from '../../../hooks/useForms';

import { Button, DropdownModal, PageLayout } from '../../../components';
import { SectionTitle } from '../../../components/Texts/HeadingText';

import { AccessionDate, ButtonsArea, CancelButton, DateInputs, Form, Label } from './styles';

import { createCustomer } from '../../../services/customers';
import { getExpectedPhases } from '../../../services/expectedPhase';
import { getImplementationGroups } from '../../../services/implementationGroup';
import { getImplementationStatuses } from '../../../services/implementationStatus';

import { selectCollaboratorByCycle } from '../../../redux/slices/collaboratorSlice';
import { selectAllCycles } from '../../../redux/slices/cycleSlice';
import { formatInputDate } from '../../../utils/formatInputDate';
import { transformObjectToArray } from '../../../utils/formatObjectToArray';
import { formatPhone } from '../../../utils/formatPhone';
import { formatPhoneToNumber } from '../../../utils/formatPhoneToNumber';

function AddCustomer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { fields, createFields, createDropdownFields, errors } = useForms();
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);
  const [implementationsStatuses, setImplementationsStatuses] = useState({});
  const [implementationGroups, setImplementationGroups] = useState({});
  const [expectedPhases, setExpectedPhases] = useState({});
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedImplementationGroup, setSelectedImplementationGroup] = useState(null);
  const [selectedExpectedPhases, setSelectedExpectedPhases] = useState(null);
  const allCycles = useSelector(selectAllCycles);
  const [selectedCycle, setSelectedCycle] = useState(null);
  const [isDropdownModalOpen, setIsDropdownModalOpen] = useState(false);
  const collaborators = useSelector(state => selectCollaboratorByCycle(state, selectedCycle));

  const modalHandler = {
    action: () => {},
  };

  useEffect(() => {
    (async () => {
      const statuses = await getImplementationStatuses();
      setSelectedStatus(statuses[0].id);
      const formattedStatuses = statuses.reduce(
        (accumulator, status) => ({ ...accumulator, [status.id]: status }),
        {},
      );
      setImplementationsStatuses(formattedStatuses);

      const groups = await getImplementationGroups();
      setImplementationGroups(groups);

      const phases = await getExpectedPhases();
      setExpectedPhases(phases);
    })();
  }, []);

  const isFormValid = () => {
    const isSomeFieldEmpty = ['name', 'population'].some(field => !fields[field]);

    return !isSomeFieldEmpty && !errors.length && selectedImplementationGroup;
  };

  const fieldsObject = {
    name: {
      label: 'Nome do município',
      value: fields.name,
      placeholder: 'Município',
      messageIfHasError: 'Nome do município obrigatório',
    },
    population: {
      label: 'Número de habitantes',
      value: fields.population,
      placeholder: 'População',
    },
    day: {
      value: fields.day,
      placeholder: 'Dia',
      maxLength: 2,
    },
    month: {
      value: fields.month,
      placeholder: 'Mês',
      maxLength: 2,
    },
    year: {
      value: fields.year,
      placeholder: 'Ano',
      maxLength: 4,
    },
    phone: {
      label: 'Telefone da Prefeitura',
      value: formatPhone(fields.phone),
      placeholder: '(XX) YYYY-YYYY',
      maxLength: 15,
      validateField: false,
    },
    implementationGroup: {
      label: 'Grupo de implementação',
      options: Object.values(implementationGroups),
      placeholder: 'Selecione uma população',
      onSelect: setSelectedImplementationGroup,
      selectName: 'populationGroup',
    },
    stage: {
      label: 'Fase prevista',
      options: Object.values(expectedPhases),
      placeholder: 'Selecione uma fase',
      onSelect: setSelectedExpectedPhases,
      selectName: 'phase',
    },
    responsibleEmployee: {
      label: 'Colaborador responsável',
      options: selectedCycle ? collaborators : [],
      placeholder: 'Selecione um colaborador',
      onSelect: setSelectedCollaborator,
    },
    cycle: {
      label: 'Ciclo',
      options: transformObjectToArray(allCycles),
      placeholder: 'Selecione um ciclo',
      onSelect: setSelectedCycle,
    },
  };

  function handleBack() {
    history.goBack();
  }

  async function handleCreateCustomer() {
    const { name, population, day, month, year, phone } = fields;
    try {
      dispatch(
        createCustomer({
          name,
          population,
          accessionDate: formatInputDate({ day, month, year }),
          customerPhone: formatPhoneToNumber(phone),
          statusId: selectedStatus,
          groupId: selectedImplementationGroup,
          phaseId: selectedExpectedPhases,
          userResponsibleId: selectedCollaborator,
          cycleId: selectedCycle,
        }),
      );

      handleBack();
    } catch (err) {
      // console.log(err);
    }
  }

  return (
    <PageLayout previous>
      <Form>
        <SectionTitle>Adicionar Município</SectionTitle>
        {createFields('name', fieldsObject)}
        {/* <StatusArea>
          <StatusBarArea>
            <h5>Status de implementação</h5>
            <ProgressBar step={implementationsStatuses[selectedStatus]?.step} />
            <BodyText color="text">{implementationsStatuses[selectedStatus]?.status}</BodyText>
          </StatusBarArea>
          <Button onClick={() => setIsDropdownModalOpen(true)}>Editar status</Button>
        </StatusArea> */}
        <DropdownModal
          isOpen={isDropdownModalOpen}
          onClose={() => setIsDropdownModalOpen(false)}
          modalHandler={modalHandler}
          label="Status de implementação"
          placeholder="Selecione um status"
          options={Object.values(implementationsStatuses)}
          selectName="status"
          setSelected={setSelectedStatus}
        />
        {createDropdownFields('implementationGroup', fieldsObject)}
        {createFields('population', fieldsObject)}
        {/* {createDropdownFields('stage', fieldsObject)} */}
        <AccessionDate>
          <Label>Data de adesão</Label>
          <DateInputs>
            {['day', 'month', 'year'].map(field => createFields(field, fieldsObject))}
          </DateInputs>
        </AccessionDate>
        {createFields('phone', fieldsObject)}
        {createDropdownFields('cycle', fieldsObject)}
        {selectedCycle && createDropdownFields('responsibleEmployee', fieldsObject)}
        <ButtonsArea>
          <Button onClick={handleCreateCustomer} disabled={!isFormValid()}>
            <IoPaperPlaneOutline size={24} />
            Adicionar município
          </Button>
          <CancelButton type="button" onClick={handleBack}>
            Cancelar
          </CancelButton>
        </ButtonsArea>
      </Form>
    </PageLayout>
  );
}

export default AddCustomer;
