/* eslint-disable import/named */
import React, { useState } from 'react';
import { RiArrowDropDownLine, RiDeleteBin6Line } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectUserPermission } from '../../redux/slices/authSlice';
import { deleteFaq } from '../../services/faq';
import { deleteRoadMap } from '../../services/roadmap';
import { Wrapper, Summary, Content, Arrow, ContentView, IconBtn } from './style';
// import useDoubtsAndScripts from '../../hooks/useDoubtsAndScripts';

function Details({ children, summary, green, identifier, type, isHeader, location = '' }) {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const userPermission = useSelector(selectUserPermission);

  function handleToggleIsOpen() {
    setIsOpen(prevState => !prevState);
  }

  // eslint-disable-next-line no-shadow
  const DelRoadMap = async id => {
    try {
      // eslint-disable-next-line no-console
      if (type === 'roadmap') {
        await deleteRoadMap(id);
        history.push('/dashboard/help');
      }

      if (type === 'doubts') {
        await deleteFaq(id);
        history.push('/dashboard/help');
      }
    } catch (err) {
      // console.log(err);
    }
  };

  return (
    <Wrapper>
      <Summary isOpen={isOpen} green={green}>
        {summary}
        <ContentView location={location}>
          <Arrow isOpen={isOpen} onClick={handleToggleIsOpen}>
            <RiArrowDropDownLine size={30} />
          </Arrow>
          {userPermission && !isHeader && location === '/dashboard/help' && (
            <IconBtn type="submit" onClick={() => DelRoadMap(identifier)}>
              <RiDeleteBin6Line size={20} color="#db3018" />
            </IconBtn>
          )}
        </ContentView>
      </Summary>
      <Content isOpen={isOpen}>{children}</Content>
    </Wrapper>
  );
}

export default Details;
