import Cookies from 'js-cookie';
import { createAsyncThunk } from '@reduxjs/toolkit';
import fetcher from '../fetcher';

export const login = createAsyncThunk('login/user', async fields => {
  const { email, password } = fields;
  const fetch = fetcher();
  const {
    data: { token, user },
  } = await fetch.post('/auth/login', {
    email,
    password,
  });

  Cookies.set('token', token);

  return user;
});

export const register = createAsyncThunk('register/user', async fields => {
  const { name, email, password, token, cycle } = fields;
  const fetch = fetcher();

  try {
    const res = await fetch.post(`/auth/register/${token}`, {
      name,
      email,
      password,
      permissionId: 1,
      cycleId: cycle,
    });

    return { account: res, status: 201 };
  } catch (err) {
    console.log(err.response);
    return {
      status: err.response.status,
      message: err.response.data.message,
    };
    // return { error: err.response.data };
  }

  // const {
  //   data: { token: tokenAuth },
  // } = await fetch.post('/auth/login', {
  //   email,
  //   password,
  // });

  // Cookies.set('token', tokenAuth);
  // const fetchAuth = fetcher(true);

  // const resCycle = await fetchAuth.post(`/associationUserCycle/`, {
  //   userId: res.data.data.id,
  //   cycleId: cycle,
  // });
});

export const verifyToken = createAsyncThunk('verify/user', async () => {
  const fetch = fetcher(true);

  const {
    data: { auth },
  } = await fetch.get(`/auth/verify`);
  return auth;
});

export const logout = createAsyncThunk('logout/user', async () => {
  localStorage.clear();
  Cookies.remove('token');
});
