import React from 'react';
import { useHistory, useParams } from 'react-router';
import { IoPaperPlaneOutline } from 'react-icons/io5';

import { useDispatch, useSelector } from 'react-redux';
import { Button, PageLayout } from '../../components';
import { SectionTitle } from '../../components/Texts/HeadingText';
import useForms from '../../hooks/useForms';
import { Form, ButtonsArea, CancelButton } from './style';

import { formatPhone } from '../../utils/formatPhone';
import { formatCpf } from '../../utils/formatCpf';
import { createPerson } from '../../services/person';
import { formatPhoneToNumber } from '../../utils/formatPhoneToNumber';
import { formatCpfToNumber } from '../../utils/formatCpfToNumber';

import { selectCustomer } from '../../redux/slices/customerSlice';

function AddRepresentative() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { fields, createFields, errors } = useForms();

  const customerExists = useSelector(state => selectCustomer(state, id));

  if (!customerExists) history.goBack();

  const fieldsObject = {
    name: {
      label: 'Nome do representante',
      value: fields.name,
      placeholder: 'Nome',
    },
    whatsapp: {
      label: 'Whatsapp',
      value: formatPhone(fields.whatsapp),
      maxLength: 15,
      placeholder: '(XX) YYYYY-YYYY',
    },
    phone: {
      label: 'Telefone',
      value: formatPhone(fields.phone),
      maxLength: 15,
      placeholder: '(XX) YYYY-YYYY',
    },
    email: {
      label: 'E-mail do representante',
      value: fields.email,
      type: 'email',
      placeholder: 'E-mail',
    },
    role: {
      label: 'Cargo do representante',
      value: fields.role,
      placeholder: 'Cargo',
    },
    cpf: {
      label: 'CPF do representante',
      value: formatCpf(fields.cpf),
      maxLength: 14,
      placeholder: 'Cpf',
    },
  };

  function handleBack() {
    history.push(`/contact/${id}`);
  }

  async function handleCreatePerson() {
    try {
      dispatch(
        createPerson({
          name: fields.name,
          whatsapp: formatPhoneToNumber(fields.whatsapp),
          phone: formatPhoneToNumber(fields.phone),
          role: fields.role,
          email: fields.email,
          cpf: formatCpfToNumber(fields.cpf),
          customerId: id,
        }),
      );

      handleBack();
    } catch (err) {
      // eslint-disable-next-line no-console
      // console.log(err);
    }
  }

  const isFormValid = () => fields.name && fields.email && !errors.length;

  return (
    <PageLayout previous={`/contact/${id}`}>
      <Form>
        <SectionTitle>Adicionar representante</SectionTitle>
        {['name', 'whatsapp', 'phone', 'email', 'role', 'cpf'].map(field =>
          createFields(field, fieldsObject),
        )}

        <ButtonsArea>
          <Button onClick={handleCreatePerson} disabled={!isFormValid()}>
            <IoPaperPlaneOutline size={24} />
            Adicionar representante
          </Button>
          <CancelButton type="button" onClick={handleBack}>
            Cancelar
          </CancelButton>
        </ButtonsArea>
      </Form>
    </PageLayout>
  );
}

export default AddRepresentative;
