import styled from 'styled-components';

const BodyText = styled.p`
  font-size: 14px;
  text-align: center;
  color: ${({ theme, color }) => theme.colors.primary[color] || theme.colors.primary.main};
  line-height: 18px;
`;

export default BodyText;
