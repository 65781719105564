import { utcToZonedTime, format } from 'date-fns-tz';

export default function formatDate(date, showHour = true) {
  if (!date) return null;
  const timeZone = 'America/Sao_Paulo';
  const zonedDate = utcToZonedTime(new Date(date), timeZone);

  const pattern = showHour ? 'dd/MM/yyyy - HH:mm' : 'dd/MM/yyyy';

  return format(zonedDate, pattern, { timeZone });
}

export function formatDayOrMonth(date, type) {
  if (date === '') return '';
  switch (type) {
    case 'day':
      return Number(date) <= 31 ? `0000${date}`.slice(-2) : '31';
    case 'month':
      return Number(date) <= 12 ? `0000${date}`.slice(-2) : '12';
    default:
      return date;
  }
}
