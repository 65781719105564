import styled from 'styled-components';

export const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  outline: transparent;
  border: none;
  min-height: 120px;
  border-radius: 4px;
  padding: 10px 15px;
  color: ${({ theme }) => theme.colors.primary.main};
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  label {
    font-size: 0.875rem;
  }
`;

export const End = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Btn = styled.button`
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 20px;
  background-color: #096032;
  display: flex;
  align-items: center;
  justify-content: center;
`;
