import ReactDOM from 'react-dom';

import { Overlay, Container, Footer } from './styles';

import { BodyText, Button, OutlinedButton } from '..';

function ConfirmModal({ isOpen, modalHandler, onClose, collaboratorName, modalType }) {
  function handleConfirm() {
    modalHandler.action();
    onClose();
  }

  const danger = modalType === 'remove';

  return (
    isOpen &&
    ReactDOM.createPortal(
      <Overlay>
        <Container danger={danger}>
          {danger ? (
            <BodyText color="text">
              Deseja remover esse município da lista de <strong>{collaboratorName}</strong>?
            </BodyText>
          ) : (
            <BodyText color="text">Deseja passar a supervisionar esse município?</BodyText>
          )}

          <Footer>
            <OutlinedButton onClick={onClose}> Cancelar</OutlinedButton>

            <Button onClick={handleConfirm} type="button" danger={danger}>
              {modalHandler.label}
            </Button>
          </Footer>
        </Container>
      </Overlay>,
      document.getElementById('modal-root'),
    )
  );
}

export default ConfirmModal;
