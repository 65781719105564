import styled, { css } from 'styled-components';

export const ControlButtons = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const Search = styled.label`
  padding: 10px;
  background: ${({ theme }) => theme.colors.altText};
  border-radius: 8px;
  color: ${({ theme, disabled }) => (disabled ? theme.colors.disabled : theme.colors.primary.main)};

  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 194px;
  gap: 10px;
  margin-right: 10px;
  cursor: ${({ disabled }) => (!disabled ? 'text' : 'default')};
`;

export const Input = styled.input`
  outline: 0;
  border: none;
  background: transparent;
  width: 100%;
  font-size: 0.75rem;

  &::placeholder {
    color: ${({ disabled, theme }) =>
      !disabled ? theme.colors.primary.main : theme.colors.disabled};
    text-transform: uppercase;
  }
`;

export const SortButton = styled.button`
  color: ${({ theme }) => theme.colors.altText};
  background: ${({ theme, disabled }) =>
    disabled ? theme.colors.disabled : theme.colors.primary.main} !important;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 4px;
  align-self: flex-end;
  font-size: 0.75rem;
  text-transform: uppercase;
  background: transparent;
  outline: none;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  ${({ disabled }) =>
    disabled &&
    css`  
    span svg {
      transform: none};
    }
  `}
`;

export const Span = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    transform: ${({ orderBy }) => orderBy === 'desc' && 'rotate(180deg)'};
    transition: transform ease-in-out 0.4s;
  }
`;
