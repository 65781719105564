import React from 'react';
import Container from './styles';

function FormGroup({ children, error, noMargin = false }) {
  return (
    <Container noMargin={noMargin}>
      {children}
      {error && <small>{error}</small>}
    </Container>
  );
}

export default FormGroup;
