import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.form`
  position: relative;
  min-height: 100vh;
  width: 414px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
`;

export const DefaultLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary.main};
  align-self: flex-start;
`;

export const RegisterLink = styled(DefaultLink)`
  display: block;
  font-size: 1rem;
  margin-top: 20px;
  align-self: center;
  color: ${({ theme }) => theme.colors.primary.accent};
`;

export const Content = styled.div`
  position: absolute;
  top: 175px;
  width: 100%;
  height: 100%;
  padding: 80px 40px 20px;

  background: #f2f2f2;
  border-radius: 20px 20px 0px 0px;

  h1 {
    text-align: center;
    margin-bottom: 60px;
  }
`;

export const Controls = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
