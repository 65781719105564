import React from 'react';
import { useSelector } from 'react-redux';
import { Title } from './style';
import { CustomersList, PageLayout } from '../../../components';
import { selectCustomers } from '../../../redux/slices/customerSlice';

function AllCustomers() {
  const customers = useSelector(selectCustomers);

  return (
    <PageLayout previous>
      <Title>Municípios</Title>
      <CustomersList large customers={customers} addButton />
    </PageLayout>
  );
}

export default AllCustomers;
