import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  // BodyText,
  // Button,
  CustomersList,
  Loading,
  OutlinedButton,
  PageLayout,
  PeopleList,
} from '../../../components';
import { SectionTitle } from '../../../components/Texts/HeadingText';
import {
  // Section,
  SectionControls,
} from './style';
// import { TeamCode } from '../../../components/TeamCode/style';
import { selectStatus } from '../../../redux/slices/authSlice';
import {
  selectCollaborators,
  selectCollaboratorStatus,
} from '../../../redux/slices/collaboratorSlice';
import { selectCustomers, selectCustomerStatus } from '../../../redux/slices/customerSlice';
import { selectAllCycles } from '../../../redux/slices/cycleSlice';
import { getAllCustomers } from '../../../services/customers';
import { getAllCycles } from '../../../services/cycle';
import { getCollaborators } from '../../../services/user';
import { transformObjectToArray } from '../../../utils/formatObjectToArray';
// import { selectAllCycles } from '../../../redux/slices/cycleSlice';

function Home() {
  // const inputRef = useRef(null);

  const status = useSelector(selectStatus);
  const customersStatus = useSelector(selectCustomerStatus);
  const collaboratorsStatus = useSelector(selectCollaboratorStatus);
  const allCycles = useSelector(selectAllCycles);
  const dispatch = useDispatch();

  const allCustomers = useSelector(selectCustomers);
  const memoizedCustomers = useMemo(() => allCustomers || [], [allCustomers, dispatch]);
  const [, setCustomers] = useState({});
  const [loadingFilteredCustomers, setLoadingFilteredCustomers] = useState(true);
  const collaborators = useSelector(selectCollaborators);
  // const memoizedCollaborators = useMemo(() => collaborators || [], [collaborators]);

  // const [code, setCode] = useState('TIMENORDESTE');
  // const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    dispatch(getAllCustomers());
    dispatch(getAllCycles());
  }, []);

  useEffect(() => {
    dispatch(getAllCustomers());
    dispatch(getCollaborators());
    dispatch(getAllCycles());
  }, [dispatch]);

  useEffect(() => {
    const customersArray = Object.values(allCustomers).filter(
      customer => !!customer?.userResponsibleId,
    );

    const customersObject = customersArray.reduce(
      (acc, customer) => ({
        ...acc,
        [customer.id]: customer,
      }),
      {},
    );
    setCustomers(customersObject);
    setLoadingFilteredCustomers(false);
  }, [allCustomers]);

  function handleUpdateCustomersList() {
    dispatch(getAllCustomers({ reload: true }));
  }

  // async function handleDisabled() {
  //   await setDisabled(prevState => !prevState);
  //   inputRef.current.focus();
  // }

  // function handleValue(e) {
  //   const { value } = e.target;
  //   setCode(value);
  // }

  const isPageLoading = [status, customersStatus, collaboratorsStatus].includes('loading');
  return (
    <PageLayout>
      {isPageLoading || loadingFilteredCustomers ? (
        <Loading />
      ) : (
        <>
          <SectionTitle>Ciclos</SectionTitle>
          <PeopleList people={transformObjectToArray(allCycles)} type="cycle" addButton />
          <SectionTitle>Integrantes</SectionTitle>
          <PeopleList people={collaborators} />
          {/* <Section>
            <BodyText color="text">Seu código de equipe</BodyText>
            <TeamCode ref={inputRef} value={code} onChange={handleValue} disabled={disabled} />
            <Button onClick={handleDisabled}>{disabled ? 'Editar' : 'Salvar'}</Button>
          </Section> */}

          <SectionTitle>MUNICÍPIOS CADASTRADOS</SectionTitle>
          <CustomersList customers={memoizedCustomers} addButton />
          <SectionControls>
            <OutlinedButton onClick={handleUpdateCustomersList}>
              Atualizar lista de munícipios
            </OutlinedButton>
            <Link to="/dashboard/customers">
              <OutlinedButton>Abrir lista completa de municípios</OutlinedButton>
            </Link>
            <Link to="/dashboard/help">
              <OutlinedButton>Abrir FAQ e roteiros</OutlinedButton>
            </Link>
            <Link to="/dashboard/report">
              <OutlinedButton>Abrir geração de relatórios</OutlinedButton>
            </Link>
          </SectionControls>
        </>
      )}
    </PageLayout>
  );
}

export default Home;
