import { createSlice, current } from '@reduxjs/toolkit';
import { logout } from '../../services/auth';
import { createPerson, deletePerson, editPerson, getPerson } from '../../services/person';

const initialState = {
  people: {},
  status: null,
  error: '',
};

export const peopleSlice = createSlice({
  name: 'people',
  initialState,
  reducers: {},
  extraReducers: {
    [createPerson.pending]: state => {
      state.status = 'loading';
    },
    [createPerson.fulfilled]: (state, action) => {
      state.people = {
        ...state.people,
        ...action.payload[0],
      };
      state.status = 'sucess';
    },
    [createPerson.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [editPerson.pending]: state => {
      state.status = 'loading';
    },
    [editPerson.fulfilled]: (state, action) => {
      const peoples = current(state).people;
      peoples[action.payload.id] = action.payload;
      state.people = {
        ...peoples,
      };
      state.status = 'sucess';
    },
    [editPerson.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [getPerson.pending]: state => {
      state.status = 'loading';
    },
    [getPerson.fulfilled]: (state, action) => {
      state.people = { ...state.people, ...action.payload };
      state.status = 'sucess';
    },
    [getPerson.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [deletePerson.pending]: state => {
      state.status = 'loading';
    },
    [deletePerson.fulfilled]: (state, action) => {
      state.people = action.payload;
      state.status = 'sucess';
    },
    [deletePerson.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [logout.pending]: state => {
      state.status = 'loading';
    },
    [logout.fulfilled]: state => {
      state.people = {};
    },
  },
});

export const selectPeople = state => state.people.people;

export default peopleSlice.reducer;
