import React from 'react';
import { Wrapper, Label } from './style';

function RoadMapButton({ label, type }) {
  return (
    <Wrapper type={type}>
      <Label type={type}>{label}</Label>
    </Wrapper>
  );
}

export default RoadMapButton;
