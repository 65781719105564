import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;

  h2 {
    margin-top: 0;
    margin-bottom: 30px;
  }
`;

export const StatusArea = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 25px;

  button {
    font-size: 0.875rem;
    margin-top: 0;
    padding: 5px 10px;
  }
`;

export const StatusBarArea = styled.div`
  flex: 1;

  h5 {
    font-size: 0.875rem;
    font-weight: normal;
    margin-bottom: 10px;
  }

  p {
    text-align: left;
  }
`;

export const Label = styled.label`
  display: flex;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.text};
  margin: 0 0 4px 2px;
`;

export const AccessionDate = styled.div`
  margin-top: 20px;
`;

export const DateInputs = styled.div`
  display: flex;
  gap: 16px;

  & > div {
    margin-top: 0 !important;
    flex: 1;

    &:nth-child(3) {
      flex: 2;
    }

    input {
      text-align: center;
      text-transform: uppercase;
    }
  }
`;

export const ButtonsArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: fit-content;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 16px;
  }
`;

export const CancelButton = styled.button`
  color: ${({ theme }) => theme.colors.primary.main};
  border: none;
  background: transparent;
  margin-top: 10px;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.dark};
  }
`;
