import { IoPaperPlaneOutline } from 'react-icons/io5';
import { useHistory } from 'react-router';
import { Button } from '..';
import useForms from '../../hooks/useForms';
import FormGroup from '../FormGroup';
import { SectionTitle } from '../Texts/HeadingText';
import { ButtonsArea, CancelButton, Form, TextArea } from './style';

function AddHelpForm({ formTitle, titleLabel, textLabel, onClick }) {
  const history = useHistory();

  const { fields, createFields, handleFieldsChange } = useForms();

  function handleBack() {
    history.push('/dashboard/help');
  }

  function handleSubmit() {
    onClick(fields.title, fields.text);
    handleBack();
  }

  const fieldsObject = {
    title: {
      label: titleLabel,
      value: fields.title,
      placeholder: 'Exemplo de título',
    },
  };

  const isFormValid = () => fields.title && fields.text;

  return (
    <Form>
      <SectionTitle>{formTitle}</SectionTitle>
      {createFields('title', fieldsObject)}
      <FormGroup>
        <label>{textLabel}</label>
        <TextArea
          placeholder="Lorem ipsum dolor sit amet, consectet ui i iadipiscing elit."
          name="text"
          autoCapitalize="sentences"
          value={fields.text}
          onChange={handleFieldsChange({ confirm: false })}
        />
      </FormGroup>

      <ButtonsArea>
        <Button disabled={!isFormValid()} onClick={handleSubmit}>
          <IoPaperPlaneOutline size={24} />
          {formTitle}
        </Button>
        <CancelButton type="button" onClick={handleBack}>
          Cancelar
        </CancelButton>
      </ButtonsArea>
    </Form>
  );
}

export default AddHelpForm;
