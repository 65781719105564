import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { login } from '../../services/auth';
import useForms from '../../hooks/useForms';

import { Wrapper, ForgotPassword, Content, Controls } from './style';
import Button from '../Button';
import HeadingText from '../Texts/HeadingText';
import { Header, Modal, Loading } from '..';
import { selectStatus, setStatus } from '../../redux/slices/authSlice';
import fetcher from '../../services/fetcher';

function LoginForm() {
  const dispatch = useDispatch();
  const status = useSelector(selectStatus);
  const history = useHistory();

  useEffect(() => {
    dispatch(setStatus(null));
  }, []);

  const {
    fields: { email, password },
    createFields,
    errors,
  } = useForms();

  const isFormValid = () => email && password && !errors.length;

  const handleSignIn = async () => {
    try {
      dispatch(login({ email, password }));
    } catch (err) {
      dispatch(setStatus('failed'));
    }
  };

  const generateToken = async event => {
    event.preventDefault();
    const fetch = fetcher();
    const res = await fetch.post(`/registerToken`);
    history.push(`/register/${res.data.data}`);
  };

  const fieldsObject = {
    email: {
      label: 'E-mail',
      type: 'text',
      placeholder: 'Digite seu e-mail',
      value: email,
    },
    password: {
      label: 'Senha',
      type: 'password',
      placeholder: 'Defina sua senha',
      value: password,
      autoComplete: 'on',
    },
  };

  return (
    <>
      <Wrapper>
        <Header type="large" />

        <Content>
          {status === 'loading' ? (
            <Loading />
          ) : (
            <>
              <HeadingText>Entrar</HeadingText>
              {['email', 'password'].map(field => createFields(field, fieldsObject))}

              <Controls>
                <Button disabled={!isFormValid()} onClick={handleSignIn} type="submit">
                  Entrar
                </Button>
                <ForgotPassword onClick={generateToken}>
                  Não possui conta? Cadastre-se
                </ForgotPassword>
              </Controls>
            </>
          )}
        </Content>
      </Wrapper>
      <Modal
        visible={status === 'failed'}
        title="Ops.."
        subtitle="Parece que seus dados estão incorretos."
      >
        <Button onClick={() => dispatch(setStatus(null))}>Ok!</Button>
      </Modal>
    </>
  );
}

export default LoginForm;
