import ReactDOM from 'react-dom';

import { Overlay, Container, Footer } from './styles';

import { BodyText, Button, OutlinedButton } from '..';

function ConfirmDeleteLicenseModal({ isOpen, onClose, url, onConfirmed }) {
  function handleConfirm() {
    onConfirmed();
    onClose();
  }

  return (
    isOpen &&
    ReactDOM.createPortal(
      <Overlay>
        <Container>
          <BodyText color="text">
            Deseja remover essa licença <strong>{url}</strong> do município?{' '}
            <strong>TODAS AS SENHAS SERÃO PERDIDAS</strong>
          </BodyText>

          <Footer>
            <OutlinedButton
              onClick={() => {
                onClose();
              }}
            >
              {' '}
              Cancelar
            </OutlinedButton>

            <Button onClick={handleConfirm} type="button" danger>
              Confirmar
            </Button>
          </Footer>
        </Container>
      </Overlay>,
      document.getElementById('modal-root'),
    )
  );
}

export default ConfirmDeleteLicenseModal;
