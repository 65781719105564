import styled from 'styled-components';

export const Info = styled.form`
  width: 100%;
  background: ${({ theme }) => theme.colors.primary.light};
  padding: 20px;
  min-height: 206px;

  label {
    font-size: 0.75rem;
  }

  & > div {
    margin-top: 0 !important;
    margin-bottom: 10px;
    :last-child {
      margin-bottom: 0px;
    }
  }
`;

export const FirstField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.875rem;

  div {
    input[name='whatsapp'] {
      color: white;
      background: ${({ theme }) => theme.colors.primary.main};
    }
  }
  & > div {
    margin-top: 0 !important;
  }
`;
