import { useCallback, useEffect, useState } from 'react';
import { FaAngleRight } from 'react-icons/fa';
import { AddButton, BodyText, ListHeader } from '..';
import { useFilters } from '../../hooks';
import { Circle, InfoName, Item, List, Navigation } from './style';

function PeopleList({
  people,
  select,
  setItemSelected,
  isPeopleListEmptySentence,
  type = 'people',
  addButton,
}) {
  const {
    searchTerm,
    handleSearchTermChange,
    filteredData,
    orderBy,
    handleToggleOrderBy,
    handleOrderedData,
  } = useFilters({ data: people, filterProperty: 'name' });
  const [, setIsLoading] = useState(false);

  const handleSelectedPerson = useCallback(
    val => {
      if (val.selected) return;
      const changePersonSelected = people.map(person => {
        const newPerson = { ...person };
        if (person.id !== val.id) {
          newPerson.selected = false;
        } else {
          newPerson.selected = true;
        }

        return newPerson;
      });
      setItemSelected(changePersonSelected);
    },
    [people, setItemSelected],
  );

  useEffect(() => {
    handleOrderedData(people);
    setIsLoading(false);
  }, [handleOrderedData, people]);

  if (isPeopleListEmptySentence) {
    return <BodyText>O ciclo não possui usuários cadastrados.</BodyText>;
  }

  return (
    <>
      <ListHeader
        placeholder="Buscar"
        searchTerm={searchTerm}
        setSearchTerm={handleSearchTermChange}
        orderBy={orderBy}
        handleToggleOrderBy={handleToggleOrderBy}
        isEmpty={!people.length}
      />
      <List addButton={addButton}>
        {filteredData.map(person =>
          select ? (
            <Item
              isSelectionList
              selected={person?.selected}
              onClick={() => {
                setIsLoading(true);
                handleSelectedPerson(person);
              }}
              key={person.id}
            >
              <Circle online />
              {person?.name}
            </Item>
          ) : (
            <Item key={person.id}>
              <Navigation
                to={{
                  pathname:
                    type === 'people'
                      ? `/dashboard/person/${person.id}`
                      : `/dashboard/cycle/${person.id}`,
                  state: { name: person.name },
                }}
              >
                <InfoName myTeam={!person.myTeam}>
                  {type === 'people' && <Circle haveCycle={person?.userCycle?.length} />}
                  {person.name}
                </InfoName>
                <FaAngleRight size={20} />
              </Navigation>
            </Item>
          ),
        )}
      </List>
      {addButton && <AddButton to="/dashboard/add-cycle">Adicionar Ciclo</AddButton>}
    </>
  );
}

export default PeopleList;
