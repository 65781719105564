import { FaAngleRight } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { AddButton, BodyText, ListHeader } from '..';
import useFilters from '../../hooks/useFilters';
import { ExtraInformation, Item, List, Loader, LoaderContainer, Navigation } from './style';
import { Circle } from '../PeopleList/style';
import { selectUser } from '../../redux/slices/authSlice';

function CustomersList({
  customers,
  large,
  addButton,
  isCustomersListEmptySentence,
  noCircle = false,
}) {
  const {
    searchTerm,
    isLoading,
    forceDisableButton,
    handleSearchTermChange,
    filteredData,
    orderBy,
    handleToggleOrderBy,
  } = useFilters({ data: customers, filterProperty: 'name' });
  const user = useSelector(selectUser);
  return (
    <>
      <ListHeader
        placeholder="Buscar municípios"
        searchTerm={searchTerm}
        setSearchTerm={handleSearchTermChange}
        orderBy={orderBy}
        handleToggleOrderBy={handleToggleOrderBy}
        isEmpty={!Object.values(customers).length}
        htmlFor="customers"
        forceDisableButton={forceDisableButton}
      />
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <List large={large} addButton={addButton}>
          {Object.values(customers).length ? (
            <>
              {filteredData.map(prefecture => (
                <Item key={prefecture.id}>
                  <Navigation to={`/customer/${prefecture.id}`}>
                    {!noCircle && <Circle haveCycle={prefecture?.customerCycle?.length} />}
                    {prefecture.name}
                    {Number(prefecture.userResponsibleId) === Number(user.id) && (
                      <ExtraInformation>responsável</ExtraInformation>
                    )}
                    <FaAngleRight size={20} />
                  </Navigation>
                </Item>
              ))}
            </>
          ) : (
            <BodyText>
              {isCustomersListEmptySentence || 'Você ainda não possui municípios'}
            </BodyText>
          )}
        </List>
      )}
      {addButton && <AddButton to="/dashboard/add-customer">Adicionar município</AddButton>}
    </>
  );
}

export default CustomersList;
