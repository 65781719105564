import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import authReducer from '../slices/authSlice';
import customerReducer from '../slices/customerSlice';
import peopleReducer from '../slices/peopleSlice';
import contactReducer from '../slices/contactSlice';
import collaboratorReducer from '../slices/collaboratorSlice';
import cycleReducer from '../slices/cycleSlice';
import newsReducer from '../slices/newsSlice';

const reducers = combineReducers({
  auth: authReducer,
  customer: customerReducer,
  people: peopleReducer,
  contact: contactReducer,
  collaborator: collaboratorReducer,
  cycles: cycleReducer,
  news: newsReducer,
});

const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['get/customers/fulfilled', 'persist/PERSIST'],
      },
    }),
});

export { store };
