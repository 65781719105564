import styled from 'styled-components';

export const ListItem = styled.ul``;

export const Item = styled.li`
  background-color: white;
  padding: 10px;
  list-style-type: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-radius: 4px;

  p {
    text-align: left;
    font-size: 12px;
    text-transform: uppercase;
  }

  h5 {
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    text-transform: uppercase;
  }

  &:not(:first-of-type) {
    margin-top: 10px;
  }
`;
