import React from 'react';
import { IoPaperPlaneOutline } from 'react-icons/io5';
import { useHistory, useParams } from 'react-router-dom';

import { Button, PageLayout } from '../../components';
import { SectionTitle } from '../../components/Texts/HeadingText';
import useForms from '../../hooks/useForms';
import { addRegister, createLicenseAndFirstRegister } from '../../services/sicgespLicense';
import { ButtonsArea, CancelButton, Form } from './style';

function AddSicgespLicense({ location }) {
  const history = useHistory();
  const { customerId } = useParams();
  const { fields, createFields, errors } = useForms();
  const type = location?.state?.type;
  const licenseId = location?.state?.licenseId;

  const fieldsObject = {
    url: {
      label: 'URL',
      value: fields.url,
      placeholder: 'http://meulink.com',
      messageIfHasError: 'Url obrigatório',
    },
    cpf: {
      label: 'Usuário',
      value: fields.cpf,
      placeholder: '999.999.999-99',
      messageIfHasError: 'Usuário obrigatório',
    },
    password: {
      label: 'Senha',
      value: fields.password,
      placeholder: 'Digite a senha aqui',
      messageIfHasError: 'Senha obrigatório',
    },
    name: {
      label: 'Nome',
      value: fields.name,
      placeholder: 'Nome',
    },
    phone: {
      label: 'Telefone',
      value: fields.phone,
      placeholder: '(XX) YYYY-YYYY',
    },
    email: {
      label: 'E-mail',
      value: fields.email,
      placeholder: 'email@exemplo.com',
    },
  };

  function handleBack() {
    history.goBack();
  }

  async function handleAddRegister() {
    if (!licenseId) {
      await createLicenseAndFirstRegister({
        cpf: fields.cpf,
        url: fields.url,
        name: fields.name,
        phone: fields.phone,
        email: fields.email,
        customerId,
        password: fields.password,
      });
      handleBack();
    } else {
      await addRegister({
        licenseId,
        password: fields.password,
        cpf: fields.cpf,
        url: fields.url,
        name: fields.name,
        phone: fields.phone,
        email: fields.email,
      });
      handleBack();
    }
  }

  const validateUrl = (type === 'create' && fields.url) || type !== 'create';

  const isFormValid = () => fields.cpf && fields.password && !errors.length && validateUrl;

  return (
    <PageLayout previous={`/licenses/${customerId}`}>
      <Form>
        <SectionTitle>Adicionar cadastro</SectionTitle>

        {!licenseId && createFields('url', fieldsObject)}

        {['cpf', 'password', 'name', 'phone', 'email'].map(field =>
          createFields(field, fieldsObject),
        )}

        <ButtonsArea>
          <Button onClick={handleAddRegister} disabled={!isFormValid()}>
            <IoPaperPlaneOutline size={24} />
            Adicionar cadastro
          </Button>
          <CancelButton type="button" onClick={handleBack}>
            Cancelar
          </CancelButton>
        </ButtonsArea>
      </Form>
    </PageLayout>
  );
}

export default AddSicgespLicense;
