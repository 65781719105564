import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { IoPaperPlaneOutline, IoTrashOutline } from 'react-icons/io5';

import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { Button, PageLayout } from '../../components';
import { SectionTitle } from '../../components/Texts/HeadingText';
import useForms from '../../hooks/useForms';
import { Form, ButtonsArea, CancelButton } from './style';

import { formatPhone } from '../../utils/formatPhone';
import { formatPhoneToNumber } from '../../utils/formatPhoneToNumber';
import { deleteProspectorById, editProspector } from '../../services/prospection';

// import { selectCustomer } from '../../redux/slices/customerSlice';

function EditProspectus() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data: prospector, customerId } = location.state;
  const { fields, createFields, errors, handleAllFieldsChange } = useForms();
  const [disabled] = useState(false);
  // const prospector = useSelector(state => selectProspectorById(state, id));
  // const customerExists = useSelector(state => selectCustomer(state, id));

  // if (!customerExists) history.goBack();

  useEffect(() => {
    handleAllFieldsChange(prospector);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fieldsObject = {
    name: {
      label: 'Nome',
      value: fields.name,
      placeholder: 'Nome',
      disabled,
    },
    whatsapp: {
      label: 'Whatsapp',
      value: formatPhone(fields.whatsapp),
      maxLength: 15,
      placeholder: '(XX) YYYYY-YYYY',
      disabled,
    },
    phone: {
      label: 'Telefone',
      value: formatPhone(fields.phone),
      maxLength: 15,
      placeholder: '(XX) YYYY-YYYY',
      disabled,
    },
    email: {
      label: 'E-mail do representante',
      value: fields.email,
      type: 'email',
      placeholder: 'E-mail',
      disabled,
    },
  };

  function handleBack() {
    handleAllFieldsChange(prospector);
    history.goBack();
  }

  async function handleUpdateProspector() {
    try {
      // dispatch();
      const data = {
        name: fields.name,
        whatsapp: formatPhoneToNumber(fields.whatsapp),
        phone: formatPhoneToNumber(fields.phone),
        email: fields.email,
      };
      handleAllFieldsChange(data);
      dispatch(editProspector({ id, ...data, customerId }));
      handleBack();
    } catch (err) {
      // eslint-disable-next-line no-console
      // console.log(err);
    }
  }

  const handleDeleteProspectus = async () => {
    try {
      dispatch(deleteProspectorById({ customerId, id }));
      history.goBack();
    } catch (err) {
      console.log(err);
    }
  };

  const isFormValid = () => fields.name && fields.email && !errors.length;

  return (
    <PageLayout previous={`/contact/${id}`}>
      <Form>
        <SectionTitle>EDITAR CONTATO</SectionTitle>
        {['name', 'email', 'phone', 'whatsapp'].map(field => createFields(field, fieldsObject))}
        <br />
        <ButtonsArea>
          <Button onClick={handleUpdateProspector} disabled={!isFormValid()}>
            <IoPaperPlaneOutline size={24} />
            Salvar Alterações
          </Button>
          <Button onClick={handleDeleteProspectus} danger>
            <IoTrashOutline size={24} />
            Excluir Contato
          </Button>
          <CancelButton type="button" onClick={handleBack}>
            Descartar mudanças
          </CancelButton>
        </ButtonsArea>
      </Form>
    </PageLayout>
  );
}

export default EditProspectus;
