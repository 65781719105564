/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
import React, { useEffect, useMemo, useState } from 'react';
import { MdContentCopy } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { FaTrash } from 'react-icons/fa';
import { FiPhone } from 'react-icons/fi';
import { IoIosDocument } from 'react-icons/io';
import {
  AddButton,
  BodyText,
  ConfirmModal,
  DropdownModal,
  HeadingText,
  // ProgressBar,
  List,
  OutlinedButton,
  PageLayout,
} from '../../components';
import Dropdown from '../dashboard/ClientsOfUser/DropdownModal';
import {
  Button,
  ButtonListAll,
  ButtonsHeader,
  ControlButtonsArea,
  CopyButton,
  CycleSpan,
  // eslint-disable-next-line import/named
  HeaderList,
  Item,
  ListMail,
  Section,
  SectionTitle,
  Subtitle,
  Title,
} from './style';

import { selectUserPermission } from '../../redux/slices/authSlice';
import { selectCollaboratorByCycle } from '../../redux/slices/collaboratorSlice';
import { selectCustomer, selectCustomerEmails } from '../../redux/slices/customerSlice';
import {
  delegateOrTransferManyCustomers,
  deleteCustomerEmail,
  getClient,
  getCustomerEmails,
  removeManyCustomersOfAnUserResponsible,
} from '../../services/customers';

import { Calendar } from '../../components/Calendar';
import ConfirmDeleteEmailModal from '../../components/ConfirmDeleteEmailModal';
import { selectAllCycles } from '../../redux/slices/cycleSlice';
import { getStatus } from '../../services/calendar';
import { setCustomerCycle } from '../../services/cycle';
import { getPerson } from '../../services/person';
import { formatName } from '../../utils/formatName';
import { transformObjectToArray } from '../../utils/formatObjectToArray';
// import { SortButton, Span } from '../../components/ListHeader/style';

function Customer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const customer = useSelector(state => selectCustomer(state, id));
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isDropdownModalOpen, setIsDropdownModalOpen] = useState(false);
  const [modalHandlerType, setModalHandlerType] = useState(null);
  const [typeHeight, setTypeHeight] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState({});
  const [modalDeleteEmailOpen, setModalDeleteEmailOpen] = useState(false);
  const userPermission = useSelector(selectUserPermission);
  const collaborators = useSelector(state =>
    selectCollaboratorByCycle(
      state,
      userPermission === 'admin' ? customer?.customerCycle[0]?.cycle?.id : 0,
    ),
  );

  // const collaborators = useSelector(selectCollaborators);
  const customerEmails = useSelector(state => selectCustomerEmails(state, id));
  const filteredCollaborators = useMemo(
    () => collaborators.filter(collaborator => collaborator.id !== customer?.userResponsible?.id),
    [collaborators, customer?.userResponsible?.id],
  );
  const [cycleSelected, setCycleSelected] = useState(null);
  const [modalCycle, setModalCycle] = useState(false);

  const cycles = useSelector(selectAllCycles);
  console.log(JSON.stringify(customer, null, 2));
  function setCycle() {
    dispatch(setCustomerCycle({ cycleId: cycleSelected, customerId: id }));
  }

  useEffect(() => {
    dispatch(getClient(id));
    setTimeout(() => {
      dispatch(getCustomerEmails(id));
      dispatch(getStatus(id));
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, modalDeleteEmailOpen]);

  if (!customer) history.goBack();

  useEffect(() => {
    const people = customer?.people || [];
    Object.values(people).forEach(person => {
      dispatch(getPerson(person.id));
    });
  }, [customer?.people, dispatch]);

  function toggleHeight() {
    setTypeHeight(prev => (prev === 'large' ? null : 'large'));
  }

  async function handleRemoveCustomerOfUserResponsible() {
    try {
      dispatch(
        removeManyCustomersOfAnUserResponsible({
          userId: customer?.userResponsible?.id,
          customerIds: [customer.id],
        }),
      );
      // history.push(`/dashboard`);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleTransferCustomerUserResponsible() {
    try {
      dispatch(
        delegateOrTransferManyCustomers({
          customerIds: [customer?.id],
          userId: selectedPerson,
        }),
      );

      history.goBack();
    } catch (err) {
      console.log(err);
    }
  }

  // consoles provisórios até as funções de cada modal estarem prontas
  const modalHandlers = {
    delegate: {
      label: 'Delegar',
      disabled: !!customer?.userResponsibleId,
      action: handleTransferCustomerUserResponsible,
    },
    // supervise: {
    //   label: 'Supervisionar',
    //   disabled: true,
    //   action: () => console.log('supervisionou'),
    // },
    cycle: {
      label: 'Alterar ciclo',
      disabled: false,
      action: setCycle,
    },
    transfer: {
      label: 'Tranferir',
      disabled: !customer?.userResponsibleId,
      action: handleTransferCustomerUserResponsible,
    },
    remove: {
      label: 'Remover',
      disabled: !customer?.userResponsibleId,
      action: handleRemoveCustomerOfUserResponsible,
    },
  };

  function handleOpenModal({ modalType }) {
    return () => {
      if (modalType === 'cycle') {
        setModalHandlerType(modalType);

        setModalCycle(true);
        return;
      }
      if (['supervise', 'remove'].includes(modalType)) {
        setIsConfirmModalOpen(true);
      } else {
        setIsDropdownModalOpen(true);
      }

      setModalHandlerType(modalType);
    };
  }
  async function handleDeleteEmail() {
    await deleteCustomerEmail(selectedEmail.id);
  }
  function handleCloseModal() {
    setModalCycle(false);
    setIsConfirmModalOpen(false);
    setIsDropdownModalOpen(false);
    setModalDeleteEmailOpen(false);
  }

  const copyToClipboard = text => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };
  const copyEmails = () => {
    const emails = [];

    customerEmails.forEach(email => {
      emails.push(email?.email);
    });
    const emailsToCopy = emails.join(', ');
    copyToClipboard(emailsToCopy);
  };

  return (
    <>
      <ConfirmDeleteEmailModal
        email={selectedEmail}
        setEmail={setSelectedEmail}
        isOpen={modalDeleteEmailOpen}
        onClose={handleCloseModal}
        onConfirmed={handleDeleteEmail}
      />
      <PageLayout previous="/customers">
        <Title>
          <HeadingText color="text">{customer?.name}</HeadingText>
        </Title>
        <br />
        {customer.customerCycle.map(cycle => (
          <CycleSpan>{cycle?.cycle.name},</CycleSpan>
        ))}
        <Subtitle>
          {/* <BodyText color="text">
          Supervisor: <strong>Érida/Lucas</strong>
        </BodyText> */}
          {userPermission === 'admin' && (
            <BodyText color="text">
              Colaborador responsável:{' '}
              <strong>{formatName(customer?.userResponsible?.name) || 'Sem responsável'}</strong>
            </BodyText>
          )}
        </Subtitle>

        {userPermission === 'admin' && (
          <ControlButtonsArea>
            {Object.keys(modalHandlers).map(modalHandler => (
              <OutlinedButton
                key={modalHandler}
                onClick={handleOpenModal({ modalType: modalHandler })}
                alert={modalHandler === 'remove'}
                disabled={modalHandlers[modalHandler].disabled}
              >
                {modalHandlers[modalHandler]?.label}
              </OutlinedButton>
            ))}
          </ControlButtonsArea>
        )}

        <ButtonsHeader>
          <Button to={`/contact/${id}`}>
            <FiPhone size={24} />
            Iniciar contato
          </Button>

          <Button to={`/licenses/${id}`}>
            <IoIosDocument size={24} />
            Licença
          </Button>
        </ButtonsHeader>

        <Section>
          <SectionTitle>Status de Implementação</SectionTitle>
          <Calendar customerId={id} customer={customer} />
          {/* <ProgressBar step={customer?.implementationStatus?.step} />
        <BodyText color="text">{customer?.implementationStatus?.status}</BodyText> */}
        </Section>

        <Section>
          <SectionTitle>Dados do Município</SectionTitle>
          <List
            group={customer?.implementationGroup?.populationGroup}
            population={customer?.population}
            // stage={customer?.expectedPhase?.phase}
            date={customer?.accessionDate}
            decreeDate={customer?.decreeDate}
            phone={customer?.customerPhone}
          />
        </Section>

        <Section>
          <SectionTitle>E-MAILS DO MUNICÍPIO</SectionTitle>
          <CopyFilterHeader onClick={copyEmails} />
          <ListMail type={typeHeight}>
            <Item type="title">
              <p>endereco</p>
              {userPermission === 'admin' ? (
                <p className="column-2">
                  <p>categoria</p>

                  <p>excluir</p>
                </p>
              ) : (
                <p>categoria</p>
              )}
            </Item>
            {!!customerEmails &&
              customerEmails?.map(email => (
                <Item key={email?.id}>
                  <p>{email?.email}</p>

                  {userPermission === 'admin' ? (
                    <p className="column-2">
                      <p>{email?.category === 'executive' ? 'Exec.' : 'Leg.'}</p>
                      <FaTrash
                        color="#F24027"
                        onClick={() => {
                          setModalDeleteEmailOpen(true);
                          setSelectedEmail(email);
                        }}
                        cursor="pointer"
                      />
                    </p>
                  ) : (
                    <p>{email?.category === 'executive' ? 'Exec.' : 'Leg.'}</p>
                  )}
                </Item>
              ))}
          </ListMail>
          <ButtonListAll onClick={toggleHeight}>
            {typeHeight === 'large' ? 'Ver menos' : 'Ver lista completa'}
          </ButtonListAll>
          <AddButton to={`/contact/${id}/add-email`}>Adicionar e-mail à lista</AddButton>
        </Section>

        {userPermission === 'admin' && (
          <>
            <ConfirmModal
              isOpen={isConfirmModalOpen}
              onClose={handleCloseModal}
              collaboratorName={customer?.userResponsible?.name}
              modalHandler={modalHandlers[modalHandlerType]}
              modalType={modalHandlerType}
            />

            <DropdownModal
              isOpen={isDropdownModalOpen}
              onClose={handleCloseModal}
              modalHandler={modalHandlers[modalHandlerType]}
              options={filteredCollaborators}
              setSelected={setSelectedPerson}
            />

            <Dropdown
              isOpen={modalCycle}
              onClose={handleCloseModal}
              modalHandler={modalHandlers[modalHandlerType]}
              options={transformObjectToArray(cycles)}
              label="Ciclos"
              setSelected={setCycleSelected}
            />
          </>
        )}
      </PageLayout>
    </>
  );
}

const CopyFilterHeader = ({ onClick }) => (
  <HeaderList>
    <CopyButton onClick={onClick}>
      COPIAR LISTA COMPLETA
      <MdContentCopy size={20} />
    </CopyButton>
    {/* <SortButton>
      Filtrar
      <Span orderBy={() => console.log('')}>
        <MdOutlineFilterAlt size={20} />
      </Span>
    </SortButton> */}
  </HeaderList>
);

export default Customer;
