import styled from 'styled-components';
import theme from '../../styles/theme';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 234px;
  padding: 8px;
  height: 2.8rem;
  margin-top: 16px;
  background-color: ${({ type }) =>
    type === 'primary' ? theme.colors.accentRegular : 'transparent'};
  border: ${({ type }) =>
    type === 'primary' ? 'none' : `1px solid ${theme.colors.accentRegular}`};
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  }
`;

export const Label = styled.span`
  font-size: 1rem;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ type }) => (type === 'primary' ? 'white' : theme.colors.accentRegular)};
`;
