import React from 'react';
import { Wrapper } from './style';

function Button({ type, onClick, children, disabled, white, danger }) {
  const handleClick = event => {
    event.preventDefault();

    if (onClick) onClick();
  };

  return (
    <Wrapper white={white} danger={danger} type={type} onClick={handleClick} disabled={disabled}>
      {children}
    </Wrapper>
  );
}

export default Button;
