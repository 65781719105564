import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router';

import { Button, ConfirmPasswordModal, PageLayout } from '../../components';
import { SectionTitle } from '../../components/Texts/HeadingText';
import useForms from '../../hooks/useForms';
import { selectUser } from '../../redux/slices/authSlice';
import { logout } from '../../services/auth';
import { updatePassword, updateProfile } from '../../services/user';
import { formatName } from '../../utils/formatName';
import { ButtonArea, ControlButtons, Form } from './style';

function Profile() {
  // const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { fields, createFields, errors } = useForms({ userName: user.name, userEmail: user.email });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');

  const fieldsObject = {
    name: {
      label: 'Nome completo',
      value: fields.name,
      placeholder: 'Nome de exemplo',
    },
    email: {
      label: 'E-mail',
      value: fields.email,
      type: 'email',
      placeholder: 'exemplo@gmail.com',
    },
    team: {
      label: 'Equipe',
      value: fields.team,
      placeholder: 'Exemplo',
    },
  };

  // eslint-disable-next-line no-shadow
  function handleOpenModal(modalType) {
    return () => {
      setModalType(modalType);
      setIsModalOpen(true);
    };
  }

  function handleCloseModal() {
    setIsModalOpen(false);
    fields.name = user.name;
    fields.email = user.email;
  }

  async function handleUpdateProfile(password) {
    dispatch(updateProfile({ name: fields.name, email: fields.email, password }));
  }

  function handleUpdatePassword(password, newPassword) {
    dispatch(updatePassword({ password, newPassword }));
  }

  const modalHandlers = {
    updateData: {
      label: 'Alterar dados',
      action: handleUpdateProfile,
    },
    updatePassword: {
      label: 'Alterar senha',
      action: handleUpdatePassword,
      changePassword: true,
    },
  };

  function handleLogout() {
    dispatch(logout());
    // history.push('/');
  }

  const isFormValid = !errors.length;

  return (
    <PageLayout previous="/customers">
      <Form name="profile">
        <SectionTitle>Meu Perfil</SectionTitle>
        {['name', 'email', 'team'].map(field => createFields(field, fieldsObject))}

        <ControlButtons>
          <Button white onClick={handleOpenModal('updatePassword')}>
            Alterar Senha
          </Button>
          <Button onClick={handleOpenModal('updateData')} disabled={!isFormValid}>
            Editar Dados
          </Button>
        </ControlButtons>
      </Form>

      <ButtonArea>
        <Button onClick={handleLogout}>
          Sair de <strong>@{formatName(user?.name)}</strong>
        </Button>
      </ButtonArea>

      {isModalOpen && (
        <ConfirmPasswordModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          modalHandler={modalHandlers[modalType]}
        />
      )}
    </PageLayout>
  );
}

export default Profile;
