import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 15px 16px;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  outline: none;

  ${({ small }) =>
    small &&
    css`
      padding: 9px 16px;
      width: 100%;

      span {
        font-size: 0.875rem;
      }
    `}

  border: 1px solid ${({ theme, hasEdge }) => (hasEdge && theme.colors.black) || 'transparent'};
`;

export const LabelContent = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

export const Relative = styled.div`
  position: relative;
  height: 0px;
  width: 100%;
`;

export const OptionsContainer = styled.div`
  width: calc(100% + 30px);
  position: absolute;
  top: 20px;
  left: -15px;
  z-index: 10;
  overflow: auto;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.colors.primary.border};
  opacity: ${({ open }) => (open ? '1' : '0')};
  max-height: ${({ open }) => (open ? '300px' : '0')};
  transition: opacity 0.2s, max-height 0.2s;
`;

export const Icon = styled.div``;

export const Text = styled.span`
  width: 100%;
`;

export const Arrow = styled.div`
  height: 20px;
  width: 20px;
  overflow: hidden;
  transform: rotateX(${({ open }) => (open ? '180deg' : '0')});
  transition: transform 0.2s;

  > svg {
    margin: auto;
    right: 5px;
    bottom: 5px;
    position: relative;
  }
`;

export const Option = styled.div`
  padding: 10px 15px;
  transition: background 200ms ease-in;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.altBackground};
  }

  ${({ small }) =>
    small &&
    css`
      padding: 8px 15px;
      font-size: 0.875rem;
    `}
`;
