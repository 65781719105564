import * as axios from 'axios';
import Cookie from 'js-cookie';

const fetcher = (withToken = false) => {
  const props = {
    // baseURL: 'http://localhost:8000/',
    baseURL: 'http://api.numiware.com/',
  };

  const token = Cookie.get('token');

  if (withToken && token) {
    props.headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  const instance = axios.create(props);

  return instance;
};

export default fetcher;
