import React from 'react';
import { useHistory, useParams } from 'react-router';
import { IoPaperPlaneOutline } from 'react-icons/io5';

import { useDispatch, useSelector } from 'react-redux';
import { Button, PageLayout } from '../../components';
import { SectionTitle } from '../../components/Texts/HeadingText';
import useForms from '../../hooks/useForms';
import { Form, ButtonsArea, CancelButton } from './style';

import { formatPhone } from '../../utils/formatPhone';

import { selectCustomer } from '../../redux/slices/customerSlice';
import { createProspector } from '../../services/prospection';
import { formatPhoneToNumber } from '../../utils/formatPhoneToNumber';

function AddProspectus() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { fields, createFields, errors } = useForms();

  const customerExists = useSelector(state => selectCustomer(state, id));
  // const status = useSelector(state => selectStatus(state));
  // const [modalVisible, setModalVisible] = useState(false);
  // const toggleModalVisible = setModalVisible(prev => !prev);
  if (!customerExists) history.goBack();

  const fieldsObject = {
    name: {
      label: 'Nome',
      value: fields.name,
      placeholder: 'Nome',
      validateField: false,
    },
    whatsapp: {
      label: 'Whatsapp',
      value: formatPhone(fields.whatsapp),
      maxLength: 15,
      placeholder: '(XX) YYYYY-YYYY',
    },
    phone: {
      label: 'Telefone',
      value: formatPhone(fields.phone),
      maxLength: 15,
      placeholder: '(XX) YYYY-YYYY',
    },
    email: {
      label: 'E-mail do representante',
      value: fields.email,
      type: 'email',
      placeholder: 'E-mail',
    },
  };

  function handleBack() {
    history.goBack();
  }

  async function handleCreateProspector() {
    try {
      const data = {
        name: fields?.name,
        whatsapp: formatPhoneToNumber(fields?.whatsapp),
        phone: formatPhoneToNumber(fields?.phone),
        email: fields?.email,
        customerId: id,
      };
      dispatch(createProspector(data));

      handleBack();
    } catch (err) {
      // eslint-disable-next-line no-console
      // console.log(err);
    }
  }

  const isFormValid = () => fields.email && !errors.length;

  return (
    <PageLayout previous={`/contact/${id}`}>
      {/* <Modal visible={modalVisible} title={status.message} subtitle="">
        <Button onClick={() => setModalVisible(false)}>Fechar</Button>
      </Modal> */}
      <Form>
        <SectionTitle>ADICIONAR CONTATO</SectionTitle>
        {['name', 'email', 'phone', 'whatsapp'].map(field => createFields(field, fieldsObject))}

        <ButtonsArea>
          <Button onClick={handleCreateProspector} disabled={!isFormValid()}>
            <IoPaperPlaneOutline size={24} />
            Adicionar Contato
          </Button>
          <CancelButton type="button" onClick={handleBack}>
            Cancelar
          </CancelButton>
        </ButtonsArea>
      </Form>
    </PageLayout>
  );
}

export default AddProspectus;
