import { useEffect, useRef, useState } from 'react';
// import { useSelector } from 'react-redux';
import { BodyText, Button, Modal } from '..';
import useModal from '../../hooks/useModal';
import formatDate from '../../utils/formatDate';
import { hideText } from '../../utils/hideText';
import { Content, Span, ViewMore, Wrapper } from './style';

function Observation({ obs, index }) {
  const [isAnnotationLengthHigher, setIsAnnotationLengthHigher] = useState(false);
  const bodyRef = useRef(null);
  // const peopleRedux = useSelector(state => state.people.people);

  const {
    fields: { visible },
    changeVisibility,
  } = useModal();
  const openModal = () => {
    changeVisibility();
  };

  useEffect(() => {
    setIsAnnotationLengthHigher(bodyRef.current?.clientHeight >= 50);
  }, []);

  return (
    <>
      <Wrapper key={obs.id}>
        <Content>
          <Span>{obs.person?.name}</Span>
          <Span> - {formatDate(obs.createdAt)}</Span>
        </Content>
        <BodyText ref={bodyRef} color="text">
          {!visible && isAnnotationLengthHigher ? hideText(obs.annotation) : obs.annotation}
        </BodyText>
        {isAnnotationLengthHigher && <ViewMore onClick={() => openModal(index)}>Ver mais</ViewMore>}
      </Wrapper>
      <Modal visible={visible} title={formatDate(obs.createdAt)} subtitle={obs.annotation} large>
        <Button onClick={changeVisibility}>Fechar</Button>
      </Modal>
    </>
  );
}

export default Observation;
