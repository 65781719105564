/* eslint-disable react/jsx-props-no-spreading */
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
// import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import {
  // logout,
  // logout,
  verifyToken,
} from '../services/auth';
import { getUserByToken } from '../services/user';

function PrivateRoute(props) {
  const { component: Component, ...rest } = props;
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const token = Cookies.get('token');
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    try {
      dispatch(getUserByToken());
      dispatch(verifyToken());
    } catch (err) {
      // console.log(err);
    }

    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [token, dispatch]);

  useEffect(() => {
    dispatch(verifyToken());
  }, [dispatch]);

  if (!isAuthenticated && !isFirstRender) {
    // dispatch(logout());
    return <Redirect to="/" />;
  }

  return <Route {...rest} component={routeProps => <Component {...routeProps} />} />;
}

export default PrivateRoute;
