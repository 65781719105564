import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineReload } from 'react-icons/ai';
import { BodyText, PageLayout } from '../../components';
// import { getAllNotifications } from '../../services/notifications';
import Notification from '../../components/Notification';
import { selectUserPermission } from '../../redux/slices/authSlice';
import { selectAllNews } from '../../redux/slices/newsSlice';
import { getAllNews, getAllNewsByCycle } from '../../services/news';
import { Btn, End } from './style';

function Notifications() {
  const dispatch = useDispatch();
  // const [notifications, setNotifications] = useState([]);
  const userPermission = useSelector(selectUserPermission);
  const news = useSelector(selectAllNews);

  function handleGetNews() {
    if (userPermission === 'admin') {
      dispatch(getAllNews());
    } else {
      dispatch(getAllNewsByCycle());
    }
  }
  useEffect(() => {
    handleGetNews();
  }, []);

  // useEffect(() => {
  //   setNotifications(getAllNotifications());
  // }, []);

  return (
    <PageLayout previous="/customers" action={userPermission === 'admin'}>
      {!news?.length && <BodyText color="regular">Sem notícias</BodyText>}
      <End>
        <Btn onClick={handleGetNews}>
          <AiOutlineReload color="#fff" />
        </Btn>
      </End>
      {news?.map(({ title, message }) => (
        <Notification.Root>
          <Notification.Content title={title} text={message} />
        </Notification.Root>
      ))}
    </PageLayout>
  );
}

export default Notifications;
