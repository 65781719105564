import styled from 'styled-components';

export const Line = styled.div`
  width: 100%;
  height: 12px;
  margin-bottom: 10px;
  background: white;
  display: flex;
  align-items: center;
  background: #dadada;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  /* position: relative; */
`;

// export const Point = styled.div`
//   width: 24px;
//   height: 24px;
//   border-radius: 50%;
//   /* border: ${({ theme, done }) =>
//     done ? `1px solid ${theme.colors.primary.yellow}` : `1px solid #dadada`}; */
//   background: ${({ done }) => (done ? 'white' : '#dadada')};
//   /* background: white; */
//   margin-left: -3px;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   /* position: absolute;
//   top: -2px; */
// `;

export const Progress = styled.div`
  width: ${({ step }) => `${step}%`};
  height: 100%;
  background: ${({ theme }) => theme.colors.primary.yellow};
`;
