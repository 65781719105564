import { createSlice } from '@reduxjs/toolkit';
import {
  getAllCustomerByCycleId,
  getAllCycles,
  getAllUsersByCycleId,
  setUserCycle,
} from '../../services/cycle';

const initialState = {
  cycles: {},
  status: null,
  error: '',
};

export const cycleSlice = createSlice({
  name: 'cycles',
  initialState,
  reducers: {},
  extraReducers: {
    [getAllCycles.pending]: state => {
      state.status = 'loading';
    },
    [getAllCycles.fulfilled]: (state, action) => {
      state.cycles = action.payload;
      state.status = 'sucess';
    },
    [getAllCycles.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },

    [getAllCustomerByCycleId.pending]: state => {
      state.status = 'loading';
    },
    [getAllCustomerByCycleId.fulfilled]: (state, action) => {
      // state.cycles = action.payload;
      state.cycles = {
        ...state.cycles,
        [action.payload.id]: {
          ...state.cycles[action.payload.id],
          customers: action.payload.customers,
        },
      };
      state.status = 'sucess';
    },
    [getAllCustomerByCycleId.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },

    [getAllUsersByCycleId.pending]: state => {
      state.status = 'loading';
    },
    [getAllUsersByCycleId.fulfilled]: (state, action) => {
      // state.cycles = action.payload;
      state.cycles = {
        ...state.cycles,
        [action.payload.id]: {
          ...state.cycles[action.payload.id],
          users: action.payload.users,
        },
      };
      state.status = 'sucess';
    },
    [getAllUsersByCycleId.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
    [setUserCycle.pending]: state => {
      state.status = 'loading';
    },
    // [setUserCycle.fulfilled]: state => {
    //   console.log(state);
    // },
    [setUserCycle.rejected]: (state, action) => {
      state.error = action.error;
      state.status = 'failed';
    },
  },
});

export const selectAllCycles = state => state.cycles.cycles;
export const selectCustomersByCycleId = (state, id) => state.cycles.cycles[id]?.customers;
export const selectUsersByCycleId = (state, id) => state.cycles.cycles[id]?.users;

export default cycleSlice.reducer;
